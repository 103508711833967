import {ListMostFrequentValues} from './list-most-frequent-values';
import {SuggestCards} from './suggest-cards';

/**
 * Generated from com.dataiku.dip.eda.worksheets.queries.InteractiveQuery
 */
export interface _InteractiveQuery {}

export type InteractiveQuery = SuggestCards | ListMostFrequentValues;

export namespace InteractiveQuery {
    /**
     * Generated from com.dataiku.dip.eda.worksheets.queries.InteractiveQuery$InteractiveQueryResult
     */
    export interface _InteractiveQueryResult {}

    export type InteractiveQueryResult = SuggestCards.SuggestCardsResult | ListMostFrequentValues.ListMostFrequentValuesResult;

    export function isInteractiveQueryResult(toTest: InteractiveQuery.InteractiveQueryResult): toTest is InteractiveQueryResult { return ["suggest_cards" , "list_most_frequent_values"].includes(toTest.type); }
}
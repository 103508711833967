<feed-image-modal-section [sectionTitle]="'Details'">
    <ul class="raw-unstyled-ul">
        <li *ngFor="let item of categoryColorMap | keyvalue" class="deephub-image-details__category">
            <div class="deephub-image-legend noflex">
                <span class="deephub-image-legend__item" [style.background-color]="item.value"></span>
            </div>
            <span class="mleft8" [title]="item.key">
                {{ item.key }}
            </span>
        </li>
    </ul>
</feed-image-modal-section>
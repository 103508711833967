/**
 * Generated from com.dataiku.dip.security.SSOSettings
 */
export interface SSOSettings {
    enabled: boolean;
    protocol: SSOSettings.SSOProtocol;
    remappingRules: SSOSettings.SSOUserRemappingRule[];
    samlIDPMetadata: string;
    samlLoginAttribute: string;
    samlSPParams: SSOSettings.SAMLSPParams;
    spnegoKrb5File: string;
    spnegoLoginConfFile: string;
    spnegoMode: SSOSettings.SPNEGOMode;
    spnegoPreauthKeytab: string;
    spnegoPreauthLogin: string;
    spnegoPreauthPassword: string;
    spnegoPreauthPrincipal: string;
    spnegoStripRealm: boolean;
}

export namespace SSOSettings {
    /**
     * Generated from com.dataiku.dip.security.SSOSettings$SAMLSPParams
     */
    export interface SAMLSPParams {
        acsURL: string;
        entityId: string;
        keyAlias: string;
        keystoreFile: string;
        keystorePassword: string;
        signRequests: boolean;
    }

    /**
     * Generated from com.dataiku.dip.security.SSOSettings$SPNEGOMode
     */
    export enum SPNEGOMode {
        PREAUTH_KEYTAB = 'PREAUTH_KEYTAB',
        CUSTOM_LOGIN_CONF = 'CUSTOM_LOGIN_CONF'
    }

    /**
     * Generated from com.dataiku.dip.security.SSOSettings$SSOUserRemappingRule
     */
    export interface SSOUserRemappingRule {
        ruleFrom: string;
        ruleTo: string;
    }

    /**
     * Generated from com.dataiku.dip.security.SSOSettings$SSOProtocol
     */
    export enum SSOProtocol {
        SAML = 'SAML',
        SPNEGO = 'SPNEGO'
    }
}
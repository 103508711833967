import {AbstractHeaderCard, _AbstractHeaderCard} from './../common/abstract-header-card';
import {CardResult} from './../card-result';
import {Card} from './../card';

export namespace UnivariateHeaderCard {
    /**
     * Generated from com.dataiku.dip.eda.worksheets.cards.univariate_header.UnivariateHeaderCard$UnivariateHeaderCardResult
     */
    export interface UnivariateHeaderCardResult extends AbstractHeaderCard._AbstractHeaderCardResult {
        // PolyJson type
        type: 'univariate_header';
    }

    export function isUnivariateHeaderCardResult(toTest: CardResult): toTest is UnivariateHeaderCardResult { return 'univariate_header' === toTest.type; }
}

/**
 * Generated from com.dataiku.dip.eda.worksheets.cards.univariate_header.UnivariateHeaderCard
 */
export interface UnivariateHeaderCard extends _AbstractHeaderCard {
    showBoxPlot: boolean;
    showCumulativeDistributionFunction: boolean;
    showFrequencyTable: boolean;
    showHistogram: boolean;
    showQuantile: boolean;
    showSummary: boolean;

    // PolyJson type
    type: 'univariate_header';
}

export function isUnivariateHeaderCard(toTest: Card): toTest is UnivariateHeaderCard { return 'univariate_header' === toTest.type; }
import { Input, Component, ChangeDetectionStrategy, Output, EventEmitter } from '@angular/core';
import { EChartsOption } from 'echarts';

@Component({
    selector: 'lazy-echart',
    templateUrl: './lazy-echart.component.html',
    styleUrls: ['./lazy-echart.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class LazyEchartComponent {
    @Input() options: EChartsOption;
    @Input() passThrough = false;
    @Input() compactLoading = false;
    @Output() chartClick = new EventEmitter();
    @Output() chartInit = new EventEmitter();
}

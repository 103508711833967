import { Component, Input, ChangeDetectionStrategy, EventEmitter, Output, SimpleChanges, OnChanges } from '@angular/core';
import { BivariateBoxPlotCard, Variable } from 'src/generated-sources';
import { EChartsOption, SeriesOption, XAXisComponentOption, YAXisComponentOption } from 'echarts';
import { CardAction } from '@features/eda/worksheet/cards/events';
import { isDefined } from '@utils/objects';
import { encodeHTML } from 'entities';
import { FilterNamePipe } from '@features/eda/pipes/filter-name.pipe';
import { smarterNumber } from '@shared/pipes/number-pipes/smarter-number.pipe';

const filterNamePipe = new FilterNamePipe();

@Component({
    selector: 'bivariate-box-plot-card-body',
    templateUrl: './bivariate-box-plot-card-body.component.html',
    styleUrls: [
        '../../../../shared-styles/chart.less',
        './bivariate-box-plot-card-body.component.less'
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class BivariateBoxPlotCardBodyComponent implements OnChanges {
    @Input() params: BivariateBoxPlotCard;
    @Input() results: BivariateBoxPlotCard.BivariateBoxPlotCardResult;
    @Input() hasFixedHeight: boolean;
    @Output() action = new EventEmitter<CardAction>();

    options: EChartsOption | undefined;

    ngOnChanges(changes: SimpleChanges) {
        if (changes.results) {
            this.options = this.buildChartOptions();
        }
    }

    buildChartOptions(): EChartsOption | undefined {
        if (!this.results || !this.results.boxPlots) {
            return undefined;
        }
        let maxVal: number = Number.MIN_SAFE_INTEGER;
        let minVal: number = Number.MAX_SAFE_INTEGER;

        const drawableBoxPlots = this.results.boxPlots.map(
            (curBoxPlot, i) => {
                if (!isDefined(curBoxPlot.max) || !isDefined(curBoxPlot.min) || !isDefined(curBoxPlot.pc01)
                    || !isDefined(curBoxPlot.pc25) || !isDefined(curBoxPlot.median) || !isDefined(curBoxPlot.pc75)
                    || !isDefined(curBoxPlot.pc99)) {
                    return undefined;
                }
                if (curBoxPlot.max != null) {
                    maxVal = Math.max(maxVal, curBoxPlot.max);
                }
                if (curBoxPlot.min != null) {
                    minVal = Math.min(minVal, curBoxPlot.min);
                }
                return curBoxPlot;
            }
        );

        const data: ((number | null| undefined)[] | {})[] = [];
        const isVertical = this.params.yColumn.type !== Variable.Type.CATEGORICAL;
        let labels: (string | null | undefined)[] = [];

        if (drawableBoxPlots && this.results.axisVals) {
            labels = this.results.axisVals.map(value => filterNamePipe.transform(value));

            drawableBoxPlots.forEach((boxPlot, i) => {
                if (boxPlot) {
                    data.push([
                        boxPlot.pc01,
                        boxPlot.pc25,
                        boxPlot.median,
                        boxPlot.pc75,
                        boxPlot.pc99
                    ]);
                } else {
                    data.push({});
                }
            });
        }

        // categorical or binned numerical axis
        const catAxis = [{
            type: 'category',
            data: labels,
            axisTick: { show: true },
            axisLine: { show: true },
            axisLabel: {
                color: '#999999'
            }
        }];

        const numAxis = [{
            type: 'value',
            min: smarterNumber(minVal),
            max: smarterNumber(maxVal),
            axisTick: { show: true },
            axisLine: { show: true },
            axisLabel: {
                color: '#999999',
                formatter: (value: number) => {
                    let stringRepr = '' + value;
                    if (stringRepr.length > 6) {
                        stringRepr = value.toPrecision(5);
                    }
                    return stringRepr;
                }
            }
        }];

        return {
            color: ['#3398DB'],
            animation: false,
            tooltip: {
                confine: true,
                trigger: 'item',
                axisPointer: {
                    type: 'shadow'
                }
            },
            grid: { left: 10, top: 10, right: 10, bottom: 10, containLabel: true },
            xAxis: (isVertical ? catAxis : numAxis) as XAXisComponentOption[],
            yAxis: (isVertical ? numAxis : catAxis) as YAXisComponentOption[],
            series: [{
                type: 'boxplot',
                tooltip: {
                    formatter: (param: { dataIndex: number, data: number[] }) => {
                        return encodeHTML(this.results.axisVariable.name) + ': <b>'
                            + encodeHTML(labels[param.dataIndex] || '')
                            + '</b><br>'
                            + encodeHTML(this.results.aggregatedVariable.name) + ': <br>'
                            + [
                                '&bull; 1st percentile: ' + encodeHTML(smarterNumber(param.data[1])),
                                '&bull; 1st quartile: ' + encodeHTML(smarterNumber(param.data[2])),
                                '&bull; Median: ' + encodeHTML(smarterNumber(param.data[3])),
                                '&bull; 3rd quartile: ' + encodeHTML(smarterNumber(param.data[4])),
                                '&bull; 99th percentile: ' + encodeHTML(smarterNumber(param.data[5]))
                            ].join('<br/>');
                    }
                },
                data
            } as SeriesOption]
        };
    }
}

import {CardResult, _CardResult} from './card-result';
import {Card} from './card';
import {Filter} from './../../compute/filtering/filter';
import {HeatmapParams} from './common/heatmap-params';
import {SplitBySpec} from './../models/split-by-spec';
import {_MultivariateCard} from './multivariate-card';

/**
 * Generated from com.dataiku.dip.eda.worksheets.cards.PCACard
 */
export interface PCACard extends _MultivariateCard {
    colorBy?: SplitBySpec | null;
    heatmapParams: HeatmapParams;

    // PolyJson type
    type: 'pca';
}

export function isPCACard(toTest: Card): toTest is PCACard { return 'pca' === toTest.type; }

export namespace PCACard {
    /**
     * Generated from com.dataiku.dip.eda.worksheets.cards.PCACard$PCACardResult
     */
    export interface PCACardResult extends _CardResult {
        components: number[][];
        explainedVariance: number[];
        explainedVarianceRatio: number[];
        groups: Filter[];
        partialErrors?: string[] | null;
        projections: number[][][];

        // PolyJson type
        type: 'pca';
    }

    export function isPCACardResult(toTest: CardResult): toTest is PCACardResult { return 'pca' === toTest.type; }
}
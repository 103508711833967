import { ObservableInput } from 'observable-input';
import { startWith, map } from 'rxjs/operators';
import { Observable, Subject, combineLatest, BehaviorSubject } from 'rxjs';
import { Component, Input, Output, HostListener, EventEmitter, ViewChild, OnDestroy, forwardRef, SimpleChanges, OnChanges, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatAutocompleteTrigger, MatAutocomplete, AUTOCOMPLETE_PANEL_HEIGHT } from '@angular/material/autocomplete';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { _countGroupLabelsBeforeOption, _getOptionScrollPosition, MatOptionSelectionChange } from '@angular/material/core';


/**
 * Input with typeahead. Meant for downgrade in angularjs.
 */
@UntilDestroy()
@Component({
    selector: 'edit-typeahead',
    templateUrl: './edit-typeahead-input.component.html',
    styleUrls: ['./edit-typeahead-input.component.less'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => EditTypeaheadComponent),
            multi: true
        }
    ]
})
export class EditTypeaheadComponent implements ControlValueAccessor, OnDestroy, OnInit {
    @Input() type: string = 'text';
    @Input() name: string;
    @Input() placeholder: string;
    @Input() required: boolean = false;
    @Input() displayFn?: (option: any) => string;
    @Input() valueFn?: (option: any) => any;
    @Input() autocompletePanelWidth?: number;
    @Input() noMatchTooltip: string | null = null;


    @Output() onFocus: EventEmitter<any> = new EventEmitter();
    @Output() onBlur: EventEmitter<any> = new EventEmitter();
    @Output() onEnter: EventEmitter<any> = new EventEmitter();
    @Output() inputChange: EventEmitter<string> = new EventEmitter();
    // suggestions for mat-autocomplete
    @Input() @ObservableInput() suggestions: Observable<Object[]>;
    @ViewChild(MatAutocomplete) matAutocompleteEl: MatAutocomplete;
    @ViewChild(MatAutocompleteTrigger) matAutocompleteTrigger: MatAutocompleteTrigger;

    // whether or not user selected item in autocomplete suggestion list
    selectedAutocompleteItemViaEnter: boolean = false;

    values = new BehaviorSubject<string>('');

    filteredSuggestions: Observable<Object[]>;
    computedTooltip: Observable<string|null>;

    onChange: Function = () => { };

    onTouched: Function = () => { };

    val = null;

    ngAfterViewInit() {
        this.fixAutocompleteScroll();
    }

    ngOnDestroy(): void {
    }

    ngOnInit() {
        this.filteredSuggestions = combineLatest([this.values, this.suggestions])
            .pipe(untilDestroyed(this),
                map(([value, suggestions]) => {
                    if (!value) {
                        return suggestions;
                    }
                    const filterValue = value.toLowerCase();
                    return suggestions?suggestions.filter(suggestion =>
                        this.computeValue(suggestion).toLowerCase().includes(filterValue)
                        || this.computeDisplay(suggestion).toLowerCase().includes(filterValue)
                        ):[];
                })
            );

        this.computedTooltip = combineLatest([this.values, this.suggestions])
            .pipe(untilDestroyed(this),
                map(([value, suggestions]) => {
                    if ((null == value) || (undefined == value) || !suggestions || !this.valueFn || !this.displayFn) {
                        return null;
                    }
                    for (let sug of suggestions) {
                        if (this.valueFn(sug) === value) {
                            return this.displayFn(sug);
                        }
                    }
                    return this.noMatchTooltip;
                })
            );
    }

    handleFocus($event: FocusEvent) {
        this.onFocus.emit($event);
    }

    handleBlur() {
        this.onBlur.emit();
    }

    /*
        Whenever an autocomplete item is selected, set
        flag to true. If item was selected via click,
        it will be reset (see onSelectionClick).

        This flag is used to prevent the editable list from
        creating a new entry after selecting a suggestion.
    */
    onSelectionChange(event: MatOptionSelectionChange) {
        this.selectedAutocompleteItemViaEnter = true;
    }

    /*
        Fires after onSelectionChange.
        If selected autocomplete item was not done via enter key,
        reset flag.
    */
    onSelectionClick(event: any) {
        this.selectedAutocompleteItemViaEnter = false;
    }

    @HostListener('keydown.enter', ['$event'])
    handleEnter(event: KeyboardEvent) {
        if (!this.selectedAutocompleteItemViaEnter) {
            this.onEnter.emit(event);
        }

        this.matAutocompleteTrigger.closePanel();
        this.selectedAutocompleteItemViaEnter = false;
    }

    /*
        https://github.com/angular/components/issues/3419

        When using the up/down arrows to scroll through a select or autocomplete
        list with custom height mat-option elements, the selected item becomes
        out of sync because the mat-option height is hard coded in the
        autocomplete code.

        This workaround recalculates the scroll amount using the actual height
        of mat-option.
    */
    fixAutocompleteScroll() {
        this.matAutocompleteTrigger['_scrollToOption'] = () => {
            const optionHeight = this.matAutocompleteEl.options.first._getHostElement().clientHeight;
            const index: number = this.matAutocompleteEl['_keyManager'].activeItemIndex || 0;
            const labelCount = _countGroupLabelsBeforeOption(index, this.matAutocompleteEl.options, this.matAutocompleteEl.optionGroups);
            const newScrollPosition = _getOptionScrollPosition(index + labelCount, optionHeight, this.matAutocompleteEl._getScrollTop(), AUTOCOMPLETE_PANEL_HEIGHT);

            this.matAutocompleteEl._setScrollTop(newScrollPosition);
        };
    }


    get value() {
        return this.val;
    }

    set value(val: any){  // this value is updated by programmatic changes if( val !== undefined && this.val !== val){
        this.val = val;
        this.onChange(val);
        this.onTouched(val);
        this.values.next(<string><unknown>this.val);
    }

    writeValue(obj: any): void {
        this.val = obj;
        this.values.next(<string><unknown>this.val);
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    computeDisplay(obj: any): string {
        return this.displayFn?this.displayFn(obj):obj;
    }

    computeValue(obj: any): string {
        return this.valueFn?this.valueFn(obj):obj;
    }
}

import { Injectable } from '@angular/core';
import { PrivateDatasetsAPI } from './definitions/private-datasets-api';
import { PrivateStatisticsAPI } from './definitions/private-statistics-api';
import { PrivateProjectsAPI } from './definitions/private-projects-api';
import { PrivateDashboardsAPI } from './definitions/private-dashboards-api';
import { PrivateTaggableObjectsAPI } from './definitions/private-taggable-objects-api';
import { PrivateAnalysisAPI } from './definitions/private-analysis-api';
import { PrivateFlowAPI } from './definitions/private-flow-api';

@Injectable({
    providedIn: 'root'
})
export class DataikuAPIService {
    constructor(
        public datasets: PrivateDatasetsAPI,
        public statistics: PrivateStatisticsAPI,
        public taggableObjects: PrivateTaggableObjectsAPI,
        public projects: PrivateProjectsAPI,
        public flow: PrivateFlowAPI,
        public dashboards: PrivateDashboardsAPI,
        public analysis: PrivateAnalysisAPI
    ) { }
}

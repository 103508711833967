/**
 * Generated from com.dataiku.dip.analysis.model.core.SavedModelOriginInfo
 */
export interface SavedModelOriginInfo {
    fullModelId: string;
    jobId: string;
    origin: SavedModelOriginInfo.Origin;
}

export namespace SavedModelOriginInfo {
    /**
     * Generated from com.dataiku.dip.analysis.model.core.SavedModelOriginInfo$Origin
     */
    export enum Origin {
        EXPORTED_FROM_ANALYSIS = 'EXPORTED_FROM_ANALYSIS',
        TRAINED_FROM_RECIPE = 'TRAINED_FROM_RECIPE'
    }
}
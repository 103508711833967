import { Observable } from 'rxjs';
import { ObservableInput } from 'observable-input';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Component, Input, Output, EventEmitter, Inject, forwardRef } from '@angular/core';
import { IScope } from 'angular';

@Component({
    selector: 'downgraded-edit-typeahead-input',
    templateUrl: './downgraded-edit-typeahead-input.component.html'
})

/**
 * Bridge between Angular and AngularJS to use editable input with typeahead in the later.
 * Simply provides proper inputs and outputs, adapts ControlValueAccessor structure and propagates changes.
 * Should be used in complex cases, when displayed label is different from the value.
 *
 * @example
 * // In AngularJS code
 * <ng2-typeahead [(value)]="myChoice" [suggestions]="suggestions"></ng2-typeahead>
 *
 * suggestions being an array of string.
 *
 * Note: this simple example has no advantage over:
 *
 * <input type="text" bs-typeahead="suggestions" ng-model="myChoice" />
 *
 * and, so, this later should be prefered.
 *
 * @example
 * // In AngularJS code
 * <ng2-typeahead [(value)]="desc.modelVersionId" [suggestions]="modelVersions"
 *      [display-fn]="versionDisplayFn" [value-fn]="versionValueFn"
 *      autocomplete-panel-width="600"
 *      no-match-tooltip="(current input does not match a suggestion)"></ng2-typeahead>
 *
 *  with modelVersions = [{
 *      versionId: "",
 *      label: "Active version"
 *  },{
 *      versionId: "123456789",
 *      label: "Random Forest blah blah - 123456789"
 *  }]
 *
 *
 *   and
 *
 *  $scope.versionDisplayFn = function(version) {
 *      return version.label;
 *  }
 *
 *  $scope.versionValueFn = function(version) {
 *      return version.versionId;
 *  }
 *
 * will display an input text with typeahead, suggestions being displayed by label, and a tooltip being displayed over current value, if it matches a suggestion
*/
export class DowngradedEditTypeaheadInputComponent {
    @Input() value: string;
    @Input() name: string;
    @Input() placeholder: string;
    @Input() required: boolean = false;
    // suggestions for mat-autocomplete
    @Input() @ObservableInput() suggestions: Observable<Object[]>;
    @Input() displayFn?: (option: any) => string;
    @Input() valueFn?: (option: any) => any;
    @Input() autocompletePanelWidth?: number;
    @Input() noMatchTooltip?: string;

    @Output() onFocus: EventEmitter<any> = new EventEmitter();
    @Output() onBlur: EventEmitter<any> = new EventEmitter();
    @Output() onEnter: EventEmitter<any> = new EventEmitter();
    @Output() onValidityChange = new EventEmitter<boolean>();
    @Output() valueChange = new EventEmitter<string>();

    constructor(@Inject('$rootScope') private $rootScope: IScope) {
    }

    handleChange(value: string) {
        this.$rootScope.$applyAsync();
        this.valueChange.emit(value);
    }
}

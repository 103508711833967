import { Injectable } from '@angular/core';
import { ImagePositionInformation, BaseImagePainterOptions } from "@shared/services/image-painter.service";
import { DeepHubColumnFormat } from "src/generated-sources";
import { fabric } from "fabric";
import { DeephubObjectDetectionDesignCellData } from './deephub-design-data-fetcher.service';
import { AbstractObjectDetectionImagePainterService } from './abstract-object-detection-image-painter.service';

@Injectable()
export class DeephubObjectDetectionDesignImagePainterService extends AbstractObjectDetectionImagePainterService {
    categoryToColorMapping: Map<string, string>;

    paintCanvas(cellData: DeephubObjectDetectionDesignCellData, canvas: fabric.StaticCanvas, imagePosition: ImagePositionInformation, options?: BaseImagePainterOptions): void {
        cellData.target?.forEach((elem: DeepHubColumnFormat.ObjectDetectionTargetItem) => {
            const box = this.createBox(elem, imagePosition, {
                stroke: this.categoryToColorMapping.get(elem.category)
            });
            if (box !== null) {
                canvas.add(box);
            }
        });
    }
}

import { Component, ChangeDetectionStrategy, Inject, ChangeDetectorRef, AfterViewInit, TemplateRef} from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BaseImagePainterOptions, ImagePainterService } from '@shared/services/image-painter.service';
import { CellData, ImagesDataFetcherService } from '@shared/services/images-data-fetcher.service';
import { StaticCanvas } from 'fabric/fabric-impl';
import { ReplaySubject } from 'rxjs';
import { map, shareReplay, switchMap } from 'rxjs/operators';
import { SerializedMemTableV2, SerializedTableChunk } from 'src/generated-sources';
import { FeedImageViewerService } from './feed-image-viewer.service';

interface Column {
    name: string,
    index: number,
    content?: any
}

export interface ImageMetaData {
    allColumns: Column[];
    cellData: CellData;
    rawChunk: SerializedTableChunk,
    valid: boolean
}

export interface FeedImageModalParams {
    rowId: number, 
    feedData: SerializedMemTableV2
}

@UntilDestroy()
@Component({
    selector: 'feed-image-modal',
    templateUrl: './feed-image-modal.component.html',
    styleUrls: ['./feed-image-modal.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FeedImageModalComponent implements AfterViewInit {
    private imageId$ = new ReplaySubject<number>(1);
    currentImage: ImageMetaData;

    optionsOutput$ = new ReplaySubject<Object>(1);
    canvasTrigger$ = new ReplaySubject<fabric.StaticCanvas>(1);

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: { 
            rowId: number, 
            columns: Column[],
            sectionTemplate: TemplateRef<any>,
            imageOptions: BaseImagePainterOptions
        },
        public dataFetcher: ImagesDataFetcherService,
        public imagePainter: ImagePainterService,
        public feedImageViewerService: FeedImageViewerService,
        public cd: ChangeDetectorRef
    ) {
        this.imageId$.pipe(
            untilDestroyed(this),
            switchMap(id => this.dataFetcher.getSampleChunk(id, 1)),
            map((chunk: SerializedTableChunk) => ({
                allColumns: this.data.columns
                    .map(column => ({
                        ...column,
                        content: chunk.content[column.index]
                    }), {}),
                cellData: this.dataFetcher.rowToCellData(chunk.content, chunk.firstRow, 0),
                rawChunk: chunk,
                valid: true
            })),
            shareReplay(1)
        ).subscribe(image => {
            this.currentImage = image;
            this.cd.markForCheck();
        });
    }

    ngAfterViewInit(): void {
        this.setImage(this.data.rowId);
    }

    onCanvasUpdate(canvas: StaticCanvas) {
        this.feedImageViewerService.setCanvas(canvas);
    }

    setImage(id: number) {
        this.imageId$.next(id);
    }

    updateImageStatus(hasImage: boolean) {
        if (this.currentImage) {
            this.currentImage = {
                ...this.currentImage,
                valid: hasImage
            }
        }
    }
}

import {DeepHubMetricParams} from './deep-hub-metric-params';
import {PredictionMLTask} from './prediction-mltask';
import {PredictionModelingParams} from './prediction-modeling-params';

/**
 * Generated from com.dataiku.dip.analysis.model.prediction.DeepHubPreTrainModelingParams
 */
export interface _DeepHubPreTrainModelingParams {
    dummy: boolean;
    metrics: DeepHubMetricParams;
    type: PredictionMLTask.PredictionType;
}

export type DeepHubPreTrainModelingParams = DeepHubPreTrainModelingParams.ObjectDetectionPreTrainModelingParams;

export namespace DeepHubPreTrainModelingParams.ObjectDetectionPreTrainModelingParams.TrainableLayers {
    /**
     * Generated from com.dataiku.dip.analysis.model.prediction.DeepHubPreTrainModelingParams$ObjectDetectionPreTrainModelingParams$TrainableLayers$TrainableLayersMode
     */
    export enum TrainableLayersMode {
        AUTO = 'AUTO',
        MANUAL = 'MANUAL'
    }
}

export namespace DeepHubPreTrainModelingParams.ObjectDetectionPreTrainModelingParams {
    /**
     * Generated from com.dataiku.dip.analysis.model.prediction.DeepHubPreTrainModelingParams$ObjectDetectionPreTrainModelingParams$PretrainedModel
     */
    export enum PretrainedModel {
        FASTERRCNN = 'FASTERRCNN'
    }

    /**
     * Generated from com.dataiku.dip.analysis.model.prediction.DeepHubPreTrainModelingParams$ObjectDetectionPreTrainModelingParams$Optimizer
     */
    export enum Optimizer {
        ADAM = 'ADAM',
        SGD = 'SGD',
        RMSPROP = 'RMSPROP',
        ADAMAX = 'ADAMAX',
        ADAGRAD = 'ADAGRAD',
        ADADELTA = 'ADADELTA'
    }

    /**
     * Generated from com.dataiku.dip.analysis.model.prediction.DeepHubPreTrainModelingParams$ObjectDetectionPreTrainModelingParams$BrightnessContrastAugmentationParams
     */
    export interface BrightnessContrastAugmentationParams extends DeepHubPreTrainModelingParams.ObjectDetectionPreTrainModelingParams.ImageAugmentationParam {
        brightnessLimit: number;
        contrastLimit: number;
    }

    /**
     * Generated from com.dataiku.dip.analysis.model.prediction.DeepHubPreTrainModelingParams$ObjectDetectionPreTrainModelingParams$ImageAugmentationParams
     */
    export interface ImageAugmentationParams {
        brightnessContrast: DeepHubPreTrainModelingParams.ObjectDetectionPreTrainModelingParams.BrightnessContrastAugmentationParams;
    }

    /**
     * Generated from com.dataiku.dip.analysis.model.prediction.DeepHubPreTrainModelingParams$ObjectDetectionPreTrainModelingParams$TrainableLayers
     */
    export interface TrainableLayers {
        mode: DeepHubPreTrainModelingParams.ObjectDetectionPreTrainModelingParams.TrainableLayers.TrainableLayersMode;
        value: number;
    }

    /**
     * Generated from com.dataiku.dip.analysis.model.prediction.DeepHubPreTrainModelingParams$ObjectDetectionPreTrainModelingParams$ImageAugmentationParam
     */
    export interface ImageAugmentationParam {
        enabled: boolean;
        probability: number;
    }

    /**
     * Generated from com.dataiku.dip.analysis.model.prediction.DeepHubPreTrainModelingParams$ObjectDetectionPreTrainModelingParams$LrScheduler
     */
    export enum LrScheduler {
        PLATEAU = 'PLATEAU',
        STEP = 'STEP',
        EXPONENTIAL = 'EXPONENTIAL'
    }

    /**
     * Generated from com.dataiku.dip.analysis.model.prediction.DeepHubPreTrainModelingParams$ObjectDetectionPreTrainModelingParams$EarlyStopping
     */
    export interface EarlyStopping {
        enabled: boolean;
        minDelta: number;
        patience: number;
    }
}

export namespace DeepHubPreTrainModelingParams {
    /**
     * Generated from com.dataiku.dip.analysis.model.prediction.DeepHubPreTrainModelingParams$ObjectDetectionPreTrainModelingParams
     */
    export interface ObjectDetectionPreTrainModelingParams extends _DeepHubPreTrainModelingParams {
        augmentationParams: DeepHubPreTrainModelingParams.ObjectDetectionPreTrainModelingParams.ImageAugmentationParams;
        earlyStopping: DeepHubPreTrainModelingParams.ObjectDetectionPreTrainModelingParams.EarlyStopping;
        enableParallelDataLoading: boolean;
        epochs: number;
        gpuParams: PredictionModelingParams.GpuCodeParams;
        learningRate: number;
        lrScheduler: DeepHubPreTrainModelingParams.ObjectDetectionPreTrainModelingParams.LrScheduler;
        numWorkers: number;
        optimizer: DeepHubPreTrainModelingParams.ObjectDetectionPreTrainModelingParams.Optimizer;
        perDeviceBatchSize: number;
        pretrainedModel: DeepHubPreTrainModelingParams.ObjectDetectionPreTrainModelingParams.PretrainedModel;
        processCountPerNode: number;
        trainableLayers: DeepHubPreTrainModelingParams.ObjectDetectionPreTrainModelingParams.TrainableLayers;
        weightDecay: number;
    }
}
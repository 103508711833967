import { Injectable } from '@angular/core';
import { Observable, ReplaySubject, Subject } from 'rxjs';
import { SerializedMemTableV2, SerializedTableChunk } from 'src/generated-sources';

export interface CellData {
    itemPath: string,
    rowId: number
}

@Injectable()
export abstract class ImagesDataFetcherService {
    NUM_IMAGES_PER_ROW = 3;
    NUM_ROWS_PER_PAGE = 20;
    itemPathColIndex: number;
    managedFolderId: string;

    private subject = new ReplaySubject<void>(1);
    dataChanged$: Observable<void> = this.subject.asObservable();

    abstract refreshSample(): Observable<SerializedMemTableV2>;
    
    abstract getSampleChunk(firstRow: number, nbRows?: number): Observable<SerializedTableChunk>;

    abstract onRefreshSample(sample: SerializedMemTableV2): void;

    abstract rowToCellData(rowContent: string[], i: number, j: number): CellData;

    abstract getImagePath(itemPath: string): string;

    onDataChanged(): void {
        this.subject.next();
    }
}

import '@angular/compiler';
import { enableProdMode } from '@angular/core';
import { environment } from './environments/environment';
import { AppModule } from '@app/app.module';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { downgradeModule } from '@angular/upgrade/static';
import { StaticProvider } from '@angular/core';
import { downgradeComponents } from '@migration/downgraded-components';
import { downgradeProviders } from '@migration/downgraded-providers';
import { setAutoFreeze } from 'immer';

import 'echarts';
import 'echarts-gl';

declare var angular: any;

if (environment.production) {
    enableProdMode();
    setAutoFreeze(false);
}

const bootstrapFn = (extraProviders: StaticProvider[]) => {
    return platformBrowserDynamic(extraProviders).bootstrapModule(AppModule);
};

angular.module('ng-upgrade', ['dataiku', downgradeModule(bootstrapFn)]);

downgradeProviders();
downgradeComponents();

angular.bootstrap(document, ['ng-upgrade'], {
    // strictDi: true means that angular rejects old-style dependancy declaration
    // (ie using only function arguments names, because they cannot be minified)
    // https://docs.angularjs.org/api/ng/directive/ngApp
    strictDi: false
});

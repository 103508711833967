import {CardResult} from './../cards/card-result';
import {Card} from './../cards/card';
import {InteractiveQuery, _InteractiveQuery} from './interactive-query';
import {Variable} from './../models/variable';

/**
 * Generated from com.dataiku.dip.eda.worksheets.queries.SuggestCards
 */
export interface SuggestCards extends _InteractiveQuery {
    selectedVariables: string[];

    // PolyJson type
    type: 'suggest_cards';
}

export function isSuggestCards(toTest: InteractiveQuery): toTest is SuggestCards { return 'suggest_cards' === toTest.type; }

export namespace SuggestCards {
    /**
     * Generated from com.dataiku.dip.eda.worksheets.queries.SuggestCards$SuggestCardsResult
     */
    export interface SuggestCardsResult extends InteractiveQuery._InteractiveQueryResult {
        suggestedCards: SuggestCards.SuggestedCard[];
        suggestedVariables: SuggestCards.SuggestedVariable[];

        // PolyJson type
        type: 'suggest_cards';
    }

    export function isSuggestCardsResult(toTest: InteractiveQuery.InteractiveQueryResult): toTest is SuggestCardsResult { return 'suggest_cards' === toTest.type; }

    /**
     * Generated from com.dataiku.dip.eda.worksheets.queries.SuggestCards$SuggestedCard
     */
    export interface SuggestedCard {
        card: Card;
        miniatureCard: Card;
        miniatureResult: CardResult;
        name: string;
    }

    /**
     * Generated from com.dataiku.dip.eda.worksheets.queries.SuggestCards$SuggestedVariable
     */
    export interface SuggestedVariable {
        card: Card;
        explanations: string[];
        individualScore: number;
        name: string;
        result: CardResult;
        totalScore: number;
        type: Variable.Type;
    }
}
import { SuggestCards } from './../../../../../../generated-sources/src/main/java/com/dataiku/dip/eda/worksheets/queries/suggest-cards';
import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from "@angular/core";
import { isAbstractHeaderCard, isMultivariateCard, isPCACard } from 'src/generated-sources';

@Component({
    selector: 'proposed-card',
    templateUrl: './proposed-card.component.html',
    styleUrls: ['./proposed-card.component.less']
})
export class ProposedCard implements AfterViewInit {
    @Input() isSelected: boolean;
    @Input() proposedVisualization: SuggestCards.SuggestedCard;
    @Output() select: EventEmitter<any> = new EventEmitter();
    @Output() showDetails: EventEmitter<any> = new EventEmitter();

    @ViewChild("cardSuggestionBodyEl") cardSuggestionBodyEl: ElementRef;
    @ViewChild("cardSuggestionEl") cardSuggestionEl: ElementRef;

    constructor(private cd: ChangeDetectorRef) {
    }

    ngAfterViewInit() {
        const desiredWidth = this.cardSuggestionEl.nativeElement.scrollWidth;
        const desiredHeight = this.cardSuggestionEl.nativeElement.scrollHeight;
        const currentWidth = this.cardSuggestionEl.nativeElement.clientWidth ;
        const currentHeight = this.cardSuggestionEl.nativeElement.clientHeight ;

        const widthRatio = currentWidth / desiredWidth;
        const heightRatio = currentHeight / desiredHeight;
        const bestRatio = Math.min(widthRatio, heightRatio, 1.0) * 0.9;

        if (bestRatio < 1.0) {
            this.cardSuggestionBodyEl.nativeElement.style.transform = `scale(${bestRatio})`;
            this.cardSuggestionBodyEl.nativeElement.style.transformOrigin = 'top left';
            this.cardSuggestionEl.nativeElement.style.overflow = "hidden";
            this.cd.markForCheck();
        }
    }

    get isWideThumbnail(): boolean {
        const { card } = this.proposedVisualization;
        return isAbstractHeaderCard(card)
            || isPCACard(card);
    }

    get isLongThumbnail(): boolean {
        const { card } = this.proposedVisualization;
        return card.splitBy != null;
    }
}

<form [formGroup]="configForm" id="config-form">
    <div cdkDropListGroup class="card-header-editor horizontal-flex">
        <div class="card-header-editor__column">
            <span class="card-header-editor__description">1. Select variables</span>
            <div class="card-header-editor__header">
                <h4 class="card-header-editor__title">
                    {{ count }} available variables
                </h4>
            </div>
            <list-box [list]="currentVariables$ | async" [countTitle]="'available variables'"
                [disabledMessage]="'Variable must be numerical.'"
                class="vertical-flex card-header-editor__list"
                (select)="selectedAvailableVariables = $event"
                (dropped)="onDropVariables($event)"
                dragDropable="true"
                sortDraggable="true"
                multiselectable="true"
                dragDropable="true">
            </list-box>
            <div class="card-header-editor__footnote">
                <i class="icon-lightbulb"></i> Use <strong>Shift + Click</strong> to select a range of variables.
            </div>
        </div>
        <div class="card-header-editor__column">
            <span class="card-header-editor__description">2. Add variables</span>
            <div class="card-header-editor__header">
                <h4 class="card-header-editor__title"
                    [matTooltip]="tooManySelectedVariablesWarning">
                    {{ configForm.value.columns.length }} selected variables <i *ngIf="tooManySelectedVariablesWarning" class="icon-warning-sign text-error"></i>
                </h4>
                <div>
                    <button class="btn btn--text btn--primary btn--icon" (click)="add()"
                        [disabled]="!canAdd"
                        [qa]="{'eda-add-variables-button': {as: 'variable'}}">
                        <i class="icon-plus"></i>
                    </button>
                    <button class="btn btn--text btn--primary btn--icon" (click)="del()"
                        [disabled]="selectedSelectedVariables.length < 1"
                        [qa]="{'eda-del-variables-button': {as: 'variable'}}">
                        <i class="icon-trash"></i>
                    </button>
                </div>
            </div>
            <list-box-form-control formControlName="columns"
                class="vertical-flex card-header-editor__list"
                dragDropable="true"
                sortDraggable="true"
                multiselectable="true"
                placeHolderText="(Drag variable(s) here)"
                (dropped)="onDropAdd($event)"
                (select)="selectedSelectedVariables = $event"></list-box-form-control>
        </div>
        <div class="card-header-editor__column" *ngIf="hasOptionalParameters(params)">
            <span class="card-header-editor__description">3. Select options</span>
            <div class="card-header-editor__header">
                <h4 class="card-header-editor__title">Options</h4>
            </div>
            <div class="card-header-editor__options" *ngIf="isParallelCoordinatesPlotCard(params)">
                <div class="control-group">
                    <label class="control-label control-label">
                        Maximum number of points
                        <i class="icon-dku-help"
                            matTooltip="The points are randomly drawn from the sample of the worksheet"
                        ></i>
                    </label>
                    <input
                        type="number"
                        class="control"
                        formControlName="maxNumberOfPoints"
                        [qa]="'maximum-number-of-points'"
                    >
                </div>
            </div>
            <div class="card-header-editor__options" *ngIf="isCorrelationMatrixCard(params)">
                <div class="control-group">
                    <label class="control-label control-label--flex">
                        <input type="radio" class="control" formControlName="metric" [value]="CorrelationMetric.SPEARMAN"
                            [qa]="{'eda-select-correlation-metric-radio': {metric: 'SPEARMAN'}}">
                        Spearman
                    </label>
                </div>
                <div class="control-group">
                    <label class="control-label control-label--flex">
                        <input type="radio" class="control" formControlName="metric" [value]="CorrelationMetric.PEARSON"
                        [qa]="{'eda-select-correlation-metric-radio': {metric: 'PEARSON'}}">
                        Pearson
                    </label>
                </div>
            </div>
        </div>
    </div>
</form>

import {_PreprocessingParams} from './preprocessing-params';

/**
 * Generated from com.dataiku.dip.analysis.model.preprocessing.ClusteringPreprocessingParams
 */
export interface ClusteringPreprocessingParams extends _PreprocessingParams {
    outliers: ClusteringPreprocessingParams.OutliersParams;
    reduce: ClusteringPreprocessingParams.ReductionParams;
}

export namespace ClusteringPreprocessingParams {
    /**
     * Generated from com.dataiku.dip.analysis.model.preprocessing.ClusteringPreprocessingParams$ReductionParams
     */
    export interface ReductionParams {
        disable: boolean;
        enable: boolean;
        kept_variance: number;
    }

    /**
     * Generated from com.dataiku.dip.analysis.model.preprocessing.ClusteringPreprocessingParams$OutliersParams
     */
    export interface OutliersParams {
        method: ClusteringPreprocessingParams.OutliersHandlingMethod;
        min_cum_ratio: number;
        min_n: number;
    }

    /**
     * Generated from com.dataiku.dip.analysis.model.preprocessing.ClusteringPreprocessingParams$OutliersHandlingMethod
     */
    export enum OutliersHandlingMethod {
        NONE = 'NONE',
        DROP = 'DROP',
        CLUSTER = 'CLUSTER'
    }
}
import * as dep_dku_frontend_core from 'dku-frontend-core/generated-sources';
import {ClusteringMLTask} from './clustering/clustering-mltask';
import {ContainerExecSelection} from './../../containers/exec/container-exec-selection';
import {MLSparkParams} from './../ml/mlspark-params';
import {PredictionMLTask} from './prediction/prediction-mltask';
import {SerializedShakerScript} from './../../shaker/model/serialized-shaker-script';
import {SparkConstants} from './../ml/spark-constants';

/**
 * Generated from com.dataiku.dip.analysis.model.MLTask
 */
export interface _MLTask {
    backendType: MLTask.BackendType;
    containerSelection: ContainerExecSelection;
    diagnosticsSettings: MLTask.DiagnosticsSettings;
    envSelection: any /* TODO: Missing translation of com.dataiku.dip.code.CodeEnvSelection */;
    id: string;
    initiator: string;
    labels: dep_dku_frontend_core.SimpleKeyValue[];
    maxConcurrentModelTraining: number;
    name: string;
    predictionDisplayCharts: MLTask.PredictedDataChart[];
    predictionDisplayScript: SerializedShakerScript;
    sparkCheckpoint: SparkConstants.Checkpoint;
    sparkCheckpointDir: string;
    sparkParams: MLSparkParams;
    taskType: MLTask.MLTaskType;
}

export type MLTask = PredictionMLTask.ClassicalPredictionMLTask | ClusteringMLTask | PredictionMLTask.DeepHubPredictionMLTask;

export namespace MLTask {
    /**
     * Generated from com.dataiku.dip.analysis.model.MLTask$DiagnosticsSettings
     */
    export interface DiagnosticsSettings {
        enabled: boolean;
        settings: MLTask.DiagnosticSetting[];
    }

    /**
     * Generated from com.dataiku.dip.analysis.model.MLTask$DiagnosticSetting
     */
    export interface DiagnosticSetting {
        enabled: boolean;
        type: any /* TODO: Missing translation of com.dataiku.dip.warnings.WarningsContext$WarningType */;
    }

    /**
     * Generated from com.dataiku.dip.analysis.model.MLTask$PredictedDataChart
     */
    export interface PredictedDataChart {
        def: any /* TODO: Missing translation of com.dataiku.dip.pivot.frontend.model.ChartDef */;
    }

    /**
     * Generated from com.dataiku.dip.analysis.model.MLTask$MLTaskType
     */
    export enum MLTaskType {
        PREDICTION = 'PREDICTION',
        CLUSTERING = 'CLUSTERING'
    }

    /**
     * Generated from com.dataiku.dip.analysis.model.MLTask$BackendType
     */
    export enum BackendType {
        PY_MEMORY = 'PY_MEMORY',
        MLLIB = 'MLLIB',
        VERTICA = 'VERTICA',
        H2O = 'H2O',
        KERAS = 'KERAS',
        DEEP_HUB = 'DEEP_HUB'
    }
}
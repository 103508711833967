import { Component, Input, OnChanges, ChangeDetectionStrategy } from '@angular/core';
import { TZTest1SampCard } from 'src/generated-sources';
const { jStat } = require('jStat'); // This library is packaged as shit
import * as _ from 'lodash';
import { EChartsOption } from 'echarts';
import { zipSameSize } from '@features/eda/echarts-utils';

@Component({
    selector: 'tztest1-samp-card-body',
    templateUrl: './tztest1-samp-card-body.component.html',
    styleUrls: [
        '../../../../shared-styles/test-conclusion.less',
        '../../../../shared-styles/stats-table.less',
        '../../../../shared-styles/card-layout.less',
        './tztest1-samp-card-body.component.less'
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TZTest1SampCardBodyComponent implements OnChanges {
    @Input() params: TZTest1SampCard;
    @Input() results: TZTest1SampCard.TZTest1SampCardResult;

    statisticDistributionChartOptions: EChartsOption;

    ngOnChanges() {
        let statDistribPdf;
        if (this.params.knownStdDev != null) {
            statDistribPdf = (x: number): number => jStat.normal.pdf(x, 0, 1);
        } else {
            statDistribPdf = (x: number): number => jStat.studentt.pdf(x, this.results.dof);
        }

        this.rebuildTZDistributionChart(statDistribPdf, this.results.statistic);
    }

    get isRejected() {
        return this.results.pvalue <= (1 - this.params.confidenceLevel);
    }

    rebuildTZDistributionChart(distribution: (x: number) => number, statistic: number) {
        const absStatistic = Math.abs(statistic);
        const xMax = Math.max(absStatistic * 1.2, 6);
        const xMin = Math.min(-absStatistic * 1.2, -6);
        const nbPoints = 100;

        const xVals = _.range(0, nbPoints + 1)
            .map(pos => xMin * (pos / nbPoints) + xMax * (1 - pos / nbPoints));
        xVals.push(statistic);
        xVals.push(-statistic);
        xVals.sort((a, b) => a - b);

        const xValsBefore = xVals.filter(x => x <= -absStatistic);
        const xValsAfter = xVals.filter(x => x >= absStatistic);

        const yValsBefore = _.map(xValsBefore, x => distribution(x));
        const yValsAfter = _.map(xValsAfter, x => distribution(x));
        const yVals = _.map(xVals, x => distribution(x));

        this.statisticDistributionChartOptions = {
            color: ['#3398DB'],
            animation: false,
            tooltip: {
                trigger: 'item',
                axisPointer: { type: 'none' }
            },
            grid: { left: 0, top: 10, right: 0, bottom: 0, containLabel: true },
            xAxis: [{
                type: 'value',
                axisTick: { show: true },
                axisLine: { show: true },
                axisLabel: { color: '#999999' }
            }],
            yAxis: {
                type: 'value',
                axisLine: { show: false },
                axisTick: { show: false },
                axisLabel: { color: '#999999' }
            },
            series: [
                {
                    type: 'line',
                    data: zipSameSize(xVals, yVals),
                    symbol: 'none'
                },
                {
                    type: 'line',
                    data: zipSameSize(xValsBefore, yValsBefore),
                    symbol: 'none',
                    areaStyle: {},
                    lineStyle: { opacity: 0 },
                    markLine: {
                        silent: true,
                        symbolSize: 0,
                        label: {
                            position: 'middle',
                        },
                        data: [{
                            label: 'Oups',
                            xAxis: this.results.statistic
                        }] as any
                    }
                },
                {
                    type: 'line',
                    data: zipSameSize(xValsAfter, yValsAfter),
                    symbol: 'none',
                    areaStyle: {},
                    lineStyle: { opacity: 0 }
                },

            ]
        };
    }
}

import {GroupScriptStep} from './group-script-step';
import {ProcessorScriptStep} from './processor-script-step';
import {ProcessorWithRecordedReport} from './../processor-with-recorded-report';

/**
 * Generated from com.dataiku.dip.shaker.model.ScriptStep
 */
export interface _ScriptStep {
    alwaysShowComment: boolean;
    comment: string;
    designTimeReport: ProcessorWithRecordedReport.ProcessorRecordedReport;
    disabled: boolean;
    mainColor: string;
    metaType: ScriptStep.MetaType;
    name: string;
    overrideTable: any /* TODO: Missing translation of com.dataiku.dip.variables.OverrideTable */;
    preview: boolean;
    secondaryColor: string;
}

export type ScriptStep = ProcessorScriptStep | GroupScriptStep;

export namespace ScriptStep {
    /**
     * Generated from com.dataiku.dip.shaker.model.ScriptStep$MetaType
     */
    export enum MetaType {
        PROCESSOR = 'PROCESSOR',
        GROUP = 'GROUP'
    }
}
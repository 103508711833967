import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { ImagePainterService } from '@shared/services/image-painter.service';
import { ImagesDataFetcherService } from '@shared/services/images-data-fetcher.service';
import { DeepHubPredictionModelDetails } from 'src/generated-sources';
import { AbstractObjectDetectionImagePainterService } from '../abstract-object-detection-image-painter.service';
import { DeephubObjectDetectionResultsImagePainterService } from './deephub-object-detection-results-image-painter.service';
import { DeephubResultsObjectDetectionDataFetcherService } from './deephub-results-object-detection-data-fetcher.service';
import { DeephubResultsObjectDetectionService } from './deephub-results-object-detection.service';

@Component({
    selector: 'dss-deephub-results-object-detection',
    templateUrl: './deephub-results-object-detection.component.html',
    styleUrls: ['./deephub-results-object-detection.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        DeephubResultsObjectDetectionDataFetcherService,
        { provide: ImagesDataFetcherService, useExisting: DeephubResultsObjectDetectionDataFetcherService },
        DeephubObjectDetectionResultsImagePainterService,
        { provide: ImagePainterService, useExisting: DeephubObjectDetectionResultsImagePainterService },
        { provide: AbstractObjectDetectionImagePainterService, useExisting: DeephubObjectDetectionResultsImagePainterService }
    ]
})
export class DeephubResultsObjectDetectionComponent {
    // replace inputs with modelData
    @Input() modelData: DeepHubPredictionModelDetails;
    @Input() iou: number;
    @Input() confidenceThreshold?: number;
    @Input() tab: string;

    constructor(
        private objectDetectionService: DeephubResultsObjectDetectionService
    ) { }

    ngOnChanges(): void {
        this.objectDetectionService.updateReport({
            coreParams: this.modelData.coreParams,
            fullModelId: this.modelData.fullModelId,
            perf: this.modelData.perf,
            categories: this.modelData.preprocessing.target_remapping.map(label => label.sourceValue),
            confidenceThreshold: this.confidenceThreshold,
            iou: this.iou
        });
    }
}

import { Inject, Injectable } from '@angular/core';
import { ImagePainterService, ImagePositionInformation } from '@shared/services/image-painter.service';
import { fairAny } from 'dku-frontend-core';
import { fabric } from "fabric";
import { IRectOptions } from 'fabric/fabric-impl';
import { DeepHubColumnFormat } from 'src/generated-sources';

@Injectable({
  providedIn: 'root'
})
export abstract class AbstractObjectDetectionImagePainterService extends ImagePainterService {
    categoryToColorMapping: Map<string, string>;
    private readonly colors: string[];

    constructor(
        @Inject('COLOR_PALETTES') COLOR_PALETTES: fairAny
    ) {
        super();
        this.colors = COLOR_PALETTES.highContrast;
    }

    setColorMapping(categories: string[]) {
        this.categoryToColorMapping = new Map();
        categories.forEach((category: string, index: number) => {
            this.categoryToColorMapping.set(category, this.colors[index % this.colors.length]);
        });
    }

    createBox(item: DeepHubColumnFormat.ObjectDetectionTargetItem, imagePosition: ImagePositionInformation, params: IRectOptions) {
        try {
            const bbox = item.bbox;
            return new fabric.Rect(Object.assign(this.getBoxParams(bbox, imagePosition), params));
        } catch(error) {
            console.warn("couldn't parse bbox for: ",item);
            return null;
        };
    }

    getBoxParams(bbox: number[], imagePosition: ImagePositionInformation): IRectOptions {
         return {
            left: imagePosition.left + bbox[0] * imagePosition.scale,
            top: imagePosition.top + bbox[1] * imagePosition.scale,
            strokeWidth: this.baseOptions.strokeWidth,
            fill: 'rgba(0,0,0,0)',
            width: bbox[2] * imagePosition.scale,
            height: bbox[3] * imagePosition.scale
        };
    }
}

import { Component, Input, Output, EventEmitter, ChangeDetectionStrategy, OnChanges, SimpleChanges } from '@angular/core';
import { GroupedCompiledCardWrapper, Card, Filter, isTZTest1SampCard, isSignTest1SampCard, isShapiroNormalityTestCard, isAbstract2SampTestCard, isOneWayANOVACard, isMoodTestNSampCard, isChi2IndTestCard } from 'src/generated-sources';
import { CardAction, CardActionType } from '@features/eda/worksheet/cards/events';
import { combineLatest, Observable, ReplaySubject, EMPTY } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { fadeInOutHeight } from '@shared/animations/fade-in-out';
import { CollapsibleGroup, CollapsingService, UpdatableCollapsingService } from '@features/eda/collapsing.service';
import { noFilterIfAll } from '@features/eda/card-utils';
import { filterName } from '@features/eda/pipes/filter-name.pipe';
import { CardBodyRenderingMode } from '@features/eda/worksheet/cards/body/rendering-mode';

@Component({
    selector: 'grouped-card-body',
    templateUrl: './grouped-card-body.component.html',
    styleUrls: [
        '../../../../shared-styles/menu-button.less',
        './grouped-card-body.component.less'
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [fadeInOutHeight]
})
export class GroupedCardBodyComponent implements OnChanges {
    @Input() results: GroupedCompiledCardWrapper.GroupedCardResult;
    @Input() params: Card;
    @Input() renderingMode: CardBodyRenderingMode;
    @Input() readOnly: boolean;
    @Input() extendedActions: boolean;
    @Input() hasFixedHeight: boolean;
    @Output() action = new EventEmitter<CardAction>();
    private params$ = new ReplaySubject<Card>(1);
    private results$ = new ReplaySubject<GroupedCompiledCardWrapper.GroupedCardResult>(1);
    groupCollapseStates$: Observable<boolean[]>;
    CardActionType = CardActionType;

    showCollapsingControls: boolean;

    constructor(private collapsingService: CollapsingService) {
        this.groupCollapseStates$ = combineLatest([this.params$, this.results$]).pipe(
            switchMap(([params, results]) => {
                if (results.type === 'groups') {
                    return combineLatest(
                        results.groups
                            .map(group => new CollapsibleGroup(params.id, group))
                            .map(collapsible => this.collapsingService.watchIsCollapsed(collapsible))
                    );
                }
                return EMPTY;
            })
        );
        this.showCollapsingControls = this.collapsingService instanceof UpdatableCollapsingService;
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.params) {
            this.params$.next(this.params);
        }
        if (changes.results) {
            this.results$.next(this.results);
        }
    }

    toggleGroup(group: Filter, newIsCollapsed: boolean) {
        this.collapsingService.setIsCollapsed(
            new CollapsibleGroup(this.params.id, group),
            newIsCollapsed
        );
    }

    trackByIndex(index: number) {
        return index;
    }

    // Export a part of this header as another header card
    // If a group index is given: create a new filtered header card
    // If no group index is given: create a new splitted header card
    exportGroup(groupIndex: number, type: CardActionType.ADD | CardActionType.DEBUG | CardActionType.PUBLISH) {
        this.action.emit({
            type, card: {
                ...this.params,
                splitBy: null,
                filter: noFilterIfAll(this.results.groups[groupIndex])
            }
        });
    }

    handleAction(action: CardAction, groupIndex: number) {
        if (action.type === CardActionType.HIGHLIGHT) {
            if (action.filter) {
                const groupFilter = {
                    ...this.results.groups[groupIndex],
                    name: this.params.splitBy!.groupingColumn.name
                        + ': ' + filterName(this.results.groups[groupIndex])
                };
                action = {
                    ...action, filter: noFilterIfAll({
                        type: 'and',
                        filters: [action.filter, groupFilter]
                    })
                };
            }
            this.action.emit(action);
        }
    }

    getAsSingleTestCard() {
        if (isTZTest1SampCard(this.params) || isSignTest1SampCard(this.params) || isShapiroNormalityTestCard(this.params) || isAbstract2SampTestCard(this.params) || isOneWayANOVACard(this.params) || isMoodTestNSampCard(this.params) || isChi2IndTestCard(this.params)) {
            return this.params;
        }

        return;
    }
}

import produce from 'immer';
import { CachedComputedCard, State, Transition, INITIAL_STATE } from './state';
import deepEqual from 'fast-deep-equal';
import { Worksheet, WorksheetRootCard, DataSpec, Card, Sample, SerializedDataset, AnyLoc, AbstractHeaderCard, GroupedCompiledCardWrapper, isAbstractHeaderCard } from 'src/generated-sources';
import { resolveSmartName } from '../utils';
import { moveItemInArray } from '@angular/cdk/drag-drop';

export function setErrorTransition(error: any): Transition {
    return state => ({ ...state, error });
}

export function worksheetInitialLoadTransition(worksheet: Worksheet): Transition {
    return () => ({
        ...INITIAL_STATE,
        worksheet,
        worksheetLoc: { projectKey: worksheet.projectKey, id: worksheet.id! }
    });
}

export function worksheetSavedTransition(fixedWorksheet: Worksheet): Transition {
    const allCardIdsInFixedWorksheet = fixedWorksheet.rootCard!.cards.map(card => card.id);

    return state => produce(state, draft => {
        for (const cardId in draft.results) {
            if (!allCardIdsInFixedWorksheet.includes(cardId)) {
                // Delete the results of a card if card isn't part of the worksheet anymore
                delete draft.results[cardId];
            }
        }

        for (const card of fixedWorksheet.rootCard!.cards) {
            const computedCard = state.results[card.id];
            if (computedCard && !deepEqual(computedCard.cardParams, card)) {
                // Delete the results of a card if they are outdated
                delete draft.results[card.id];
            }
        }

        if (draft.worksheet) {
            const previousDataSpec = draft.worksheet.dataSpec;
            const newDataSpec = fixedWorksheet.dataSpec;
            if (!deepEqual(previousDataSpec, newDataSpec)) {
                // If dataspec has changed => clear current sample & current results
                draft.sample = undefined;
                draft.results = {};
            }
        }

        draft.dirtyWorksheet = undefined;
        draft.worksheet = fixedWorksheet;
    });
}

export function resultsReceivedTransition(computedCards: CachedComputedCard[]) {
    return (state: State) => {
        // Do NOT use immer to store the card results into the state because:
        // - Results are (possibly) huge objects
        // - Immer's diffing is can be slow
        computedCards.forEach(receivedComputedCard => {
            // Ignore if current sample doesn't match
            if (!state.worksheet || !state.sample
                || state.sample.id !== receivedComputedCard.sampleId) {
                return;
            }

            // Store results if they correspond to the params of an existing top level card
            for (const topLevelCard of state.worksheet.rootCard!.cards!) {
                if (deepEqual(topLevelCard, receivedComputedCard.cardParams)) {
                    const results = {
                        ...state.results,
                        [receivedComputedCard.cardParams.id]: receivedComputedCard
                    };
                    state = { ...state, results };
                    break;
                }
            }
        });

        return state;
    };
}

export function resetFocusedCardTransition(): Transition {
    return state => produce(state, draft => {
        draft.focusedCardId = undefined;
    });
}

export function sampleLoadedTransition(sample: Sample): Transition {
    return state => produce(state, draft => {
        draft.sample = sample;

        for (const cardId in draft.results) {
            if (draft.results[cardId].sampleId !== sample.id) {
                // Delete the results of a card if sample has been changed
                delete draft.results[cardId];
            }
        }
    });
}

export function requestCardTransition(cardIds: string[]): Transition {
    return state => produce(state, draft => {
        draft.requestedCardIds = cardIds;
    });
}

export function renameWorksheetTransition(newName: string): Transition {
    return state => produce(state, draft => {
        if (draft.worksheet) {
            draft.worksheet.name = newName || 'Unnamed worksheet'; // Speculative
            draft.dirtyWorksheet = draft.worksheet;
        }
    });
}

export function editRootCardTransition(rootCard: WorksheetRootCard, immediate?: boolean): Transition {
    return state => produce(state, draft => {
        if (!draft.worksheet) {
            return;
        }
        draft.dirtyWorksheet = { ...draft.worksheet, rootCard };
        if (immediate) {
            draft.worksheet = draft.dirtyWorksheet;
        }
    });
}

export function swapCardsTransition(previousIndex: number, currentIndex: number): Transition {
    return state => produce(state, draft => {
        if (!draft.worksheet) {
            return;
        }
        // move it immediatly to keep GUI reactive even for big sheets
        moveItemInArray(draft.worksheet.rootCard.cards, previousIndex, currentIndex);
        // save the worksheet - as card ids are preserved, we should only save, not recompute
        draft.dirtyWorksheet = {...draft.worksheet};
    });
}

export function swapColumnsTransition(cardId: string, previousIndex: number, currentIndex: number): Transition {
    return state => produce(state, draft => {
        if (!draft.worksheet) {
            return;
        }
        // move it immediatly to keep GUI reactive even for big sheets
        const draftCard = draft.worksheet.rootCard.cards.find(e => e.id === cardId) as AbstractHeaderCard;
        moveItemInArray(draftCard.cards, previousIndex, currentIndex);
        moveItemInArray(draftCard.xColumns, previousIndex, currentIndex);

        const draftResults = draft.results[cardId].cardResult;
        if (AbstractHeaderCard.isAbstractHeaderCardResult(draftResults)) {
            moveItemInArray(draftResults.results, previousIndex, currentIndex);
        } else if (GroupedCompiledCardWrapper.isGroupedCardResult(draftResults)) {
            for (const c of draftResults.results) {
                if (AbstractHeaderCard.isAbstractHeaderCardResult(c)) {
                    moveItemInArray(c.results, previousIndex, currentIndex);
                }
            }
        }
        // save the worksheet - as card ids are preserved, we should only save, not recompute
        draft.dirtyWorksheet = {...draft.worksheet};
    });
}

export function addTopLevelCardTransition(card: Card): Transition {
    return addTopLevelCardsTransition([card]);
}

export function addTopLevelCardsTransition(cards: Card[]): Transition {
    return state => produce(state, draft => {
        if (!draft.worksheet || !cards.length) {
            return;
        }
        draft.dirtyWorksheet = produce(draft.worksheet, worksheetDraft => {
            cards.forEach(card => worksheetDraft.rootCard!.cards.push(card))
        });
        draft.requestedCardIds.push(cards[0].id);
        draft.focusedCardId = cards[0].id;
    });
}

export function editDataSpecTransition(dataSpec: DataSpec): Transition {
    return state => produce(state, draft => {
        if (!draft.worksheet) {
            return;
        }
        draft.dirtyWorksheet = { ...draft.worksheet, dataSpec };
        draft.dataset = undefined;
        draft.sample = undefined;
    });
}

export function requestSample(): Transition {
    return state => ({...state, sampleExternallyRequested: true });
}

export function datasetLoaded(dataset: SerializedDataset): Transition {
    return state => produce(state, draft => {
        if (!draft.worksheet) {
            return;
        }
        const receivedDatasetLoc: AnyLoc = { projectKey: dataset.projectKey, id: dataset.name };
        const expectedDatasetLoc: AnyLoc = resolveSmartName(
            draft.worksheet.projectKey,
            draft.worksheet.dataSpec.inputDatasetSmartName
        );
        if (!deepEqual(receivedDatasetLoc, expectedDatasetLoc)) {
            return;
        }
        draft.dataset = dataset;
    });
}

export function loadWorksheetTransition(projectKey: string, id: string): Transition {
    return () => ({ ...INITIAL_STATE, worksheetLoc: { projectKey, id } });
}

import { Component, EventEmitter, Output, Input, OnInit, OnChanges, SimpleChanges, ChangeDetectionStrategy, ViewChild, ElementRef, OnDestroy, Optional } from '@angular/core';
import { Card, CardResult } from 'src/generated-sources';
import { CardAction } from '@features/eda/worksheet/cards/events';
import deepEqual from 'fast-deep-equal';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';
import { BehaviorSubject, asyncScheduler } from 'rxjs';
import { combineLatestObject } from 'dku-frontend-core';
import { subscribeOn } from 'rxjs/operators';
import { isFullyComputed } from '@features/eda/card-utils';
import { WorksheetContextService } from '@features/eda/worksheet-state/worksheet.context.service';
import { CardBodyRenderingMode } from '@features/eda/worksheet/cards/body/rendering-mode';

@UntilDestroy()
@Component({
    selector: 'card-body',
    templateUrl: './card-body.component.html',
    styleUrls: [
        '../../../shared-styles/card-spinner.less',
        './card-body.component.less'
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CardBodyComponent implements OnInit, OnChanges, OnDestroy {
    readonly CardBodyRenderingMode = CardBodyRenderingMode;

    @Input() results: CardResult;
    @Input() params: Card;
    @Input() renderingMode = CardBodyRenderingMode.STAT_CARD;
    @Input() readOnly: boolean;
    @Input() cardKey: string | undefined;
    @Input() extendedActions: boolean;
    @Input() hasFixedHeight: boolean;
    @Output() action = new EventEmitter<CardAction>();

    displayedResults$ = new BehaviorSubject<CardResult | undefined>(undefined);
    displayedParams$ = new BehaviorSubject<Card | undefined>(undefined);
    displayOverlay = false;

    constructor(
        private elementRef: ElementRef<HTMLElement>,
        @Optional() private worksheetContextService: WorksheetContextService
    ) { }

    get displayedResults() {
        return this.displayedResults$.value;
    }

    get displayedParams() {
        return this.displayedParams$.value;
    }

    ngOnInit() {
        this.updateDisplayedState();

        if (this.worksheetContextService) {
            combineLatestObject({
                focusedCardId: this.worksheetContextService.getFocusedCardId(),
                displayedParams: this.displayedParams$,
                displayedResults: this.displayedResults$
            }).pipe(subscribeOn(asyncScheduler), untilDestroyed(this))
                .subscribe(({ focusedCardId, displayedParams, displayedResults }) => {
                    // Check if results are available and the current card is focused
                    if (displayedParams && displayedParams.id === focusedCardId
                        && displayedResults && isFullyComputed(displayedResults)) {
                        // Scroll to the card
                        this.elementRef.nativeElement.scrollIntoView();
                        // Clear focused card once scrolled into
                        this.worksheetContextService.resetFocusedCardTransition();
                    }
                });
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.results || changes.params) {
            this.updateDisplayedState();
        }
    }

    propagateAction(event: CardAction) {
        if (!this.displayOverlay) {
            this.action.emit(event);
        }
    }

    trackByIndex(index: number) {
        return index;
    }

    ngOnDestroy() {
    }

    updateDisplayedState() {
        if (this.results.type === 'unavailable'
            && this.results.reason === CardResult.UnavailabilityReason.NOT_COMPUTED
            && this.displayedResults
            && this.displayedResults.type !== 'unavailable') {
            // Results are not available => display an overlay on top of the previous state
            this.displayOverlay = true;
        } else {
            // Results are available => hide the overlay to show them
            this.displayOverlay = false;

            if (this.hasPossiblyChanged(this.displayedParams, this.params)) {
                // Keep propagating the old reference if the params or results have not changed
                // This avoids refreshing cards which have not been changed at all, especially
                // if they contain expensive widgets like charts
                this.displayedParams$.next(this.params);
            }
            if (this.hasPossiblyChanged(this.displayedResults, this.results)) {
                // Same
                this.displayedResults$.next(this.results);
            }
        }
    }

    hasPossiblyChanged(oldState: Card | CardResult | undefined, newState: Card | CardResult) {
        if (oldState === newState) {
            return false;
        }
        if (!oldState || oldState.type !== newState.type) {
            return true;
        }
        switch (oldState.type) {
            // Do not deep-check "container-like" cards/results because this is expensive
            // and useless: leaf cards are recursively checked anyway
            case 'bivariate_header':
            case 'univariate_header':
            case 'column_card':
            case 'groups':
                return true;
        }
        return !deepEqual(oldState, newState);
    }

    get distinctWarnings(): string[] {
        if (!this.displayedResults || !this.displayedResults.warnings) {
            return [];
        }
        return Array.from(new Set(this.displayedResults.warnings));
    }
}

import { Component, Input, EventEmitter, Output, SimpleChanges, ChangeDetectionStrategy, OnChanges, ViewContainerRef } from '@angular/core';
import { PCACard, isPCACard, Card } from 'src/generated-sources';
import { EChartsOption, ScatterSeriesOption } from 'echarts';
import _ from 'lodash';
import { CardAction, CardActionType } from '@features/eda/worksheet/cards/events';
import { ColorsService } from '@shared/graphics/colors.service';
import { HeatmapConfigComponent } from '../../config/heatmap-config/heatmap-config.component';
import { ModalShape, ModalsService } from '@shared/modals/modals.service';
import { encodeHTML } from 'entities';
import { smarterNumber } from '@shared/pipes/number-pipes/smarter-number.pipe';
import { filterName } from '@features/eda/pipes/filter-name.pipe';
import { zipSameSize } from '@features/eda/echarts-utils';

@Component({
    selector: 'pca-card-body',
    templateUrl: './pca-card-body.component.html',
    styleUrls: [
        '../../../../shared-styles/card-layout.less',
        './pca-card-body.component.less'
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PCACardBodyComponent implements OnChanges {
    @Input() params: PCACard;
    @Input() results: PCACard.PCACardResult;
    @Input() readOnly: boolean;
    @Output() action = new EventEmitter<CardAction>();

    varianceChartOptions: EChartsOption | undefined;
    scatterPlotChartOptions: EChartsOption | undefined;

    heatmapColumnLabels: string[] | undefined;
    heatmapEVLabels: string[] | undefined;
    heatmapData: number[][] | undefined;

    constructor(private colorsService: ColorsService, private modalsService: ModalsService,
                private viewContainerRef: ViewContainerRef) {
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.results) {
            this.buildVarianceChartOptions(this.results);
            this.buildScatterPlotChartOptions(this.results);
            this.buildHeatmapChartOptions(this.results);
        }
    }

    buildVarianceChartOptions(results: PCACard.PCACardResult): void {
        if (!results.explainedVarianceRatio) {
            this.varianceChartOptions = undefined;
            return;
        }
        const varianceLabels: string[] = results.explainedVarianceRatio.map((v, i) => 'PC' + (i + 1));
        const varianceRatios: number[] = results.explainedVarianceRatio;

        const cumulativeSum = (sum => (value: number) => sum += value)(0);
        const cumsumVarianceRatios = varianceRatios.map(cumulativeSum);

        this.varianceChartOptions = {
            grid:
            {
                left: '4%',
                top: '3%',
                right: '3%',
                bottom: '3%',
                containLabel: true
            },
            tooltip: {
                trigger: 'item',
                confine: true,
                axisPointer: { type: 'none' },
                formatter: (params: any) => {
                    if (params.seriesIndex === 1) {
                        return encodeHTML(`${params.value.toFixed(2)}`);
                    } else {
                        return '';
                    }
                }
            },
            xAxis: [{
                type: 'value',
                axisLabel: { color: '#999999' },
                axisTick: { show: false },
                axisLine: { show: false },
                show: false
            },
            {
                type: 'value',
                position: 'bottom',
                name: 'Explained variance ratio',
                nameLocation: 'middle',
                nameTextStyle: {
                    color: '#999999'
                },
                nameGap: 30,
                min: 0,
                max: 100,
                axisLine: { show: false },
                axisTick: { show: false },
                axisLabel: { color: '#ff0000' }
            }],
            yAxis: [{
                type: 'category',
                name: 'Eigenvalues',
                nameLocation: 'middle',
                nameTextStyle: {
                    color: '#999999'
                },
                nameGap: 40,
                data: varianceLabels.slice().reverse(),
                axisLine: { show: false },
                axisTick: { show: false },
                axisLabel: { color: '#999999' }
            }],
            series: [
                {
                    type: 'bar',
                    emphasis: { color: '#2d92cc' },
                    itemStyle: {
                        color: '#c4dffe',
                    },
                    data: results.explainedVariance.slice().reverse(),
                    tooltip: {
                        formatter: (v: { name: string, value: any }) =>
                            '<b>' + encodeHTML(v.name) + '</b><br>'
                            + 'Eigenvalue: <b>' + encodeHTML(smarterNumber(v.value)) + '</b>'
                    }
                },
                {
                    type: 'line',
                    itemStyle: {
                        color: '#cc0000',
                    },
                    tooltip: {
                        formatter: (v: { name: string, value: any }) =>
                            '<b>' + encodeHTML(v.name) + '</b><br>'
                            + 'Explained variance ratio: <b>'
                            + encodeHTML(smarterNumber(v.value) || '')
                            + '%</b> (cumulative)'
                    },
                    data: cumsumVarianceRatios.slice().reverse().map(v => v * 100.),
                    xAxisIndex: 1,
                    markLine: {
                        lineStyle: {
                            color: '#ff0000',
                            width: 1,
                            type: 'solid'
                        },
                        symbol: 'none',
                        data: [
                            {
                                name: 'Horizontal line with Y value at 0.9',
                                xAxis: 90
                            } as any,
                        ],
                        label: {
                            position: 'start'
                        }
                    }
                }
            ],
            animation: false,
        };
    }

    buildScatterPlotChartOptions(result: PCACard.PCACardResult): void {
        if (!result.projections) {
            this.scatterPlotChartOptions = undefined;
            return;
        }
        const scatterSeries = result.projections.map( (curProj, i): ScatterSeriesOption => {
            return {
                name: filterName(result.groups[i]),
                type: 'scatter',
                symbolSize: 4,
                itemStyle: {
                    opacity: 0.5,
                    color: this.colorsService.getColorFromIndex(i),
                },
                data: zipSameSize(curProj[0], curProj[1]),
                large: true
            };
        });
        this.scatterPlotChartOptions = {
            tooltip: {
                trigger: 'none',
                axisPointer: { type: 'cross' }
            },
            legend: {
                textStyle: {
                    color: '#333',
                    fontSize: 13
                },
                type: 'scroll',
                padding: [0, 0, 15, 0]
            },
            grid:
            {
                left: '0%',
                top: this.params.colorBy ? '20%' : '15%',
                right: '15%',
                bottom: '0%',
                containLabel: true
            },
            xAxis: { name: 'PC1'},
            yAxis: { name: 'PC2'},
            series: scatterSeries,
            animation: false
        };
    }

    buildHeatmapChartOptions(result: PCACard.PCACardResult): void {
        this.heatmapData = result.components.map(
            (curComponent) => curComponent.map( (cc) => cc )
        );
        this.heatmapEVLabels = result.components.map( (_, i) => 'PC' + (i+1));
        this.heatmapColumnLabels = this.params.columns.map(c => c.name);
    }

    configureVisualization() {
        if (isPCACard(this.params)) {
            this.modalsService.open(HeatmapConfigComponent, { params: this.params.heatmapParams },
                ModalShape.NARROW, this.viewContainerRef)
                .then((heatmapParams) => {
                    // these second typeguards are imposed by TS.
                    if (isPCACard(this.params)) {
                        const card: PCACard = _.cloneDeep(this.params);
                        card.heatmapParams = heatmapParams;
                        this.updateCard(card);
                    }
                }, () => { });
        }

    }

    updateCard(card: Card) {
        this.action.emit({ type: CardActionType.UPDATE, newParams: card });
    }
}

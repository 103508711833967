import _ from 'lodash';
import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges, ChangeDetectionStrategy } from '@angular/core';
import { ScatterPlotCard } from 'src/generated-sources';
import { EChartsOption, SeriesOption } from 'echarts';
import { CardAction } from '../../events';
import { getChartNumericalAxisMaxTickLength, zipSameSize } from '@features/eda/echarts-utils';
import { smarterNumber } from '@shared/pipes/number-pipes/smarter-number.pipe';
import { filterName } from '@features/eda/pipes/filter-name.pipe';
import { ColorsService } from '@shared/graphics/colors.service';

@Component({
    selector: 'scatter-plot-card-body',
    templateUrl: './scatter-plot-card-body.component.html',
    styleUrls: [
        '../../../../shared-styles/chart.less',
        './scatter-plot-card-body.component.less'
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ScatterPlotCardBodyComponent implements OnChanges {
    @Input() results: ScatterPlotCard.ScatterPlotCardResult;
    @Input() params: ScatterPlotCard;
    @Input() hasFixedHeight: boolean;
    @Output() action = new EventEmitter<CardAction>();

    chartOptions: EChartsOption;

    private readonly DEFAULT_COLOR = '#1E7EFA';

    constructor(private colorsService: ColorsService) {}

    ngOnChanges(changes: SimpleChanges) {
        if (changes.results || changes.params) {
            this.chartOptions = this.buildChartOptions();
        }
    }

    buildChartOptions(): EChartsOption {
        const { symbolSize } = this.params;
        const groupNames = this.results.groups.map(it => filterName(it));
        const isHighlightEnabled = this.results.series.some(it => it.isHighlighted);
        const hasMultipleSubgroups = this.results.groups.length > 1 || isHighlightEnabled;

        const groupColors: string[] = [];
        if (hasMultipleSubgroups) {
            groupNames.forEach(it => {
                const color = this.colorsService.getColorForVariable(it);
                groupColors.push(color);
            });
        } else {
            groupColors.push(this.DEFAULT_COLOR);
        }

        const getPointOpacity = (highlight: boolean) =>
            (highlight || !isHighlightEnabled) ? 0.6 : 0.2;

        const getLineOpacity = (highlight: boolean) =>
            (highlight || !isHighlightEnabled) ? 1 : 0.5;

        const legendItems = this.results.series.map(it => {
            let name = groupNames[it.groupIndex];
            if (it.isHighlighted) {
                name += " (selection)";
            } else if (isHighlightEnabled) {
                name += " (other)";
            }

            return {
                name,
                itemStyle: {
                    opacity: getLineOpacity(it.isHighlighted),
                },
            };
        });

        const allX = _.flatMap(this.results.series, it => it.xSeries);
        const allY = _.flatMap(this.results.series, it => it.ySeries);

        const minX = Math.floor(_.min(allX) || 0);
        const minY = Math.floor(_.min(allY) || 0);

        const maxX = Math.ceil(_.max(allX) || 0);
        const maxY = Math.ceil(_.max(allY) || 0);

        const series: SeriesOption[] = [];
        this.results.series.forEach((it, i) => {
            const points = zipSameSize(it.xSeries, it.ySeries);
            const color = groupColors[it.groupIndex];
            const opacity = getPointOpacity(it.isHighlighted);

            const scatterSeries: SeriesOption = {
                name: legendItems[i].name,
                type: 'scatter',
                data: points,
                large: true,
                silent: true,
                symbolSize,
                z: 0,
                itemStyle: {
                    color,
                    opacity,
                },
            };

            series.push(scatterSeries);

            if (it.trend != null) {
                const opacity = getLineOpacity(it.isHighlighted);
                const { coefs } = it.trend;
                const line = [
                    [minX, coefs[0] + coefs[1] * minX],
                    [maxX, coefs[0] + coefs[1] * maxX]
                ];

                const trendSeries: SeriesOption = {
                    name: legendItems[i].name,
                    data: line,
                    type: 'line',
                    symbol: 'none',
                    silent: true,
                    lineStyle: {
                        color,
                        opacity,
                    },
                };

                series.push(trendSeries);
            }
        });

        const splitXAxis = 5;
        const maxTickLength = getChartNumericalAxisMaxTickLength(splitXAxis, minX, maxX);
        const rotate = maxTickLength > 4 ? 45 : 0;

        return {
            series,
            legend: {
                show: hasMultipleSubgroups,
                type: 'scroll',
                data: legendItems,
            },
            animation: false,
            tooltip: {
                trigger: 'none',
                axisPointer: {
                    type: 'cross',
                }
            },
            grid: {
                left: 0,
                top: hasMultipleSubgroups ? 30 : 10,
                right: 0,
                bottom: 0,
                containLabel: true,
            },
            xAxis: [{
                type: 'value',
                min: minX,
                max: maxX,
                axisTick: { show: true },
                axisLine: { show: true },
                axisLabel: {
                    color: '#999999',
                    rotate,
                    formatter: (value: number) => value === minX || value === maxX ? '' : '' + value
                },
                axisPointer: {
                    label: {
                        formatter: ({ value }: { value: number }) => {
                            return this.params.xColumn.name + ': ' + smarterNumber(value);
                        }
                    }
                }
            }],
            yAxis: {
                type: 'value',
                min: minY,
                max: maxY,
                axisLine: { show: false },
                axisTick: { show: false },
                axisLabel: {
                    color: '#999999',
                    formatter: (value: number) => value === minY || value === maxY ? '' : '' + value
                },
                axisPointer: {
                    label: {
                        formatter: ({ value }: { value: number }) => {
                            return this.params.yColumn.name + ': ' + smarterNumber(value);
                        }
                    }
                }
            },
        };
    }
}

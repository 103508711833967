import {DataService} from './../server/data-service';
import {DatasetSelectionToMemTable} from './../../datasets/dataset-selection-to-mem-table';
import {ScriptStep} from './script-step';
import {StreamableDatasetSelection} from './../../datasets/streamable-dataset-selection';

/**
 * Generated from com.dataiku.dip.shaker.model.SerializedShakerScript
 */
export interface SerializedShakerScript {
    analysisColumnData: {[key: string]: SerializedShakerScript.AnalysisColumnData};
    coloring: SerializedShakerScript.TableColoring;
    columnWidthsByName: {[key: string]: number};
    columnsSelection: SerializedShakerScript.DisplayedColumnsSelection;
    contextProjectKey: string;
    explorationFilters: any[];
    explorationSampling: SerializedShakerScript.ShakerExplorationSampleSettings;
    exploreUIParams: {[key: string]: any};
    fullSampleStatistics: SerializedShakerScript.FullSampleStatisticsConfig;
    globalSearchQuery: string;
    maxProcessedMemTableBytes: number;
    origin: SerializedShakerScript.ShakerOrigin;
    previewMode: SerializedShakerScript.PreviewMode;
    recipeSchema: DataService.ShakerRecipeSchema;
    sorting: SerializedShakerScript.TableSorting[];
    steps: ScriptStep[];
    vizSampling: SerializedShakerScript.RefreshableStreamableSelection;
}

export namespace SerializedShakerScript {
    /**
     * Generated from com.dataiku.dip.shaker.model.SerializedShakerScript$DisplayedColumn
     */
    export interface DisplayedColumn {
        d: boolean;
        name: string;
    }

    /**
     * Generated from com.dataiku.dip.shaker.model.SerializedShakerScript$ValueColoringMode
     */
    export enum ValueColoringMode {
        HASH = 'HASH',
        RANK = 'RANK'
    }

    /**
     * Generated from com.dataiku.dip.shaker.model.SerializedShakerScript$TableColoringScheme
     */
    export enum TableColoringScheme {
        MEANING_AND_STATUS = 'MEANING_AND_STATUS',
        FILL_ONLY = 'FILL_ONLY',
        INDIVIDUAL_COLUMNS_VALUES = 'INDIVIDUAL_COLUMNS_VALUES',
        ALL_COLUMNS_VALUES = 'ALL_COLUMNS_VALUES',
        SINGLE_COLUMN_VALUES = 'SINGLE_COLUMN_VALUES',
        SINGLE_VALUE_HIGHLIGHT = 'SINGLE_VALUE_HIGHLIGHT'
    }

    /**
     * Generated from com.dataiku.dip.shaker.model.SerializedShakerScript$TableSorting
     */
    export interface TableSorting {
        ascending: boolean;
        column: string;
    }

    /**
     * Generated from com.dataiku.dip.shaker.model.SerializedShakerScript$ShakerExplorationSampleSettings
     */
    export interface ShakerExplorationSampleSettings {
        _refreshTrigger: number;
        autoRefreshSample: boolean;
        selection: DatasetSelectionToMemTable;
    }

    /**
     * Generated from com.dataiku.dip.shaker.model.SerializedShakerScript$TableColoring
     */
    export interface TableColoring {
        individualColumns: string[];
        scheme: SerializedShakerScript.TableColoringScheme;
        singleColumn: string;
        singleValue: string;
        valueColoringMode: SerializedShakerScript.ValueColoringMode;
    }

    /**
     * Generated from com.dataiku.dip.shaker.model.SerializedShakerScript$ShakerOrigin
     */
    export enum ShakerOrigin {
        DATASET_EXPLORE = 'DATASET_EXPLORE',
        ANALYSIS = 'ANALYSIS',
        PREPARE_RECIPE = 'PREPARE_RECIPE'
    }

    /**
     * Generated from com.dataiku.dip.shaker.model.SerializedShakerScript$FullSampleStatisticsConfig
     */
    export interface FullSampleStatisticsConfig {
        advanced: boolean;
        basic: boolean;
        computeCostFreeMetrics: boolean;
        countDistinct: boolean;
        engineConfig: any /* TODO: Missing translation of com.dataiku.dip.metrics.MetricsEngineDesc$MetricsEngineConfig */;
        numberTopValues: number;
        percentile: boolean;
        updateOnAnalyzeBoxOpen: boolean;
        validity: boolean;
    }

    /**
     * Generated from com.dataiku.dip.shaker.model.SerializedShakerScript$DisplayedColumnsMode
     */
    export enum DisplayedColumnsMode {
        ALL = 'ALL',
        SELECT = 'SELECT',
        SELECT_AND_REORDER = 'SELECT_AND_REORDER'
    }

    /**
     * Generated from com.dataiku.dip.shaker.model.SerializedShakerScript$ChartsEngine
     */
    export enum ChartsEngine {
        LINO = 'LINO',
        SQL = 'SQL',
        SPARKSQL = 'SPARKSQL'
    }

    /**
     * Generated from com.dataiku.dip.shaker.model.SerializedShakerScript$AnalysisColumnData
     */
    export interface AnalysisColumnData {
        comment: string;
        customFields: {[key: string]: any};
        meaning: string;
    }

    /**
     * Generated from com.dataiku.dip.shaker.model.SerializedShakerScript$RefreshableStreamableSelection
     */
    export interface RefreshableStreamableSelection {
        _refreshTrigger: number;
        autoRefreshSample: boolean;
        selection: StreamableDatasetSelection;
    }

    /**
     * Generated from com.dataiku.dip.shaker.model.SerializedShakerScript$DisplayedColumnsSelection
     */
    export interface DisplayedColumnsSelection {
        list: SerializedShakerScript.DisplayedColumn[];
        mode: SerializedShakerScript.DisplayedColumnsMode;
    }

    /**
     * Generated from com.dataiku.dip.shaker.model.SerializedShakerScript$PreviewMode
     */
    export enum PreviewMode {
        IMPACTED_ONLY = 'IMPACTED_ONLY',
        ALL_ROWS = 'ALL_ROWS'
    }
}
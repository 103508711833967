import { Card, CardResult, isBivariateHeaderCard, isUnivariateHeaderCard } from 'src/generated-sources';
import { ChangeDetectionStrategy, Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntilDestroy } from '@ngneat/until-destroy';
import { CardBodyRenderingMode } from '@features/eda/worksheet/cards/body/rendering-mode';

export interface CardPreviewModalComponentData {
    params: Card
    results: CardResult
    isSelected: boolean
}

export interface CardPreviewModalComponentAction {
    toggleSelection: boolean
}

@UntilDestroy()
@Component({
    selector: 'card-preview-modal',
    templateUrl: './card-preview-modal.component.html',
    styleUrls: ['./card-preview-modal.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CardPreviewModalComponent {
    readonly CardBodyRenderingMode = CardBodyRenderingMode;
    params: Card;
    results: CardResult;
    isSelected: boolean;

    constructor(
        @Inject(MAT_DIALOG_DATA) private data: CardPreviewModalComponentData,
        private dialogRef: MatDialogRef<CardPreviewModalComponent>
    ) {
        this.params = data.params;
        this.results = data.results;
        this.isSelected = data.isSelected;
    }

    get isDragScrollable(): boolean {
        return isUnivariateHeaderCard(this.params)
            || isBivariateHeaderCard(this.params);
    }

    toggleSelection() {
        const action: CardPreviewModalComponentAction = {
            toggleSelection: true,
        };

        this.dialogRef.close(action);
    }

    dismiss() {
        const action: CardPreviewModalComponentAction = {
            toggleSelection: false,
        };

        this.dialogRef.close(action);
    }
}

import { Component, ChangeDetectionStrategy, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { FeedImageModalComponent, FeedImageModalParams } from '@shared/components/feed-image-modal/feed-image-modal.component';
import { ModalShape, ModalsService } from '@shared/modals/modals.service';
import { Subject } from 'rxjs';
import { withLatestFrom } from 'rxjs/operators';
import { DeephubImagesDataService, SerializedMemTableV2 } from 'src/generated-sources';
import { DeephubResultsObjectDetectionDataFetcherService } from '../deephub-results-object-detection-data-fetcher.service';

@UntilDestroy()
@Component({
  selector: 'deephub-results-object-detection-image-feed',
  templateUrl: './deephub-results-object-detection-image-feed.component.html',
  styleUrls: ['./deephub-results-object-detection-image-feed.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DeephubResultsObjectDetectionImageFeedComponent {
    itemsPerRow = 2;
    openModal$ = new Subject<FeedImageModalParams>();
    @ViewChild('sectionTemplate') private sectionTemplate: TemplateRef<any>;

    constructor(
        public dataFetcher: DeephubResultsObjectDetectionDataFetcherService,
        public viewContainerRef: ViewContainerRef,
        public modalsService: ModalsService
    ) {
        this.openModal$.pipe(
            withLatestFrom(dataFetcher.filter$),
            untilDestroyed(this)
        ).subscribe(([{rowId, feedData}, filter]) => {
            const IGNORED_COLUMNS = [
                this.dataFetcher.getTargetColumnName(), 
                this.dataFetcher.getPredictionColumnName(), 
                this.dataFetcher.getPairingColumnName(),
                this.dataFetcher.getEnrichedFilteredColumnName(),
                this.dataFetcher.getEnrichedValidColumnName()
            ];
            const IGNORED_PREFIX = "__dku__deephub_";
            const columns = feedData.allColumnNames.map((name, index) => ({
                name,
                index
            })).filter(column => !IGNORED_COLUMNS.includes(column.name) && !column.name.startsWith(IGNORED_PREFIX));
            this.modalsService
                .open(FeedImageModalComponent, {
                    rowId,
                    columns,
                    sectionTemplate: this.sectionTemplate,
                    imageOptions: {
                        showResultIcon: true,
                        selectedStrokeWidth: 3
                    }
                 }, ModalShape.FULL, this.viewContainerRef)
                .then(() => {}, () => {});
        })
    }

    openModal({rowId, feedData}: { rowId: number, feedData: SerializedMemTableV2 }) {
        this.openModal$.next({rowId, feedData});
    }

    public get SortBy() {
        return DeephubImagesDataService.SortBy;
    }

    setSortingByConfidence(filter: DeephubImagesDataService.ObjectDetectionPredictedFilter) {
        this.dataFetcher.setFilteredPair({
            detection: filter.detection,
            groundTruth: filter.groundTruth,
            sorting: {sortBy: DeephubImagesDataService.SortBy.CONFIDENCE, ascending: filter.sorting.ascending}
        })
    }

    setSortingByIOU(filter: DeephubImagesDataService.ObjectDetectionPredictedFilter) {
        this.dataFetcher.setFilteredPair({
            detection: filter.detection,
            groundTruth: filter.groundTruth,
            sorting: {sortBy: DeephubImagesDataService.SortBy.IOU, ascending: filter.sorting.ascending}
        })
    }

    setSortingAscending(filter: DeephubImagesDataService.ObjectDetectionPredictedFilter) {
        this.dataFetcher.setFilteredPair({
            detection: filter.detection,
            groundTruth: filter.groundTruth,
            sorting: {sortBy: filter.sorting.sortBy, ascending: true}
        })
    }

    setSortingDescending(filter: DeephubImagesDataService.ObjectDetectionPredictedFilter) {
        this.dataFetcher.setFilteredPair({
            detection: filter.detection,
            groundTruth: filter.groundTruth,
            sorting: {sortBy: filter.sorting.sortBy, ascending: false}
        })
    }

}

import { Injectable } from '@angular/core';
import { StaticCanvas } from 'fabric/fabric-impl';
import { Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class FeedImageViewerService {
    private canvas$ = new Subject<StaticCanvas>();

    constructor() { }

    getCanvas() {
        return this.canvas$;
    }

    setCanvas(canvas: StaticCanvas) {
        this.canvas$.next(canvas);
    }
}

import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { WidgetsModule } from "@app/widgets/widgets.module";
import { ComponentsModule } from "@shared/components/components.module";
import { DeephubDesignImagesFeedComponent } from "./design-images-feed/deephub-design-images-feed.component";
import { BrowserModule } from "@angular/platform-browser";
import { DeephubImageDetailsComponent } from './deephub-image-details/deephub-image-details.component';
import { PipesModule } from "@shared/pipes/pipes.module";
import { DeephubResultsObjectDetectionComponent } from './deephub-results-object-detection/deephub-results-object-detection.component';
import { DeephubResultsObjectDetectionPrecisionRecallChartComponent } from './deephub-results-object-detection/precision-recall-chart/deephub-results-object-detection-precision-recall-chart.component';
import { NgxEchartsModule } from "ngx-echarts";
import { DeephubResultsObjectDetectionConfusionMatrixComponent } from './deephub-results-object-detection/confusion-matrix/deephub-results-object-detection-confusion-matrix.component';
import { DeephubResultsObjectDetectionImageFeedComponent } from './deephub-results-object-detection/image-feed/deephub-results-object-detection-image-feed.component';
import { DeephubImageMetadataComponent } from './deephub-image-metadata/deephub-image-metadata.component';
import { DeephubResultsObjectDetectionImageLegendComponent } from './deephub-results-object-detection/image-legend/deephub-results-object-detection-image-legend.component';
import { DeephubImagePairListComponent } from './deephub-results-object-detection/pair-list/deephub-image-pair-list.component';

@NgModule({
    declarations: [
        DeephubDesignImagesFeedComponent,
        DeephubImageDetailsComponent,
        DeephubResultsObjectDetectionComponent,
        DeephubResultsObjectDetectionPrecisionRecallChartComponent,
        DeephubResultsObjectDetectionConfusionMatrixComponent,
        DeephubResultsObjectDetectionImageFeedComponent,
        DeephubImageMetadataComponent,
        DeephubResultsObjectDetectionImageLegendComponent,
        DeephubImagePairListComponent
    ],
    exports: [],
    imports: [
        BrowserModule,
        FormsModule,
        ReactiveFormsModule,
        ComponentsModule,
        WidgetsModule,
        PipesModule,
        NgxEchartsModule.forRoot({
            echarts: () => import('echarts'),
        }),
    ]
})

export class DeephubModule {}
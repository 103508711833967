import { Component, Input, Output, EventEmitter, Inject } from '@angular/core';
import { IScope } from 'angular';

@Component({
    selector: 'downgraded-credentials-list',
    templateUrl: './downgraded-credentials-list.component.html',
})

/**
 * Bridge between Angular and AngularJS to use credentials lists in the later.
 * Simply provides proper inputs and outputs, adapts ControlValueAccessor structure and propagates changes.
 * 
 * @example
 * // In AngularJS code
 * <ng2-credentials-list [(items)]="items"></ng2-credentials-list>
 */
export class DowngradedCredentialsListComponent {
    @Input() items: Array<Object>;
    @Input() sortable: boolean;
    @Output() onAdd = new EventEmitter<number>();
    @Output() onDelete = new EventEmitter<number>();
    @Output() onChange = new EventEmitter<Array<Object>>();
    @Output() itemsChange = new EventEmitter<Array<Object>>();
    @Output() onValidityChange = new EventEmitter<boolean>();
    @Input() addLabel: string = 'Add Credentials';
    @Input() hasDivider: boolean = true;
    @Input() focusOnEnter: boolean = true;
    @Input() keyPlaceholder: string = 'User Name';
    @Input() valuePlaceholder: string = 'User Password';
    @Input() keyIdentifier: string = 'key';
    @Input() valueIdentifier: string = 'value';
    @Input() keyRequired: boolean = false;
    @Input() valueRequired: boolean = false;
    @Input() required: boolean = false;
    @Input() canBeFinal: boolean = true;
    @Input() warnIfTrimmable: boolean = false;

    constructor(@Inject('$rootScope') private $rootScope: IScope) { 
        this.onValidityChange.subscribe(() => $rootScope.$applyAsync());
    }

    handleChange(items: Array<Object>) {
        this.$rootScope.$applyAsync();
        this.itemsChange.emit(items);
        this.onChange.emit(items);
    }
}

import {ClusteringModelingParams} from './../clustering-modeling-params';
import {PredictionModelingParams} from './../prediction/prediction-modeling-params';

/**
 * Generated from com.dataiku.dip.analysis.model.clustering.PreTrainClusteringModelingParams
 */
export interface PreTrainClusteringModelingParams {
    affinity: string;
    algorithm: PreTrainClusteringModelingParams.Algorithm;
    coef0: number;
    custom_has_k: boolean;
    custom_model_name: string;
    epsilon: number;
    gamma: number;
    init: string;
    isolation_forest: ClusteringModelingParams.IsolationForestParams;
    k: number;
    max_iterations: number;
    metrics: ClusteringModelingParams.MetricParams;
    min_sample_ratio: number;
    mllib_custom: PredictionModelingParams.MLLibCustomGridParams;
    n_jobs: number;
    scikit_clf: string;
    seed: number;
    ts_kmeans_k: number;
}

export namespace PreTrainClusteringModelingParams {
    /**
     * Generated from com.dataiku.dip.analysis.model.clustering.PreTrainClusteringModelingParams$Algorithm
     */
    export enum Algorithm {
        KMEANS = 'KMEANS',
        MiniBatchKMeans = 'MiniBatchKMeans',
        SPECTRAL = 'SPECTRAL',
        WARD = 'WARD',
        DBSCAN = 'DBSCAN',
        GMM = 'GMM',
        SCIKIT_MODEL = 'SCIKIT_MODEL',
        PY_TWO_STEP = 'PY_TWO_STEP',
        PY_ISOLATION_FOREST = 'PY_ISOLATION_FOREST',
        MLLIB_KMEANS = 'MLLIB_KMEANS',
        MLLIB_GAUSSIAN_MIXTURE = 'MLLIB_GAUSSIAN_MIXTURE',
        MLLIB_CUSTOM = 'MLLIB_CUSTOM',
        MLLIB_TWO_STEP = 'MLLIB_TWO_STEP',
        MLLIB_ISOLATION_FOREST = 'MLLIB_ISOLATION_FOREST',
        VERTICA_KMEANS = 'VERTICA_KMEANS',
        SPARKLING_KMEANS = 'SPARKLING_KMEANS'
    }
}
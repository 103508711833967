<div class="modal modal3">
    <modal-header
        [title]="params | cardTitle: 'full'"
        [hasBorder]="false"
    ></modal-header>
    <div class="modal-body">
        <drag-scroll [dragEnabled]="isDragScrollable">
            <card-body
                class="card-preview"
                [params]="params"
                [results]="results"
                [readOnly]="true"
                [extendedActions]="false"
                [hasFixedHeight]="false"
                [renderingMode]="CardBodyRenderingMode.TOP_LEVEL_CARD"
            ></card-body>
        </drag-scroll>
    </div>
    <div class="modal-footer modal-footer-std-buttons">
        <div class="pull-right">
            <button
                type="button"
                class="btn btn--text btn--secondary"
                (click)="dismiss()"
            >
                Cancel
            </button>

            <button
                *ngIf="!isSelected"
                type="submit"
                class="btn btn--text btn--primary"
                (click)="toggleSelection()"
            >
                Select card
            </button>
        </div>
    </div>
</div>

import { Component, OnInit, ChangeDetectionStrategy, Input, Output, EventEmitter, ChangeDetectorRef, ElementRef } from '@angular/core';
import produce from 'immer';
import { CardWizardVariable } from '@features/eda/card-models';
import { Variable } from 'src/generated-sources';
import { ObservableInput } from 'observable-input';
import { Observable } from 'rxjs';
import { CdkDragDrop, CdkDragStart } from '@angular/cdk/drag-drop';

@Component({
    selector: 'list-box',
    templateUrl: './list-box.component.html',
    styleUrls: [
        '../../../shared-styles/list-box.less',
        './list-box.component.less'
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ListBoxComponent implements OnInit {
    @Input() @ObservableInput() list: Observable<CardWizardVariable[]>;
    @Input() countTitle = '';
    @Input() height?: number;
    @Input() disabledMessage = '';
    @Input() dragDropable = false;
    @Input() sortDraggable = false;
    @Output() select = new EventEmitter<CardWizardVariable[]>();
    @Output() dropped = new EventEmitter<CdkDragDrop<CardWizardVariable[]>>();

    query = '';
    Variable = Variable;
    lastSelectedItem: CardWizardVariable | null;
    currentList: CardWizardVariable[] = [];

    constructor() { }

    ngOnInit() {
        this.list.
            subscribe(
                list => {
                    if (list && list.length) {
                        this.lastSelectedItem = list[0];
                    } else {
                        this.lastSelectedItem = null;
                    }
                    this.currentList = list;
                }
            );
    }

    // whether variable is selected or not
    selectItem(event: MouseEvent, item: CardWizardVariable) {
        const previousItem = this.lastSelectedItem;
        item.selected = !item.selected;
        this.lastSelectedItem = item;

        // if shift was pressed, select all items in between previous item
        // and the item we just clicked on
        if (event.shiftKey) {
            let prevIndex = this.currentList.indexOf(previousItem!);
            prevIndex = prevIndex >= 0 ? prevIndex : 0;
            const currIndex = this.currentList.indexOf(item);
            const startIndex = prevIndex < currIndex ? prevIndex : currIndex;
            const endIndex = currIndex >= prevIndex ? currIndex : prevIndex;

            for (let i = startIndex; i <= endIndex; i++) {
                this.currentList[i].selected = item.selected; // based on select state of clicked item
            }
        }

        // emit all selected items
        this.select.emit(this.currentList.filter(v => v.selected));
    }

    drop(event: CdkDragDrop<CardWizardVariable[]>): void {
        this.dropped.emit(event);
    }

    dragStarted(ev: CdkDragStart): void {
        const selectedItems = this.currentList.filter(v => v.selected);
        const items = (selectedItems && selectedItems.length) ? selectedItems : [ev.source.data];
        ev.source.data = items;
    }

    trackByVariableName(index: number, variable: CardWizardVariable) {
        return variable.name;
    }
}

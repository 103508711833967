import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { ImageMetaData } from '@shared/components/feed-image-modal/feed-image-modal.component';

@Component({
  selector: 'deephub-image-metadata',
  templateUrl: './deephub-image-metadata.component.html',
  styleUrls: ['./deephub-image-metadata.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DeephubImageMetadataComponent implements OnInit {
    @Input() image: ImageMetaData

    constructor() { }

    ngOnInit(): void {
    }
}

<div class="modal feed-image-modal">
    <modal-header
        [title]="currentImage?.cellData?.itemPath"
    ></modal-header>
    <div class="modal-body flex modal-body--analysis no-padding horizontal-flex">
        <div class="feed-image-modal__canvas">
            <images-feed-image class="w100 h100" [cellData]="currentImage?.cellData" [showNoImageMessage]="true" (imageStatusChanged)="updateImageStatus($event)" (canvasUpdate)="onCanvasUpdate($event)" [options]="data.imageOptions"></images-feed-image>
            <ng-container *ngIf="currentImage">
                <a class="feed-image-modal__nav feed-image-modal__nav--prev" *ngIf="currentImage.cellData.rowId > 0" (click)="setImage(currentImage.cellData.rowId - 1)">
                    <i class="icon-chevron-left"></i>
                </a>
                <a class="feed-image-modal__nav feed-image-modal__nav--next" *ngIf="currentImage.cellData.rowId < currentImage.rawChunk.totalKeptRows - 1" (click)="setImage(currentImage.cellData.rowId + 1)">
                    <i class="icon-chevron-right"></i>
                </a>
            </ng-container>
        </div>
        <div class="feed-image-modal__meta">
            <ng-container *ngIf="currentImage">
                <ng-container *ngTemplateOutlet="data.sectionTemplate, context: { image: currentImage }"></ng-container>
            </ng-container>
        </div>
    </div>
</div>

import { Component, ChangeDetectionStrategy } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { FeedImageViewerService } from '@shared/components/feed-image-modal/feed-image-viewer.service';
import { Subject } from 'rxjs';
import { CanvasPairedItem, DeephubObjectDetectionResultsImagePainterService } from '../deephub-object-detection-results-image-painter.service';

@UntilDestroy()
@Component({
    selector: 'deephub-image-pair-list',
    templateUrl: './deephub-image-pair-list.component.html',
    styleUrls: [
        './deephub-image-pair-list.component.less',
        '../../shared-styles/deephub-image.less'
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DeephubImagePairListComponent {
    pairList$ = new Subject<CanvasPairedItem[]>();

    constructor(
        public imagePainter: DeephubObjectDetectionResultsImagePainterService,
        public feedImageViewerService: FeedImageViewerService
    ) { 
        this.feedImageViewerService.getCanvas().pipe(
            untilDestroyed(this)
        ).subscribe(canvas => {
            this.pairList$.next(this.imagePainter.getPairs(canvas));
        });
    }
}

import _ from 'lodash';
import { ChangeDetectionStrategy } from '@angular/core';
import { Component, Input, OnChanges, EventEmitter, Output, SimpleChanges } from '@angular/core';
import { filterName } from '@features/eda/pipes/filter-name.pipe';
import { CardAction } from '@features/eda/worksheet/cards/events';
import { ColorsService } from '@shared/graphics/colors.service';
import { ScatterPlot3DCard } from 'src/generated-sources';

@Component({
    selector: 'scatter-plot-3d-card-body',
    templateUrl: './scatter-plot-3d-card-body.component.html',
    styleUrls: ['./scatter-plot-3d-card-body.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ScatterPlot3DCardBodyComponent implements OnChanges {
    @Input() results: ScatterPlot3DCard.ScatterPlot3DCardResult;
    @Input() params: ScatterPlot3DCard;
    @Input() hasFixedHeight: boolean;
    @Output() action = new EventEmitter<CardAction>();

    chartOptions: any;  // No typings for Echarts GL

    private readonly DEFAULT_COLOR = '#1E7EFA';

    constructor(private colorsService: ColorsService) {}

    ngOnChanges(changes: SimpleChanges) {
        if (changes.results || changes.params) {
            this.chartOptions = this.buildChartOptions();
        }
    }

    private buildChartOptions() {
        const { symbolSize } = this.params;

        const groupNames = this.results.groups.map(it => filterName(it));
        const isHighlightEnabled = this.results.series3d.some(it => it.isHighlighted);

        const hasMultipleSubgroups = this.results.groups.length > 1 || isHighlightEnabled;
        const groupColors: string[] = [];
        if (hasMultipleSubgroups) {
            groupNames.forEach(it => {
                const color = this.colorsService.getColorForVariable(it);
                groupColors.push(color);
            });
        } else {
            groupColors.push(this.DEFAULT_COLOR);
        }

        const getOpacity = (highlight: boolean) =>
            (highlight || !isHighlightEnabled) ? 0.6 : 0.2;

        const legendItems = this.results.series3d.map(it => {
            let name = groupNames[it.groupIndex];
            if (it.isHighlighted) {
                name += " (selection)";
            } else if (isHighlightEnabled) {
                name += " (other)";
            }

            return {
                name,
                itemStyle: {
                    opacity: getOpacity(it.isHighlighted),
                }
            };
        });

        const series = this.results.series3d.map((it, i) => {
            const zippedSeries = _.zip(
                it.xSeries,
                it.ySeries,
                it.zSeries,
            );

            return {
                name: legendItems[i].name,
                type: 'scatter3D',
                animation: false,
                symbolSize,
                data: zippedSeries,
                itemStyle: {
                    opacity: getOpacity(it.isHighlighted),
                    color: groupColors[it.groupIndex],
                },
            };
        });

        const allX = _.flatMap(this.results.series3d, it => it.xSeries);
        const allY = _.flatMap(this.results.series3d, it => it.ySeries);
        const allZ = _.flatMap(this.results.series3d, it => it.zSeries);

        const minX = Math.floor(_.min(allX) || 0);
        const minY = Math.floor(_.min(allY) || 0);
        const minZ = Math.floor(_.min(allZ) || 0);

        const maxX = Math.ceil(_.max(allX) || 0);
        const maxY = Math.ceil(_.max(allY) || 0);
        const maxZ = Math.ceil(_.max(allZ) || 0);

        return {
            series,
            grid3D: {},
            xAxis3D: {
                name: this.params.xColumn.name,
                min: minX,
                max: maxX,
                axisLabel: {
                    formatter: (value: number) =>
                        value === minX || value === maxX ? '' : '' + value,
                },
            },
            yAxis3D: {
                name: this.params.yColumn.name,
                min: minY,
                max: maxY,
                axisLabel: {
                    formatter: (value: number) =>
                        value === minY || value === maxY ? '' : '' + value,
                },
            },
            zAxis3D: {
                name: this.params.zColumn.name,
                min: minZ,
                max: maxZ,
                axisLabel: {
                    formatter: (value: number) =>
                        value === minZ || value === maxZ ? '' : '' + value,
                },
            },
            legend: {
                show: hasMultipleSubgroups,
                type: 'scroll',
                data: legendItems,
                orient: 'vertical',
                right: 10,
                top: 'middle',
            }
        };
    }
}

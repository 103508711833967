import {ComputationResult} from './../computation-result';
import {Computation} from './../computation';
import {_AvailableResult} from './../available-result';

/**
 * Generated from com.dataiku.dip.eda.compute.computations.univariate.Entropy
 */
export interface Entropy extends Computation._UnivariateComputation {
    // PolyJson type
    type: 'entropy';
}

export function isEntropy(toTest: Computation): toTest is Entropy { return 'entropy' === toTest.type; }

export namespace Entropy {
    /**
     * Generated from com.dataiku.dip.eda.compute.computations.univariate.Entropy$EntropyResult
     */
    export interface EntropyResult extends _AvailableResult {
        value: number;

        // PolyJson type
        type: 'entropy';
    }

    export function isEntropyResult(toTest: ComputationResult): toTest is EntropyResult { return 'entropy' === toTest.type; }
}
import { Injectable } from '@angular/core';
import { CellData } from './images-data-fetcher.service';

export class ImagePositionInformation {
    constructor(
        public scale:number,
        public top: number,
        public left: number
    ) {}
}

export interface BaseImagePainterOptions {
    strokeWidth?: number
}

@Injectable()
export abstract class ImagePainterService {
    baseOptions = {
        strokeWidth: 2
    }

    abstract paintCanvas(cellData: CellData, canvas: fabric.StaticCanvas, imagePosition: ImagePositionInformation, options?: BaseImagePainterOptions): void;
}

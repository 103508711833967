import { Observable } from 'rxjs';
import { DkuHttpService } from 'dku-frontend-core';
import { Injectable } from '@angular/core';
import { SerializedMemTableV2 } from '@model-main/shaker/server/serialized-mem-table-v2';
import { SerializedTableChunk } from '@model-main/shaker/server/serialized-table-chunk';

@Injectable({ providedIn: 'root' })
export class PrivateAnalysisAPI {
    constructor(
        private dkuHttp: DkuHttpService,
    ) { }

    refreshPMLImagesDataSample(projectKey: string, analysisId: string, mlTaskId: string, filters?: any) : Observable<SerializedMemTableV2>{
        return this.dkuHttp.request('POST', '/analysis/pml/refresh-images-data-sample', { projectKey, analysisId, mlTaskId, filters: JSON.stringify(filters) });
    }

    getPMLImagesDataChunk(projectKey: string, analysisId: string, mlTaskId: string, firstRow: number, nbRows?: number, filters?: any) : Observable<SerializedTableChunk>{
        return this.dkuHttp.request('POST', '/analysis/pml/get-images-data-chunk', { projectKey, analysisId, mlTaskId, firstRow, nbRows, filters: JSON.stringify(filters) });
    }

    refreshPredictedImagesDataSample(fullModelId: string, filters?: any) : Observable<SerializedMemTableV2>{
        return this.dkuHttp.request('POST', '/analysis/predicted/refresh-images-data-sample', { fullModelId, filters: JSON.stringify(filters) });
    }

    getPredictedImagesDataChunk(fullModelId: string, firstRow: number, nbRows?: number, filters?: any) : Observable<SerializedTableChunk>{
        return this.dkuHttp.request('POST', '/analysis/predicted/get-images-data-chunk', { fullModelId, firstRow, nbRows, filters: JSON.stringify(filters) });
    }

}
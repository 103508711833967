export namespace DeephubImagesDataService {
    /**
     * Generated from com.dataiku.dip.analysis.ml.prediction.DeephubImagesDataService$Sorting
     */
    export interface Sorting {
        ascending: boolean;
        sortBy: DeephubImagesDataService.SortBy;
    }

    /**
     * Generated from com.dataiku.dip.analysis.ml.prediction.DeephubImagesDataService$SortBy
     */
    export enum SortBy {
        IOU = 'IOU',
        CONFIDENCE = 'CONFIDENCE'
    }

    /**
     * Generated from com.dataiku.dip.analysis.ml.prediction.DeephubImagesDataService$ObjectDetectionPredictedFilter
     */
    export interface ObjectDetectionPredictedFilter {
        detection?: DeephubImagesDataService.Category | null;
        groundTruth?: DeephubImagesDataService.Category | null;
        minConfidence: number;
        minIOU: number;
        sorting: DeephubImagesDataService.Sorting;
    }

    /**
     * Generated from com.dataiku.dip.analysis.ml.prediction.DeephubImagesDataService$CategoryType
     */
    export enum CategoryType {
        ANY = 'ANY',
        NONE = 'NONE',
        ONE = 'ONE'
    }

    /**
     * Generated from com.dataiku.dip.analysis.ml.prediction.DeephubImagesDataService$Category
     */
    export interface Category {
        type: DeephubImagesDataService.CategoryType;
        value: string;
    }

    /**
     * Generated from com.dataiku.dip.analysis.ml.prediction.DeephubImagesDataService$DeepHubDesignFilterRequest
     */
    export interface DeepHubDesignFilterRequest {
        targetCategories: string[];
    }
}
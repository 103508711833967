import {PredictionPreprocessingParams} from './prediction-preprocessing-params';

/**
 * Generated from com.dataiku.dip.analysis.model.preprocessing.ClassicalPredictionPreprocessingParams
 */
export interface ClassicalPredictionPreprocessingParams extends PredictionPreprocessingParams {
    feature_selection_params: ClassicalPredictionPreprocessingParams.FeatureSelectionParams;
    preprocessingFitSampleRatio: number;
    preprocessingFitSampleSeed: number;
}

export namespace ClassicalPredictionPreprocessingParams {
    /**
     * Generated from com.dataiku.dip.analysis.model.preprocessing.ClassicalPredictionPreprocessingParams$FeatureSelectionMethod
     */
    export enum FeatureSelectionMethod {
        NONE = 'NONE',
        RANDOM_FOREST = 'RANDOM_FOREST',
        LASSO = 'LASSO',
        CORRELATION = 'CORRELATION',
        PCA = 'PCA',
        CUSTOM = 'CUSTOM'
    }

    /**
     * Generated from com.dataiku.dip.analysis.model.preprocessing.ClassicalPredictionPreprocessingParams$FeatureSelectionParams
     */
    export interface FeatureSelectionParams {
        correlation_params: ClassicalPredictionPreprocessingParams.FeatureSelectionParams.CorrelationParams;
        custom_params: ClassicalPredictionPreprocessingParams.FeatureSelectionParams.CustomParams;
        lasso_params: ClassicalPredictionPreprocessingParams.FeatureSelectionParams.LassoParams;
        method: ClassicalPredictionPreprocessingParams.FeatureSelectionMethod;
        pca_params: ClassicalPredictionPreprocessingParams.FeatureSelectionParams.PCAParams;
        random_forest_params: ClassicalPredictionPreprocessingParams.FeatureSelectionParams.RandomForestParams;
    }
}

export namespace ClassicalPredictionPreprocessingParams.FeatureSelectionParams {
    /**
     * Generated from com.dataiku.dip.analysis.model.preprocessing.ClassicalPredictionPreprocessingParams$FeatureSelectionParams$LassoParams
     */
    export interface LassoParams {
        alpha: number[];
        cross_validate: boolean;
    }

    /**
     * Generated from com.dataiku.dip.analysis.model.preprocessing.ClassicalPredictionPreprocessingParams$FeatureSelectionParams$CorrelationParams
     */
    export interface CorrelationParams {
        max_abs_correlation: number;
        min_abs_correlation: number;
        n_features: number;
    }

    /**
     * Generated from com.dataiku.dip.analysis.model.preprocessing.ClassicalPredictionPreprocessingParams$FeatureSelectionParams$RandomForestParams
     */
    export interface RandomForestParams {
        depth: number;
        n_features: number;
        n_trees: number;
    }

    /**
     * Generated from com.dataiku.dip.analysis.model.preprocessing.ClassicalPredictionPreprocessingParams$FeatureSelectionParams$CustomParams
     */
    export interface CustomParams {
        code: string;
    }

    /**
     * Generated from com.dataiku.dip.analysis.model.preprocessing.ClassicalPredictionPreprocessingParams$FeatureSelectionParams$PCAParams
     */
    export interface PCAParams {
        n_features: number;
        variance_proportion: number;
    }
}
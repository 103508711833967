<mat-form-field>
    <mat-select
        [disableOptionCentering]="true"
        [placeholder]="noneSelectedText"
        [value]="selectedItem"
        (valueChange)="selectItem($event)"
    >
        <mat-option>
            <ngx-mat-select-search
                [(ngModel)]="filterQuery"
                [hideClearSearchButton]="true"
                noEntriesFoundLabel="No variables"
                placeholderLabel="Filter..."
            ></ngx-mat-select-search>
        </mat-option>

        <mat-option
            *ngFor="let item of selectableItems | filter:filterQuery:'displayName'"
            [value]="item"
            [class.text-disabled]="item.disabled"
        >
            <ng-container *ngTemplateOutlet="nameWithIcon; context: { $implicit: item } "></ng-container>
        </mat-option>
        <mat-select-trigger>
            <ng-container *ngTemplateOutlet="nameWithIcon; context: { $implicit: selectedItem } "></ng-container>
        </mat-select-trigger>
    </mat-select>
</mat-form-field>

<ng-template #nameWithIcon let-item>
    <span *ngIf="item">
        <i class="icon-font" *ngIf="item.type == Variable.Type.CATEGORICAL"></i>
        <i class="icon-number" *ngIf="item.type == Variable.Type.CONTINUOUS"></i>
        {{ item.displayName }}
    </span>
</ng-template>

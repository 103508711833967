import { Injectable } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { CellData, ImagesDataFetcherService } from '@shared/services/images-data-fetcher.service';
import { DeepHubColumnFormat, SerializedMemTableV2 } from 'src/generated-sources';

export class DeephubCellData implements CellData {
    constructor(
        public itemPath: string,
        public target: DeepHubColumnFormat.ObjectDetectionTargetItem[],
        public rowId: number
    ) {}
}

@UntilDestroy()
@Injectable({
  providedIn: 'root'
})
export abstract class AbstractDeephubDataFetcherService extends ImagesDataFetcherService {
    targetPathColIndex: number;
    projectKey: string;

    abstract getTargetColumnName(): string;

    abstract getItemPathColumnName(): string;
    
    getImagePath(itemPath: string): string {
        let contentType = 'image/jpeg';
        // keep in sync with PathStats::SUPPORTED_IMAGES
        if (itemPath.toLowerCase().endsWith('.png')) {
            contentType = 'image/png';
        }

        return `/dip/api/managedfolder/preview-image?projectKey=${encodeURIComponent(this.projectKey)}&odbId=${encodeURIComponent(this.managedFolderId)}&itemPath=${encodeURIComponent(itemPath)}&contentType=${encodeURIComponent(contentType)}`;
    }

    onRefreshSample(sample: SerializedMemTableV2): void {
        this.targetPathColIndex = sample.allColumnNames.indexOf(this.getTargetColumnName());
        this.itemPathColIndex = sample.allColumnNames.indexOf(this.getItemPathColumnName());
    }

    rowToCellData(rowContent: string[], i: number, j: number): CellData {
        return new DeephubCellData(
            rowContent[this.itemPathColIndex],
            JSON.parse(rowContent[this.targetPathColIndex]),
            i);
    }

}

import {CardResult, _CardResult} from './card-result';
import {Card} from './card';
import {Filter} from './../../compute/filtering/filter';
import {Series} from './common/series';
import {SplitBySpec} from './../models/split-by-spec';
import {_MultivariateCard} from './multivariate-card';

/**
 * Generated from com.dataiku.dip.eda.worksheets.cards.ParallelCoordinatesPlotCard
 */
export interface ParallelCoordinatesPlotCard extends _MultivariateCard {
    colorBy?: SplitBySpec | null;
    highlightFilter?: Filter | null;
    maxNumberOfPoints: number;

    // PolyJson type
    type: 'parallel_coordinates_plot';
}

export function isParallelCoordinatesPlotCard(toTest: Card): toTest is ParallelCoordinatesPlotCard { return 'parallel_coordinates_plot' === toTest.type; }

export namespace ParallelCoordinatesPlotCard {
    /**
     * Generated from com.dataiku.dip.eda.worksheets.cards.ParallelCoordinatesPlotCard$ParallelCoordinatesPlotCardResult
     */
    export interface ParallelCoordinatesPlotCardResult extends _CardResult {
        groups: Filter[];
        parallelCoordinates: ParallelCoordinatesPlotCard.ParallelCoordinates[];

        // PolyJson type
        type: 'parallel_coordinates_plot';
    }

    export function isParallelCoordinatesPlotCardResult(toTest: CardResult): toTest is ParallelCoordinatesPlotCardResult { return 'parallel_coordinates_plot' === toTest.type; }

    /**
     * Generated from com.dataiku.dip.eda.worksheets.cards.ParallelCoordinatesPlotCard$ParallelCoordinates
     */
    export interface ParallelCoordinates {
        columnSeries: Series[];
        groupIndex: number;
        isHighlighted: boolean;
    }
}
<div class="deephub-design-image-feed">

    <!-- Temporary solution to validate backend filtering works, TODO: better way of filtering/displaying selected categories -->
    <form class="deephub-design-image-feed__filter-form dkuform-horizontal" [formGroup]="form">
        <div class="control-group">
            <label class="control-label">Filter displayed classes</label>
            <div class="controls" >
                <dku-bs-select-form-control
                    formControlName="selectedCategories"
                    ngOptions="category for category in list"
                    [list]="categoriesList"
                    dataActionsBox="true"
                    dataLiveSearch="true"
                    dkuMultiple="multiple"
                    [layout]="'list'">
                </dku-bs-select-form-control>
            </div>
        </div>
    </form>

    <images-feed class="deephub-design-image-feed__feed" [imageHeight]="200" [itemsPerRow]="itemsPerRow" (imageClick)="openModal($event)"></images-feed>
</div>

<ng-template #sectionTemplate let-image="image">
    <feed-image-modal-section [sectionTitle]="'Path'">
        <div class="alert alert-error mbot8" *ngIf="!image.valid">
            <i class="icon-dku-error mright4"></i> Path not found in managed folder
        </div>
        <p class="break-all">{{ image?.cellData?.itemPath }}</p>
    </feed-image-modal-section>
    <feed-image-modal-section [sectionTitle]="'Metadata'">
        <deephub-image-metadata [image]="image"></deephub-image-metadata>
    </feed-image-modal-section>
    <deephub-image-details [categories]="image?.cellData?.target | pluck:'category'"></deephub-image-details>
</ng-template>

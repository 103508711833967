<div
    class="card-suggestion"
    [class.selected]="isSelected"
    (dblclick)="showDetails.emit($event)"
    (click)="select.emit($event)"
    #cardSuggestionEl
>    
    <div class="card-suggestion__title">
        <mat-checkbox
            [checked]="isSelected"
            [class.mat-checkbox-disabled]="false"
            disabled
        >
            <span
                class="dku-text-sb"
                [title]="proposedVisualization.name"
            >
                {{ proposedVisualization.name }}
            </span>
        </mat-checkbox>

        <i
            class="preview-icon icon-zoom-in"
            (click.stop)="showDetails.emit($event)"
        ></i>
    </div>

    <div #cardSuggestionBodyEl>
        <card-body
            [params]="proposedVisualization.miniatureCard"
            [results]="proposedVisualization.miniatureResult"
            [readOnly]="true"
            [extendedActions]="false"
            [hasFixedHeight]="false"
            class="card-suggestion__preview"
            [class.card-suggestion__preview--wide]="isWideThumbnail"
            [class.card-suggestion__preview--long]="isLongThumbnail"
        ></card-body> 
    </div>

    <div class="card-suggestion__overlay"></div>
</div>

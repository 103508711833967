<div class="h100 paned-page-section vertical-flex pad0" *ngIf="{ filter: dataFetcher.filter$ | async } as data">
    <div class="image-feed__header">
        <h2 class="paned-page-section__subheading">
            {{ dataFetcher.totalItems }} {{ 'Image' | plurify: dataFetcher.totalItems}}
        </h2>

        <div *ngIf="data?.filter?.sorting as sorting">
            Sort by <a class="image-feed__sorting-by-link" [class.image-feed__sorting-by-link--selected]="sorting.sortBy === SortBy.CONFIDENCE" (click)="sorting.sortBy !== SortBy.CONFIDENCE && setSortingByConfidence(data.filter)">Confidence</a> |
            <a class="image-feed__sorting-by-link" [class.image-feed__sorting-by-link--selected]="sorting.sortBy === SortBy.IOU" (click)="sorting.sortBy !== SortBy.IOU && setSortingByIOU(data.filter)">IOU</a>
            &nbsp; <i *ngIf="sorting.ascending" (click)='setSortingDescending(data.filter)' class="icon-sort-by-attributes image-feed__sorting-order"></i>
                    <i *ngIf="!sorting.ascending" (click)='setSortingAscending(data.filter)' class="icon-sort-by-attributes-alt image-feed__sorting-order"></i>
        </div>

    </div>
    <div class="padleftright16">
        <deephub-results-object-detection-image-legend></deephub-results-object-detection-image-legend>
    </div>
    <images-feed class="h100" [imageHeight]="200" [itemsPerRow]="itemsPerRow" (imageClick)="openModal($event)"></images-feed>
</div>

<ng-template #sectionTemplate let-image="image">
    <deephub-image-metadata class="padleftright24 mbot16 db" [image]="image"></deephub-image-metadata>
    <deephub-image-pair-list></deephub-image-pair-list>
</ng-template>

import {FloatHyperparameterDimension} from './float-hyperparameter-dimension';
import {IntegerHyperparameterDimension} from './integer-hyperparameter-dimension';

/**
 * Generated from com.dataiku.dip.analysis.model.prediction.NumericalHyperparameterDimension
 */
export interface _NumericalHyperparameterDimension {
    gridMode: NumericalHyperparameterDimension.ValueMode;
    limit: NumericalHyperparameterDimension.Limit;
    randomMode: NumericalHyperparameterDimension.ValueMode;
    range: NumericalHyperparameterDimension.Range;
    values: any /* TODO: Missing translation of T */[];
}

export type NumericalHyperparameterDimension = IntegerHyperparameterDimension | FloatHyperparameterDimension;

export namespace NumericalHyperparameterDimension {
    /**
     * Generated from com.dataiku.dip.analysis.model.prediction.NumericalHyperparameterDimension$Range
     */
    export interface Range {
        max: any /* TODO: Missing translation of T */;
        min: any /* TODO: Missing translation of T */;
        nbValues: number;
        scaling: NumericalHyperparameterDimension.Scaling;
    }

    /**
     * Generated from com.dataiku.dip.analysis.model.prediction.NumericalHyperparameterDimension$ValueMode
     */
    export enum ValueMode {
        EXPLICIT = 'EXPLICIT',
        RANGE = 'RANGE'
    }

    /**
     * Generated from com.dataiku.dip.analysis.model.prediction.NumericalHyperparameterDimension$Limit
     */
    export interface Limit {
        max: any /* TODO: Missing translation of T */;
        min: any /* TODO: Missing translation of T */;
    }

    /**
     * Generated from com.dataiku.dip.analysis.model.prediction.NumericalHyperparameterDimension$Scaling
     */
    export enum Scaling {
        LINEAR = 'LINEAR',
        LOGARITHMIC = 'LOGARITHMIC'
    }
}
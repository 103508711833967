import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { DeephubObjectDetectionResultsImagePainterService } from '../deephub-object-detection-results-image-painter.service';
import { DeephubResultsObjectDetectionDataFetcherService } from '../deephub-results-object-detection-data-fetcher.service';

@Component({
  selector: 'deephub-results-object-detection-image-legend',
  templateUrl: './deephub-results-object-detection-image-legend.component.html',
  styleUrls: [
      './deephub-results-object-detection-image-legend.component.less',
      '../../shared-styles/deephub-image.less'
    ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DeephubResultsObjectDetectionImageLegendComponent implements OnInit {
    constructor(
        public dataFetcher: DeephubResultsObjectDetectionDataFetcherService,
        public imagePainter: DeephubObjectDetectionResultsImagePainterService
    ) { }

    ngOnInit(): void {
    }
}

import { Inject, Injectable } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BaseImagePainterOptions, ImagePositionInformation } from '@shared/services/image-painter.service';
import { DeepHubColumnFormat } from 'src/generated-sources';
import { AbstractObjectDetectionImagePainterService } from '../abstract-object-detection-image-painter.service';
import { DeephubObjectDetectionResultsCellData } from './deephub-results-object-detection-data-fetcher.service';
import { DeephubResultsObjectDetectionService, ObjectDetectionReport } from './deephub-results-object-detection.service';
import { fabric } from "fabric";
import { Group, IRectOptions, Rect } from 'fabric/fabric-impl';
import { fairAny } from 'dku-frontend-core';

interface ImagePainterOptions extends BaseImagePainterOptions {
    showResultIcon: boolean,
    selectedStrokeWidth?: number
}

interface CanvasBoxOptions {
    selected?: boolean,
    hovered?: boolean,
    options: ImagePainterOptions
}

export interface CanvasPairedItem {
    boxGrouping: Group,
    groundTruth?: Rect
    detection?: Rect
}

enum BoxType {
    GROUND_TRUTH = 'groundTruth',
    DETECTION = 'detection'
}

enum EnrichedType {
    FILTERED = 'filtered',
    VALID = 'valid'
}

@UntilDestroy()
@Injectable({
  providedIn: 'root'
})
export class DeephubObjectDetectionResultsImagePainterService extends AbstractObjectDetectionImagePainterService {
    groundTruth: string | undefined;
    predicted: string | undefined;
    report: ObjectDetectionReport;

    constructor(
        private objectDetectionService: DeephubResultsObjectDetectionService,
        @Inject('COLOR_PALETTES') COLOR_PALETTES: fairAny
    ) {
        super(COLOR_PALETTES);

        this.objectDetectionService.getReport().pipe(
            untilDestroyed(this)
        ).subscribe(report => {
            this.report = report;
        });
    }

    setClasses(groundTruth: string | undefined, predicted: string | undefined) {
        this.groundTruth = groundTruth;
        this.predicted = predicted;
    }

    getPairs(canvas: fabric.StaticCanvas): CanvasPairedItem[] {
        return [...(canvas.getObjects() as Group[])]
            .map(group => this.createPairItemFromGroup(group))
            .sort((a, b) => { // sort by reading order, row by row
                const groupA = a.boxGrouping;
                const groupB = b.boxGrouping;

                return groupA.top! - groupB.top! || groupA.left! - groupB.left!;
            });
    }

    createPairItemFromGroup(boxGrouping: Group): CanvasPairedItem {
        const pairItem: CanvasPairedItem = {
            boxGrouping
        };
        
        boxGrouping.getObjects().forEach(box => {
            const boxType = box.name!;

            if (boxType === BoxType.DETECTION || boxType === BoxType.GROUND_TRUTH) {
                pairItem[boxType] = box;
            }
        });

        return pairItem;
    }

    paintCanvas(cellData: DeephubObjectDetectionResultsCellData, canvas: fabric.StaticCanvas, imagePosition: ImagePositionInformation, options?: ImagePainterOptions): void {
        this.drawBoxes(cellData, canvas, imagePosition, options);
    }

    private drawBoxesForEnriched(canvas: fabric.StaticCanvas, imagePosition: ImagePositionInformation, enriched: DeepHubColumnFormat.EnrichedObjectDetectionPairedItem[], type: string, options?: ImagePainterOptions): void {
        enriched.forEach((item: DeepHubColumnFormat.EnrichedObjectDetectionPairedItem) => {
            let pairObjects: fabric.Object[] = [];

            if (item.groundTruth !== undefined) {
                const box = this.createBox(item.groundTruth, imagePosition, {
                    stroke: this.categoryToColorMapping.get(item.groundTruth.category),
                    strokeDashArray: [3, 3],
                    name: BoxType.GROUND_TRUTH,
                    selectable: false,
                    data: item.groundTruth,
                });
                if (box !== null) {
                    pairObjects.push(box);
                }
            }

            if (item.detection !== undefined) {
                const color = this.categoryToColorMapping.get(item.detection.category);
                const box = this.createBox(item.detection, imagePosition, {
                    stroke: color,
                    name: BoxType.DETECTION,
                    selectable: false,
                    data: item.detection
                });
                if (box !== null) {
                    pairObjects.push(box);
                    
                    if (options?.showResultIcon) {
                        pairObjects = pairObjects.concat(this.createStatusIcon(item.groundTruth?.category === item.detection?.category, item.detection.bbox, imagePosition, {
                            fill: color
                        }));
                    }
                }
            }

            if (pairObjects.length) {
                const group = new fabric.Group(pairObjects, {
                    name: type,
                    selectable: false,
                    data: {
                        selected: false,
                        hovered: false,
                        options
                    } as CanvasBoxOptions
                });
                const pair = this.createPairItemFromGroup(group);

                // always show valid pairs by default
                this.setPairSelection(pair, type === EnrichedType.VALID);

                canvas.add(group);
            }
        });
    }

    createStatusIcon(isCorrect: boolean, detectionBbox: number[], imagePosition: ImagePositionInformation, options: IRectOptions) {
        const padding = {
            x: 3,
            y: 4
        };
        const fontSizeRatio = 0.9
        const SUCCESS_ICON = '\uf058';
        const FAIL_ICON = '\uf00d';
        const boxSize = Math.min(28, detectionBbox[2], detectionBbox[3]);
        const bbox = [...detectionBbox];
        bbox[2] = boxSize;
        bbox[3] = boxSize;

        const container = new fabric.Rect(Object.assign(this.getBoxParams(bbox, imagePosition), options));
        const text = new fabric.Text(isCorrect ? SUCCESS_ICON : FAIL_ICON, Object.assign(this.getBoxParams(bbox, imagePosition), {
            fill: '#222',
            fontFamily: 'FontAwesome',
            fontSize: boxSize * fontSizeRatio * imagePosition.scale,
            originX: 'center', 
            originY: 'center',
            top: imagePosition.top + imagePosition.scale * (bbox[1] + 0.5 * (padding.y + bbox[3])),
            left: imagePosition.left + imagePosition.scale * (bbox[0] + 0.5 * (padding.x + bbox[2]))
        }));

        return [container, text];
    }

    togglePairSelection(pair: CanvasPairedItem) {
        this.setPairSelection(pair, !pair.boxGrouping.data.selected);
        pair.boxGrouping.canvas?.renderAll();
    }

    setPairSelection(pair: CanvasPairedItem, selected: boolean) {
        const data = pair.boxGrouping.data;
        
        data.selected = selected;

        if (selected) {
            const strokeWidth = this.getStrokeWidth(pair);
            
            pair.boxGrouping.set({
                visible: true   
            });
            pair.groundTruth?.set({
                visible: true,
                strokeWidth
            });
            pair.detection?.set({
                strokeWidth
            });
        } else {
            this.resetPair(pair);
        }
    }

    togglePairHover(pair: CanvasPairedItem) {
        this.setPairHover(pair, !pair.boxGrouping.data.hovered);
        pair.boxGrouping.canvas?.renderAll();
    }

    setPairHover(pair: CanvasPairedItem, hovered: boolean) {
        const data = pair.boxGrouping.data;

        data.hovered = hovered;
        
        if (hovered) {
            pair.boxGrouping.set({
                visible: true   
            });
            pair.groundTruth?.set({
                visible: true
            });

            // increase stroke width even more if already selected
            if (data.selected) {
                const strokeWidth = this.getStrokeWidth(pair);

                pair.groundTruth?.set({
                    strokeWidth
                });
        
                pair.detection?.set({
                    strokeWidth
                });
            }
        } else {
            this.resetPair(pair);
        }
    }

    resetPair(pair: CanvasPairedItem) {
        const data = pair.boxGrouping.data;
        const strokeWidth = this.getStrokeWidth(pair);

        if (!data.selected && !data.hovered) {
            pair.boxGrouping.set({
                visible: false
            });
        }

        pair.groundTruth?.set({
            strokeWidth
        });

        pair.detection?.set({
            strokeWidth
        });
    }

    private getStrokeWidth(pair: CanvasPairedItem) {
        const data = pair.boxGrouping.data;
        let strokeWidth = this.baseOptions.strokeWidth;

        if (data.options?.selectedStrokeWidth) {
            if (data.selected) {
                strokeWidth = data.options?.selectedStrokeWidth + (data.hovered ? 1 : 0);
            }
        }

        return strokeWidth;
    }

    private drawBoxes(cellData: DeephubObjectDetectionResultsCellData, canvas: fabric.StaticCanvas, imagePosition: ImagePositionInformation, options?: ImagePainterOptions) {
        this.drawBoxesForEnriched(canvas, imagePosition, cellData.enrichedValid, EnrichedType.VALID, options);
        this.drawBoxesForEnriched(canvas, imagePosition, cellData.enrichedFiltered, EnrichedType.FILTERED, options);
    }

}

import {NumFeaturePreprocessingParams} from './num-feature-preprocessing-params';
import {_FeaturePreprocessingParams} from './feature-preprocessing-params';

/**
 * Generated from com.dataiku.dip.analysis.model.preprocessing.CatFeaturePreprocessingParams
 */
export interface CatFeaturePreprocessingParams extends _FeaturePreprocessingParams {
    categorical_rescaling: NumFeaturePreprocessingParams.RescalingMethod;
    category_handling: CatFeaturePreprocessingParams.CategoryHandlingMethod;
    cumulative_proportion: number;
    dummy_clip: CatFeaturePreprocessingParams.DummyClippingMethod;
    dummy_drop: CatFeaturePreprocessingParams.DummyDroppingMethod;
    frequency_default_mode: CatFeaturePreprocessingParams.FrequencyDefault;
    frequency_default_value: number;
    frequency_normalized: boolean;
    hash_whole_categories: boolean;
    impact_kfold: boolean;
    impact_kfold_k: number;
    impact_kfold_seed: number;
    impact_m: number;
    impact_method: CatFeaturePreprocessingParams.ImpactMethod;
    impute_constant_value: string;
    max_cat_safety: number;
    max_nb_categories: number;
    min_samples: number;
    missing_handling: CatFeaturePreprocessingParams.MissingHandlingMethod;
    missing_impute_with: CatFeaturePreprocessingParams.CatImputationMethod;
    nb_bins_hashing: number;
    ordinal_ascending: boolean;
    ordinal_default_mode: CatFeaturePreprocessingParams.OrdinalDefault;
    ordinal_default_value: number;
    ordinal_order: CatFeaturePreprocessingParams.OrdinalOrder;
}

export namespace CatFeaturePreprocessingParams {
    /**
     * Generated from com.dataiku.dip.analysis.model.preprocessing.CatFeaturePreprocessingParams$ImpactMethod
     */
    export enum ImpactMethod {
        M_ESTIMATOR = 'M_ESTIMATOR',
        GLMM = 'GLMM'
    }

    /**
     * Generated from com.dataiku.dip.analysis.model.preprocessing.CatFeaturePreprocessingParams$DummyDroppingMethod
     */
    export enum DummyDroppingMethod {
        NONE = 'NONE',
        DROP = 'DROP',
        AUTO = 'AUTO'
    }

    /**
     * Generated from com.dataiku.dip.analysis.model.preprocessing.CatFeaturePreprocessingParams$FrequencyDefault
     */
    export enum FrequencyDefault {
        MIN = 'MIN',
        MEDIAN = 'MEDIAN',
        MAX = 'MAX',
        EXPLICIT = 'EXPLICIT'
    }

    /**
     * Generated from com.dataiku.dip.analysis.model.preprocessing.CatFeaturePreprocessingParams$MissingHandlingMethod
     */
    export enum MissingHandlingMethod {
        NONE = 'NONE',
        IMPUTE = 'IMPUTE',
        DROP_ROW = 'DROP_ROW'
    }

    /**
     * Generated from com.dataiku.dip.analysis.model.preprocessing.CatFeaturePreprocessingParams$CategoryHandlingMethod
     */
    export enum CategoryHandlingMethod {
        FLAG_PRESENCE = 'FLAG_PRESENCE',
        IMPACT = 'IMPACT',
        DUMMIFY = 'DUMMIFY',
        HASHING = 'HASHING',
        ORDINAL = 'ORDINAL',
        FREQUENCY = 'FREQUENCY',
        CUSTOM = 'CUSTOM',
        NONE = 'NONE'
    }

    /**
     * Generated from com.dataiku.dip.analysis.model.preprocessing.CatFeaturePreprocessingParams$DummyClippingMethod
     */
    export enum DummyClippingMethod {
        MAX_NB_CATEGORIES = 'MAX_NB_CATEGORIES',
        CUMULATIVE_PROPORTION = 'CUMULATIVE_PROPORTION',
        MIN_SAMPLES = 'MIN_SAMPLES'
    }

    /**
     * Generated from com.dataiku.dip.analysis.model.preprocessing.CatFeaturePreprocessingParams$OrdinalOrder
     */
    export enum OrdinalOrder {
        COUNT = 'COUNT',
        LEXICOGRAPHIC = 'LEXICOGRAPHIC'
    }

    /**
     * Generated from com.dataiku.dip.analysis.model.preprocessing.CatFeaturePreprocessingParams$CatImputationMethod
     */
    export enum CatImputationMethod {
        MODE = 'MODE',
        CONSTANT = 'CONSTANT'
    }

    /**
     * Generated from com.dataiku.dip.analysis.model.preprocessing.CatFeaturePreprocessingParams$OrdinalDefault
     */
    export enum OrdinalDefault {
        HIGHEST = 'HIGHEST',
        MEDIAN = 'MEDIAN',
        EXPLICIT = 'EXPLICIT'
    }
}
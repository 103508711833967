<div class="editable-list__input-group">
    <mat-form-field>
        <input
            type="text"
            matInput
            [(ngModel)]="value"
            [matAutocomplete]="auto"
            class="editable-list__input"
            placeholder="{{placeholder}}"
            (focus)="handleFocus($event)"
            (blur)="handleBlur()"
            [required]="required"
            [matTooltip]="computedTooltip|async|stripHtml"
        >
        <mat-autocomplete #auto="matAutocomplete" [disableRipple]="true" [displayWith]="computeDisplay" [panelWidth]="autocompletePanelWidth">
            <mat-option *ngFor="let suggestion of filteredSuggestions | async" [value]="computeValue(suggestion)"
                (onSelectionChange)="onSelectionChange($event)" (click)="onSelectionClick($event)"
                [innerHtml]="computeDisplay(suggestion)">
            </mat-option>
        </mat-autocomplete>

    </mat-form-field>
</div>

<div class="images-feed__container" #container>
    <div class="tac" *ngIf="loadingStatus$ | async">
        <i class="dku-loader icon-spin dku-loader--inlined images-feed__loader"></i>
    </div>
    <infinite-scroll [getChunkFn]="getChunk" [itemsPerRow]="itemsPerRow" [itemHeight]="imageHeight" (loadingStatus)="loadingStatus$.next($event)">
        <ng-template let-item="item" let-index="index">
            <images-feed-image (click)="imageClick?.emit({ rowId: index, feedData: tableData })" [ngClass]="{ 'cursor-pointer': !!imageClick }" [cellData]="item" [imageHeight]="imageHeight" [rowId]="index" [imageWidth]="imageWidth" [showBackground]="true"></images-feed-image>
        </ng-template>
    </infinite-scroll>
</div>

import { Component, ChangeDetectionStrategy, Input, ElementRef, ViewChild, AfterViewInit, ChangeDetectorRef, Output, EventEmitter, OnDestroy, SimpleChanges, OnInit } from '@angular/core';
import { fabric } from 'fabric';
import { CellData, ImagesDataFetcherService } from '@shared/services/images-data-fetcher.service';
import { ImagePositionInformation, ImagePainterService, BaseImagePainterOptions } from "@shared/services/image-painter.service";
import { ICanvasDimensions, ICanvasOptions, StaticCanvas } from 'fabric/fabric-impl';
import { WindowService } from 'dku-frontend-core';
import { debounceTime } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'images-feed-image',
  templateUrl: './images-feed-image.component.html',
  styleUrls: ['./images-feed-image.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ImagesFeedImageComponent implements OnInit, OnDestroy, AfterViewInit {
    @ViewChild('canvasEl') canvasEl: ElementRef;
    @ViewChild('wrapperEl') wrapperEl: ElementRef;
    @Input() cellData: CellData;
    @Input() rowId: number; // in case cell has no data yet
    @Input() imageWidth: number;
    @Input() imageHeight: number;
    @Input() showBackground: boolean = false;
    @Input() showNoImageMessage: boolean = false;
    @Input() options: BaseImagePainterOptions;
    @Output() imageStatusChanged = new EventEmitter<boolean>();
    @Output() canvasUpdate = new EventEmitter<StaticCanvas>();

    public hasImage: boolean;
    public hasLoaded: boolean;
    public margin = 16;
    public loaderBaseHeight: number;
    private canvas: StaticCanvas;
    private image: HTMLImageElement;

    constructor(
        public dataFetcher: ImagesDataFetcherService,
        public imagePainter: ImagePainterService,
        private windowService: WindowService,
        public cd: ChangeDetectorRef
    ) {
    }

    ngOnInit() {
        if (!this.imageWidth && !this.imageHeight) {
            this.windowService.resize$.pipe(
                untilDestroyed(this),
                debounceTime(500)
            ).subscribe(_ => {
                this.drawCanvasElements();
            });
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (this.cellData) {
            // redraw canvas if image is different
            if (changes.cellData?.currentValue?.itemPath !== changes.cellData?.previousValue?.itemPath) {
                this.drawCanvas();
            } else { // if we're just resizing, don't redraw entire canvas
                this.drawCanvasElements();
            }

            this.cd.markForCheck();
        }
    }
    
    ngAfterViewInit() {
        this.loaderBaseHeight = Math.min(this.imageHeight || this.wrapperEl.nativeElement.clientHeight, 400);

        this.canvas = new fabric.StaticCanvas(this.canvasEl.nativeElement, this.getCanvasDimensions() as ICanvasOptions);
    }

    drawCanvas(): void {
        this.resetCanvas();

        const imgUrl = this.dataFetcher.getImagePath(this.cellData.itemPath);

        this.image = fabric.util.createImage();
        this.image.src = imgUrl;
        this.image.onload = this.image.onerror = this.drawCanvasElements.bind(this);
    }
    
    drawCanvasElements() {
        const oImg = new fabric.Image(this.image, {});
        this.hasLoaded = true;

        const imgOriginalWidth: number = oImg.getScaledWidth();
        const imgOriginalHeight: number = oImg.getScaledHeight();

        if (this.canvas) {
            this.clearCanvas();
            this.canvas.setDimensions(this.getCanvasDimensions());
        }
        
        if (!imgOriginalWidth || !imgOriginalHeight) {
            this.setImageStatus(false);
        } else {
            this.setImageStatus(true);

            const canvasHeight: number = this.canvas.getHeight();
            const canvasWidth: number = this.canvas.getWidth();

            const imgScaleX = Math.min(canvasWidth, imgOriginalWidth) / imgOriginalWidth;
            const imgScaleY = Math.min(canvasHeight, imgOriginalHeight) / imgOriginalHeight;

            let imgScale = 1;
            let top = (canvasHeight - imgOriginalHeight * imgScaleX) / 2;
            let left = (canvasWidth - imgOriginalWidth * imgScaleY) / 2;

            if (imgScaleX > imgScaleY) {
                imgScale = imgScaleY;
                top = 0;
            } else if (imgScaleY > imgScaleX) {
                imgScale = imgScaleX;
                left = 0;
            }

            const imgPosition = new ImagePositionInformation(imgScale, top, left);

            this.canvas.setBackgroundImage(oImg, this.canvas.renderAll.bind(this.canvas), {
                scaleX: imgPosition.scale,
                scaleY: imgPosition.scale,
                top: imgPosition.top,
                left: imgPosition.left
            });

            this.imagePainter.paintCanvas(this.cellData, this.canvas, imgPosition, this.options);
            this.canvasUpdate.emit(this.canvas);
        }

        this.cd.markForCheck();
    }

    resetCanvas() {
        this.hasImage = false;
        this.hasLoaded = false;

        if (this.canvas) {
            this.clearCanvas();
        }
    }

    setImageStatus(hasImage: boolean) {
        this.hasImage = hasImage;
        this.imageStatusChanged.emit(hasImage);
    }

    getCanvasDimensions(): ICanvasDimensions {
        return {
            width: (this.imageWidth || this.wrapperEl.nativeElement.clientWidth) - this.margin,
            height: (this.imageHeight || this.wrapperEl.nativeElement.clientHeight) - this.margin
        };
    }

    clearCanvas() {
        this.canvas.setDimensions({
            width: 0,
            height: 0
        });
        this.canvas.clear();
    }

    ngOnDestroy() {      
        if (this.image) {
            /*
                Stop trying to load the image if no longer in view by replacing src attribute
                
                this.image.src = '' is also possible but causes problems in Android browsers
                (which aren't really supported anyway)
                
                See https://stackoverflow.com/a/5278475/11907039 & 
                https://stackoverflow.com/a/14115340/11907039
            */
            this.image.src = 'data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs=';
        }
    }
}

import {CalibrationParams} from './calibration-params';
import {ClassicalPredictionPreprocessingParams} from './../preprocessing/classical-prediction-preprocessing-params';
import {DeepHubPreTrainModelingParams} from './deep-hub-pre-train-modeling-params';
import {MLAssertionsParams} from './assertions/mlassertions-params';
import {PartitionedModelParams} from './partitioned-model-params';
import {PredictionGuessPolicy} from './../../ml/prediction/guess/prediction-guess-policy';
import {PredictionModelingParams} from './prediction-modeling-params';
import {PredictionPreprocessingParams} from './../preprocessing/prediction-preprocessing-params';
import {SplitParams} from './../split-params';
import {TimeOrderingParams} from './time-ordering-params';
import {WeightParams} from './weight-params';
import {_MLTask} from './../mltask';

/**
 * Generated from com.dataiku.dip.analysis.model.prediction.PredictionMLTask
 */
export interface _PredictionMLTask extends _MLTask {
    predictionType: PredictionMLTask.PredictionType;
    splitParams: SplitParams;
    targetVariable: string;
}

export type PredictionMLTask = PredictionMLTask.ClassicalPredictionMLTask | PredictionMLTask.DeepHubPredictionMLTask;

export namespace PredictionMLTask {
    /**
     * Generated from com.dataiku.dip.analysis.model.prediction.PredictionMLTask$ClassicalPredictionMLTask
     */
    export interface ClassicalPredictionMLTask extends _PredictionMLTask {
        assertionsParams: MLAssertionsParams;
        calibration: CalibrationParams;
        guessPolicy: PredictionGuessPolicy;
        modeling: PredictionModelingParams;
        partitionedModel: PartitionedModelParams;
        preprocessing: ClassicalPredictionPreprocessingParams;
        time: TimeOrderingParams;
        weight: WeightParams;
    }

    /**
     * Generated from com.dataiku.dip.analysis.model.prediction.PredictionMLTask$PredictionType
     */
    export enum PredictionType {
        BINARY_CLASSIFICATION = 'BINARY_CLASSIFICATION',
        REGRESSION = 'REGRESSION',
        MULTICLASS = 'MULTICLASS',
        DEEP_HUB_IMAGE_OBJECT_DETECTION = 'DEEP_HUB_IMAGE_OBJECT_DETECTION'
    }

    /**
     * Generated from com.dataiku.dip.analysis.model.prediction.PredictionMLTask$DeepHubPredictionMLTask
     */
    export interface DeepHubPredictionMLTask extends _PredictionMLTask {
        managedFolderSmartId: string;
        modeling: DeepHubPreTrainModelingParams;
        pathColumn: string;
        preprocessing: PredictionPreprocessingParams;
    }
}
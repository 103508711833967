<div class="images-feed-image__wrapper" #wrapperEl [ngClass]="{ 'images-feed-image__background': showBackground, 'h100': !this.imageHeight }">
    <canvas #canvasEl class="images-feed-image__canvas db"></canvas>
    <div class="images-feed-image__no-image" *ngIf="!hasImage">
        <i class="dku-loader icon-spin dku-loader--inlined" [ngStyle]="{ 'width': loaderBaseHeight / 5 + 'px', 'height': loaderBaseHeight / 5 + 'px' }" *ngIf="!hasLoaded"></i>
        <ng-container *ngIf="hasLoaded">
            <i class="icon-picture" [ngStyle]="{ 'font-size': loaderBaseHeight / 3 + 'px' }"></i>
            <p class="images-feed-image__no-image-message" *ngIf="showNoImageMessage">
                Not Found
            </p>
        </ng-container>
    </div>
</div>

import { PipesModule } from '@shared/pipes/pipes.module';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatOptionModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { EditTypeaheadComponent } from './edit-typeahead-input.component';
import { DowngradedEditTypeaheadInputComponent } from './downgraded/downgraded-edit-typeahead-input.component';


@NgModule({
    declarations: [
        EditTypeaheadComponent,
        DowngradedEditTypeaheadInputComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MatAutocompleteModule,
        MatInputModule,
        MatFormFieldModule,
        MatOptionModule,
        MatTooltipModule,
        PipesModule
    ],
    exports: [ EditTypeaheadComponent ],
    entryComponents: [ DowngradedEditTypeaheadInputComponent ]
})
export class EditTypeAheadModule { }

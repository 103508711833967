<lazy-render
    [trackData]="options"
    [visibleTemplate]="visible"
    [passThrough]="passThrough"
    [invisibleTemplate]="invisible"
>
    <ng-template #visible>
        <div
            echarts
            (chartClick)="chartClick.emit($event)"
            (chartInit)="chartInit.emit($event)"
            *ngIf="options"
            [options]="options"
        ></div>
    </ng-template>
    <ng-template #invisible>
        <div *ngIf="!compactLoading">
            Loading chart...
        </div>
        <div *ngIf="compactLoading" class="loading-icon-wrapper">
            <i class="icon-spin icon-spinner"></i>
        </div>
    </ng-template>
</lazy-render>

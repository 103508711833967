<div class="object-detection h100">
    <ng-container [ngSwitch]="tab">
        <ng-container *ngSwitchCase="'precision-recall'">
            <div class="paned-page-section paned-page-section--no-border w100">
                <deephub-results-object-detection-precision-recall-chart class="span7 db"></deephub-results-object-detection-precision-recall-chart>
                <div class="span5 doctor-explanation">
                    <h1>Precision-Recall Curve</h1>
                    <p>Precision and recall are measures of predictive performance. Precision tells us, from all the test examples that were assigned a label, how many were actually supposed to be categorized with that label. Recall tells us, from all the test examples that should have had the label assigned, how many were actually assigned the label.</p>
                    <ul class="horizontal-flex raw-unstyled-ul mbot8 doctor-explanation__legend">
                        <li class="mright16">
                            <img src="/static/dataiku/images/models/pr-chart/file-with-pets.png" srcset="/static/dataiku/images/models/pr-chart/file-with-pets@2x.png 2x">
                            File with pets
                        </li>
                        <li class="mright16">
                            <img src="/static/dataiku/images/models/pr-chart/algo-pets.png" srcset="/static/dataiku/images/models/pr-chart/algo-pets@2x.png 2x">
                            Algorithm predicts pets
                        </li>
                        <li>
                            <img src="/static/dataiku/images/models/pr-chart/file-without-pets.png" srcset="/static/dataiku/images/models/pr-chart/file-without-pets@2x.png 2x">
                            File without pets
                        </li>
                    </ul>
                    <div class="horizontal-flex aic">
                        <img src="/static/dataiku/images/models/pr-chart/highp-lowr.png" srcset="/static/dataiku/images/models/pr-chart/highp-lowr@2x.png 2x" class="mright16 noflex">
                        <ul class="raw-unstyled-ul">
                            <li>
                                All predictions are pets
                            </li>
                            <li class="font-weight-bold">
                                High Precision
                            </li>
                            <li class="font-weight-bold">
                                Low Recall
                            </li>
                        </ul>
                    </div>
                    <div class="horizontal-flex jce aic mbot8">
                        <ul class="raw-unstyled-ul tar">
                            <li>
                                All pets are predicted
                            </li>
                            <li class="font-weight-bold">
                                Low Precision
                            </li>
                            <li class="font-weight-bold">
                                High Recall
                            </li>
                        </ul>
                        <img src="/static/dataiku/images/models/pr-chart/lowp-highr.png" srcset="/static/dataiku/images/models/pr-chart/lowp-highr@2x.png 2x" class="mleft16 noflex">
                    </div>
                    <p>Lowering the IOU threshold reduces the bar for assigning a label thereby improving the recall at the expense of precision (over assigning labels). Raising the threshold tries to ensure certainty in the labels which are assigned at the risk of missing some objects (low recall).</p>
                    <p>For a selected IOU threshold, the curve shows how precision/recall evolves across the various levels of confidence score.</p>
                </div>
            </div>
        </ng-container>
        <ng-container *ngSwitchCase="'object_detection'">
            <div class="object-detection__results oa">
                <div class="paned-page-section paned-page-section--no-border oa">
                    <deephub-results-object-detection-confusion-matrix></deephub-results-object-detection-confusion-matrix>
                </div>
            </div>
            <div class="object-detection__feed">
                <deephub-results-object-detection-image-feed></deephub-results-object-detection-image-feed>
            </div>
        </ng-container>
    </ng-container>
</div>

<div class="deephub-results-image-legend" *ngIf="{ filter: dataFetcher.filter$ | async } as data">
    <div class="deephub-results-image-legend__item">
        <div class="deephub-results-image-legend__header">Ground Truth</div>
        <div class="horizontal-flex aic" *ngIf="!data?.filter?.groundTruth || data?.filter?.groundTruth?.type !== 'ONE'">
            <div class="deephub-image-legend mright4">
                <span class="deephub-image-legend__item deephub-image-legend__item--not-selected-secondary"></span>
            </div>
            {{ data?.filter?.groundTruth?.type === 'NONE' ? "None" : "Any class"}}
        </div>
        <div class="horizontal-flex aic" *ngIf="data?.filter?.groundTruth?.value as groundTruthCategory">
            <div class="deephub-image-legend">
                <span class="deephub-image-legend__item" [style.background-image]="'repeating-linear-gradient(to right, ' + imagePainter.categoryToColorMapping.get(groundTruthCategory) + ' 0 20%, transparent 20% 25%)'"></span>
            </div>
            {{ groundTruthCategory }}
        </div>
    </div>
    <div class="deephub-results-image-legend__item">
        <div class="deephub-results-image-legend__header">Predicted</div>
        <div class="horizontal-flex aic" *ngIf="!data?.filter?.detection || data?.filter?.detection?.type !== 'ONE'">
            <div class="deephub-image-legend mright4">
                <span class="deephub-image-legend__item deephub-image-legend__item--not-selected"></span>
            </div>
            {{ data?.filter?.detection?.type === 'NONE' ? "None" : "Any class"}}
        </div>
        <div class="horizontal-flex aic" *ngIf="data?.filter?.detection?.value as detectionCategory">
            <div class="deephub-image-legend">
                <span class="deephub-image-legend__item" [style.background-color]="imagePainter.categoryToColorMapping.get(detectionCategory)"></span>
            </div>
            {{ detectionCategory }}
        </div>
    </div>
</div>
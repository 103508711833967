import { Observable, fromEvent, of, EMPTY, Subject, concat, combineLatest, defer, from, ObservableInput, OperatorFunction } from 'rxjs';
import { distinctUntilChanged, takeUntil, switchMap, delay, audit, concatMap, finalize, map, filter } from 'rxjs/operators';
import deepEqual from 'fast-deep-equal';

/**
 * Valve letting values pass through only when it is opened
 *
 * => Latest value is stored while the valve is closed and is re-emitted when the valve is opened
 *    Interpretation: render the element when it becomes visible
 *
 * => Closing the valve does not replace the output by 'null'
 *    Interpretation: keep the rendered element on the page even if it is not visible anymore
 *
 * => Emit 'null' if a new value is pushed while the 'valve' was closed
 *    Interpretation: remove element from DOM if it is not visible and is outdated
 *
 * => Emit 'null' if a resize event is received while the element was invisible
 *    Interpretation: remove element from DOM before costly redrawing
 */
export function lazyRenderingValve<InputData>(input$: Observable<InputData>, visibility$: Observable<boolean>, resize$: Observable<void>) {
    return new Observable<InputData | null>((observer) => {
        let currentVisible = false;
        let currentInput: any;
        return input$.subscribe(
            input => {
                currentInput = input;
                if (currentVisible) {
                    observer.next(currentInput);
                }
            },
            (err) => observer.error(err),
            () => observer.complete()
        ).add(visibility$.subscribe(
            (visible) => {
                const previouslyVisible = currentVisible;
                currentVisible = visible;
                if (visible && !previouslyVisible) {
                    observer.next(currentInput);
                }
            },
            (err) => observer.error(err)
        )).add(resize$.subscribe(
            () => {
                if (!currentVisible) {
                    observer.next(null);
                }
            },
            (err) => observer.error(err)
        ));
    }).pipe(distinctUntilChanged());
}

export function fromLongPressEvent(
    // Element to install event listener
    element: HTMLElement,
    // Duration of a long press
    durationMs: number,
    // Decide if the event can bubble up
    propagateEvent: (event: Event) => boolean
) {
    const onMouseDown$ = fromEvent(element, 'mousedown');
    const onMouseUp$ = fromEvent(element.ownerDocument!, 'mouseup');

    return onMouseDown$.pipe(
        switchMap((mouseDown: Event & { preventAnotherLongPress?: boolean }) => {
            if (mouseDown.preventAnotherLongPress) {
                return EMPTY;
            }
            if (!propagateEvent(mouseDown)) {
                mouseDown.preventAnotherLongPress = true;
            }
            return of(mouseDown).pipe(delay(durationMs), takeUntil(onMouseUp$));
        })
    );
}

// stolen from rxjs internals
export const isArray = (() => Array.isArray || (<T>(x: any): x is T[] => x && typeof x.length === 'number'))();

// stolen from rxjs internals
export function isNumeric(val: any): val is number | string {
    // parseFloat NaNs numeric-cast false positives (null|true|false|"")
    // ...but misinterprets leading-number strings, particularly hex literals ("0x...")
    // subtraction forces infinities to NaN
    // adding 1 corrects loss of precision from parseFloat (#15100)
    return !isArray(val) && (val - parseFloat(val) + 1) >= 0;
}

/**
 * This operator combines the behaviors of switchMap() & audit().
 * It is inspired from https://github.com/cartant/rxjs-etc/blob/master/source/operators/auditMap.ts
 *
 * Description
 * ===========
 *
 * Projects source values to an observable which is merged in the output observable.
 * Ignores source values while the current projection is active and when it completes, projects the most recent value which has been ignored (if any).
 *
 * Use case
 * ========
 *
 * Imagine:
 * - A live UI which automatically re-computes something costly (eg. a future) when the user changes a setting.
 * - We want to avoid triggering simulateous computations while ensuring the latest changes are always taken into account.
 * - We want to avoid cancelling the expensive computation at every change (cancellation can be non-trivial backend-side and/or have side effects)
 *
 * Solutions:
 *
 * 1) Using switchMap() looks like an obvious solution to this problem but it has the drawback of causing a lot of cancellations (every change will cause a cancellation)
 *
 *      $results = $paramsChanges.pipe(
 *          switchMap(params => runLongComputation(params))
 *      )
 *
 * 2) A debouncing mechanism (eg. with debounce()) is one way to partially address the problem by rate-limiting the costly backend calls. However it can't prevent
 *  extra calls since the debouncer is not aware of how much time the computation takes.
 *
 *      $results = $paramsChanges.pipe(
 *          debounceTime(1000),
 *          switchMap(params => runLongComputation(params))
 *      )
 *
 * 3) This operator provides another solution to this problem.
 *
 *      $results = $paramsChanges.pipe(
 *          auditMap(params => runLongComputation(params))
 *      )
 *
 * => Observables returned by runLongComputation() are never cancelled by a new change and never run concurrently
 * => runLongComputation() is always called on the latest available params and changes occurring while it is running are ignored/postponed
 */
export function auditMap<T, R>(
    project: (value: T, index: number) => ObservableInput<R>
): OperatorFunction<T, R> {
    return source => defer(() => {
        const auditTrigger = new Subject<void>(); // Ask 'audit' to allow another value
        let isRunning = false; // Is the inner observable running
        return source.pipe(
            audit(() => isRunning ? auditTrigger : of(true)),
            concatMap((v, i) => {
                const projected = from(project(v, i));
                return concat(
                    // Set isRunning flag before running the inner observable
                    defer(() => { isRunning = true; return EMPTY; }),
                    projected
                ).pipe(finalize(() => {
                    // Unset the isRunning flag after completion of inner observable
                    isRunning = false;
                    auditTrigger.next();
                }));
            })
        );
    });
}

// Similar to https://github.com/cartant/rxjs-etc/blob/master/source/observable/combineLatestObject.ts
export function combineLatestObject<T>(
    obj: { [K in keyof T]: ObservableInput<T[K]> }
): Observable<T> {
    return combineLatest(
        Object.entries(obj)
            .map(([key, observable]) =>
                from(observable as ObservableInput<any>)
                    .pipe(map(value => ({ [key]: value })))
            )
    ).pipe(map(array => Object.assign({}, ...array)));
}

// Similar to RxJS's distinctUntilChanged() except that values are deeply-compared
export function deepDistinctUntilChanged<T>(selector: ((v: T) => any) = (v) => v) {
    return distinctUntilChanged<T>((a, b) => deepEqual(selector(a), selector(b)));
}

export function filterNonNull<T>(): OperatorFunction<T, NonNullable<T>> {
    return filter<NonNullable<T>>(value => value !== null && value !== undefined)
}

import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit, SimpleChanges, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { CurrentRouteService } from '@core/nav/current-route.service';
import { ImagesDataFetcherService } from '@shared/services/images-data-fetcher.service';
import { ImagePainterService } from "@shared/services/image-painter.service";
import { DeephubDesignDataFetcherService } from '@features/deephub/deephub-design-data-fetcher.service';
import { DeephubObjectDetectionDesignImagePainterService } from "@features/deephub/deephub-object-detection-design-image-painter.service";
import { PredictionMLTask } from 'src/generated-sources';
import { ModalShape, ModalsService } from '@shared/modals/modals.service';
import { FeedImageModalComponent, FeedImageModalParams } from '@shared/components/feed-image-modal/feed-image-modal.component';
import { AbstractObjectDetectionImagePainterService } from '../abstract-object-detection-image-painter.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
    selector: 'deephub-design-images-feed',
    templateUrl: './deephub-design-images-feed.component.html',
    styleUrls: ['./deephub-design-images-feed.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        DeephubDesignDataFetcherService,
        { provide: ImagesDataFetcherService, useExisting: DeephubDesignDataFetcherService },
        DeephubObjectDetectionDesignImagePainterService,
        { provide: ImagePainterService, useExisting: DeephubObjectDetectionDesignImagePainterService },
        { provide: AbstractObjectDetectionImagePainterService, useExisting: DeephubObjectDetectionDesignImagePainterService }
    ]
})
export class DeephubDesignImagesFeedComponent implements OnInit, OnChanges {
    @Input() mltask: PredictionMLTask.DeepHubPredictionMLTask;
    @Input() selectedCategories?: string[];
    // Explicitly pass pathColumn and managedFolderSmartId to trigger change from Angular
    @Input() pathColumn: string;
    @Input() managedFolderSmartId: string;
    @ViewChild('sectionTemplate') private sectionTemplate: TemplateRef<any>;

    categoriesList: string[];
    itemsPerRow = 3;

    form = this.fb.group({
        selectedCategories: new FormControl(undefined, [Validators.required])
    })

    public constructor(
        private currentRoute: CurrentRouteService,
        private modalsService: ModalsService,
        private dataFetcher: DeephubDesignDataFetcherService,
        public imagePainter: DeephubObjectDetectionDesignImagePainterService,
        public viewContainerRef: ViewContainerRef,
        private fb: FormBuilder) {}

    ngOnInit(): void {
        this.categoriesList = this.mltask.preprocessing.target_remapping.map(v => v.sourceValue);
        this.imagePainter.setColorMapping(this.categoriesList);

        this.form.get('selectedCategories')!.valueChanges.pipe(
            untilDestroyed(this)
        ).subscribe(
            values => {
                this.dataFetcher.setFilteredCategories(values);
            }
        );
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.dataFetcher.setMLTaskInfo(this.currentRoute.projectKey, this.currentRoute.analysisId, this.mltask);

        if (changes.selectedCategories?.currentValue) {
            this.form.patchValue({
                selectedCategories: this.selectedCategories
            })
        }
    }

    openModal({rowId, feedData}: FeedImageModalParams) {
        const IGNORED_COLUMNS = [this.dataFetcher.getTargetColumnName(), this.dataFetcher.getItemPathColumnName()];
        const columns = feedData.allColumnNames.map((name, index) => ({
            name,
            index
        })).filter(column => !IGNORED_COLUMNS.includes(column.name));
        this.modalsService
            .open(FeedImageModalComponent, {
                rowId,
                columns,
                sectionTemplate: this.sectionTemplate,
             }, ModalShape.FULL, this.viewContainerRef)
            .then(() => {}, () => {});
    }
}

import { FutureResponse, SerializedError } from 'dku-frontend-core';
import { Dict, fairAny } from 'dku-frontend-core';
import { EnrichedPromise } from '@core/dataiku-api/utils/enriched-promise';
import { BackendResponse } from '@core/requests/dku-legacy-http.service';

// This file groups all the Providers (in particular for Services) defined in AngularJS but used in Angular
// If possible, move those providers to Angular and downgrade them to keep them accessible in AngularJS if necessary

//
// ** WARNING **
//
// Need to export factories for prod compilation (make sure ng buid --prod works if you change this)

export function stateUtilsFactory($injector: { get: Function }) {
    return $injector.get('StateUtils');
}

const stateUtilsProvider = {
    provide: 'StateUtils',
    useFactory: stateUtilsFactory,
    deps: ['$injector']
};

// -----------------

export function APIXHRServiceFactory($injector: { get: Function }) {
    return $injector.get('APIXHRService');
}

const APIXHRServiceProvider = {
    provide: 'APIXHRService',
    useFactory: APIXHRServiceFactory,
    deps: ['$injector']
};

// -----------------

export function DataikuAPIFactory($injector: { get: Function }) {
    return $injector.get('DataikuAPI');
}

const DataikuAPIProvider = {
    provide: 'DataikuAPI',
    useFactory: DataikuAPIFactory,
    deps: ['$injector']
};

// -----------------

export function GraphZoomTrackerServiceFactory($injector: { get: Function }) {
    return $injector.get('GraphZoomTrackerService');
}

const GraphZoomTrackerServiceProvider = {
    provide: 'GraphZoomTrackerService',
    useFactory: GraphZoomTrackerServiceFactory,
    deps: ['$injector']
};


// -----------------

export function DatasetUtilsFactory($injector: { get: Function }) {
    return $injector.get('DatasetUtils');
}

const DatasetUtilsProvider = {
    provide: 'DatasetUtils',
    useFactory: DatasetUtilsFactory,
    deps: ['$injector']
};

// -----------------

export function CachedAPICallsFactory($injector: { get: Function }) {
    return $injector.get('CachedAPICalls');
}

const CachedAPICallsProvider = {
    provide: 'CachedAPICalls',
    useFactory: CachedAPICallsFactory,
    deps: ['$injector']
};

// -----------------

export function FutureWatcherFactory($injector: { get: Function }) {
    return $injector.get('FutureWatcher');
}

const FutureWatcherProvider = {
    provide: 'FutureWatcher',
    useFactory: FutureWatcherFactory,
    deps: ['$injector']
};
export interface FutureWatcherType {
    watchJobId(jobId: string): EnrichedPromise<BackendResponse<FutureResponse>>;
}

// -----------------

export function FutureProgressModalFactory($injector: { get: Function }) {
    return $injector.get('FutureProgressModal');
}

const FutureProgressModalProvider = {
    provide: 'FutureProgressModal',
    useFactory: FutureProgressModalFactory,
    deps: ['$injector']
};
export interface FutureProgressModalType {
    showPeekOnlyIfRunning(parentScope: fairAny, jobId: string, modalTitle: string): Promise<BackendResponse<FutureResponse>>;
    show(parentScope: fairAny, data: fairAny, modalTitle: string): fairAny;
}

// -----------------

export function FutureWaitingFactory($injector: { get: Function }) {
    return $injector.get('futureWaiting');
}

const FutureWaitingProvider = {
    provide: 'futureWaiting',
    useFactory: FutureWaitingFactory,
    deps: ['$injector', 'DataikuAPI']
};

// -----------------

export function ProgressStackMessageBuilderFactory($injector: { get: Function }) {
    return $injector.get('ProgressStackMessageBuilder');
}

const ProgressStackMessageBuilderProvider = {
    provide: 'ProgressStackMessageBuilder',
    useFactory: ProgressStackMessageBuilderFactory,
    deps: ['$injector',]
};

// -----------------

export function DKUConstantsFactory($injector: { get: Function }) {
    return $injector.get('DKUConstants');
}

const DKUConstantsProvider = {
    provide: 'DKUConstants',
    useFactory: DKUConstantsFactory,
    deps: ['$injector']
};

// -----------------

export function LegacyDialogsFactory($injector: { get: Function }) {
    return $injector.get('Dialogs');
}

const LegacyDialogsProvider = {
    provide: 'legacyDialogs',
    useFactory: LegacyDialogsFactory,
    deps: ['$injector', 'CreateModalFromTemplate', '$state', 'DKUConstants']
};

export interface LegacyDialogs {
    displaySerializedError($scope: any, e: SerializedError): void;
}
// -----------------

export function $stateFactory($injector: { get: Function }) {
    return $injector.get('$state');
}

const $stateProvider = {
    provide: '$state',
    useFactory: $stateFactory,
    deps: ['$injector']
};
export type $stateType = { current: { name: string, url:string }, go: Function, href: Function, params: Dict<string> };


// -----------------

export function $locationFactory($injector: { get: Function }) {
    return $injector.get('$location');
}

const $locationProvider = {
    provide: '$location',
    useFactory: $locationFactory,
    deps: ['$injector']
};

// -----------------

export function StringUtilsFactory($injector: { get: Function }) {
    return $injector.get('StringUtils');
}

const StringUtilsProvider = {
    provide: 'StringUtils',
    useFactory: StringUtilsFactory,
    deps: ['$injector']
};

// -----------------

export function TopNavFactory($injector: { get: Function }) {
    return $injector.get('TopNav');
}

const TopNavProvider = {
    provide: 'TopNav',
    useFactory: TopNavFactory,
    deps: ['$injector']
};

// -----------------

export function WT1Factory($injector: { get: Function }) {
    return $injector.get('WT1');
}

const WT1Provider = {
    provide: 'WT1',
    useFactory: WT1Factory,
    deps: ['$injector']
};

// -----------------

export function $dkuSanitizeFactory($injector: { get: Function }) {
    return $injector.get('$dkuSanitize');
}

const $dkuSanitizeProvider = {
    provide: '$dkuSanitize',
    useFactory: $dkuSanitizeFactory,
    deps: ['$injector']
};

// -----------------

export function ThrottleFactory($injector: { get: Function }) {
    return $injector.get('Throttle');
}

const ThrottleProvider = {
    provide: 'Throttle',
    useFactory: ThrottleFactory,
    deps: ['$injector']
};

// -----------------

export function $rootScopeFactory($injector: { get: Function }) {
    return $injector.get('$rootScope');
}

const $rootScopeProvider = {
    provide: '$rootScope',
    useFactory: $rootScopeFactory,
    deps: ['$injector']
};

// -----------------

export function ExportUtilsFactory($injector: { get: Function }) {
    return $injector.get('ExportUtils');
}

const ExportUtilsProvider = {
    provide: 'ExportUtils',
    useFactory: ExportUtilsFactory,
    deps: ['$injector']
};

// -----------------

export function ExpressionsFactory($injector: { get: Function }) {
    return $injector.get('Expressions');
}

const ExpressionsProvider = {
    provide: 'Expressions',
    useFactory: ExpressionsFactory,
    deps: ['$injector']
};
// -----------------

export function UpgradedFiltersFactory($injector: { get: Function }) {
    return $injector.get('UpgradedFilters');
}

const UpgradedFiltersProvider = {
    provide: 'UpgradedFilters',
    useFactory: UpgradedFiltersFactory,
    deps: ['$injector']
};

// -----------------

export function localStorageServiceFactory($injector: { get: Function }) {
    return $injector.get('localStorageService');
}

const localStorageServiceProvider = {
    provide: 'localStorageService',
    useFactory: localStorageServiceFactory,
    deps: ['$injector']
};

// -----------------

export function SamplingDataFactory($injector: { get: Function }) {
    return $injector.get('SamplingData');
}

const SamplingDataProvider = {
    provide: 'SamplingData',
    useFactory: SamplingDataFactory,
    deps: ['$injector']
};

// -----------------

export function LoggerFactory($injector: { get: Function }) {
    return $injector.get('Logger');
}

const LoggerProvider = {
    provide: 'Logger',
    useFactory: LoggerFactory,
    deps: ['$injector']
};

// -----------------

export function CreateModalFromTemplateFactory($injector: { get: Function }) {
    return $injector.get('CreateModalFromTemplate');
}

const CreateModalFromTemplateProvider = {
    provide: 'CreateModalFromTemplate',
    useFactory: CreateModalFromTemplateFactory,
    deps: ['$injector']
};

// -----------------

export function SpinnerServiceFactory($injector: { get: Function }) {
    return $injector.get('SpinnerService');
}

const SpinnerServiceProvider = {
    provide: 'SpinnerService',
    useFactory: SpinnerServiceFactory,
    deps: ['$injector']
};

// -----------------

export function ColorPaletteFactory($injector: { get: Function }) {
    return $injector.get('COLOR_PALETTES');
}

const ColorPaletteFactoryProvider = {
    provide: 'COLOR_PALETTES',
    useFactory: ColorPaletteFactory,
    deps: ['$injector']
};

// -----------------

export function upgradedProviders() {
    return [
        // Long term imports, to be removed when we totally remove AngularJS
        $stateProvider,
        $locationProvider,

        // Services kept here until they are moved to Angular
        stateUtilsProvider,
        APIXHRServiceProvider,
        DataikuAPIProvider,
        DatasetUtilsProvider,
        TopNavProvider,
        WT1Provider,
        FutureWatcherProvider,
        FutureProgressModalProvider,
        FutureWaitingProvider,
        ProgressStackMessageBuilderProvider,
        ThrottleProvider,
        $rootScopeProvider,
        CachedAPICallsProvider,
        $dkuSanitizeProvider,
        ExportUtilsProvider,
        UpgradedFiltersProvider,
        localStorageServiceProvider,
        GraphZoomTrackerServiceProvider,
        LoggerProvider,
        CreateModalFromTemplateProvider,
        SpinnerServiceProvider,
        DKUConstantsProvider,
        LegacyDialogsProvider,
        StringUtilsProvider,
        ExpressionsProvider,
        SamplingDataProvider,
        ColorPaletteFactoryProvider
    ];
}

/*
    Service that manages the state of the new/edit analysis window
*/
import { Injectable } from '@angular/core';
import { Card, Variable, CorrelationMatrixCard, PValueAdjustmentMethod } from 'src/generated-sources';
import { map } from 'rxjs/operators';
import { AnalysisType } from './enums';
import { randomId } from '@utils/random';
import { CardWizardVariable } from '@features/eda/card-models';
import { SampleContextService } from '@features/eda/sample-context.service';

interface AvailableVariableOptions {
    isTestVariable?: boolean;
}

@Injectable()
export class CardWizardService {
    constructor(
        private sampleContextService: SampleContextService
    ) {}

    private mapVariables(variables: Variable[], type: string, options?: AvailableVariableOptions): CardWizardVariable[] {
        return variables.map((variable: Variable) => ({
            ...variable,
            disabled: this.isVariableDisabled(variable, type, options)
        })).sort((a, b) => (+a.disabled - +b.disabled));
    }

    availableVariables(type: string, options?: AvailableVariableOptions) {
        return this.sampleContextService.availableVariables().pipe(
            map(variables => this.mapVariables(variables, type, options))
        );
    }

    private isVariableDisabled(variable: Variable, type: string, options?: AvailableVariableOptions) {
        const analysisType = this.getAnalysisType(type);

        switch (analysisType) {
            case AnalysisType.UNIVARIATE_CARD:
            case AnalysisType.MULTIVARIATE_CARD:
                return variable.type !== Variable.Type.CONTINUOUS;
            case AnalysisType.BIVARIATE_CARD:
                if (type === 'chi2_independence_test') {
                    return variable.type !== Variable.Type.CATEGORICAL;
                } else if (type === 'fit_2d_distribution' || type === 'fit_curve') {
                    return variable.type !== Variable.Type.CONTINUOUS;
                } else {
                    if (options && options.isTestVariable) {
                        return variable.type !== Variable.Type.CONTINUOUS;
                    }
                }
                break;
            case AnalysisType.TRIVARIATE_CARD:
                if (type === 'scatter_plot_3d') {
                    return variable.type !== Variable.Type.CONTINUOUS;
                }
                break;
        }

        return false;
    }

    getAnalysisType(type: string) {
        switch (type) {
            case 'univariate_header':
                return AnalysisType.UNIVARIATE_HEADER;
            case 'bivariate_header':
                return AnalysisType.BIVARIATE_HEADER;
            case 'scatter_plot_3d':
                return AnalysisType.TRIVARIATE_CARD;
            case 'parallel_coordinates_plot':
            case 'pca':
            case 'correlation_matrix':
                return AnalysisType.MULTIVARIATE_CARD;
            case 'fit_2d_distribution':
            case 'fit_curve':
            case 'mood_test_2samp':
            case 'mood_nsamp':
            case 'ks_test_2samp':
            case 'ttest_2samp':
            case 'oneway_anova':
            case 'chi2_independence_test':
            case 'pairwise_mood':
            case 'pairwise_ttest':
                return AnalysisType.BIVARIATE_CARD;
            case 'fit_distribution':
            case 'shapiro':
            case 'sign_test_1samp':
            case 'ttest_ztest_1samp':
                return AnalysisType.UNIVARIATE_CARD;
            default:
                return '';
        }
    }

    getCardDefault(type: string) {
        const analysisType = this.getAnalysisType(type);
        const analysisDefault = this.getAnalysisDefault(analysisType);
        let card = {
            type,
            ...analysisDefault,
            id: randomId()
        };
        let defaultOptions = {};

        switch (type) {
            case 'univariate_header':
                defaultOptions = {
                    showBoxPlot: true,
                    showFrequencyTable: true,
                    showHistogram: true,
                    showQuantile: true,
                    showSummary: true,
                    showCumulativeDistributionFunction: false,
                };
                break;
            case 'bivariate_header':
                defaultOptions = {
                    showBoxPlot: true,
                    showFrequencyTable: true,
                    showHistogram: true,
                    showMosaicPlot: true,
                    showScatterPlot: true,
                    showSummary: true
                };
                break;
            case 'correlation_matrix':
                defaultOptions = {
                    metric: CorrelationMatrixCard.CorrelationMetric.SPEARMAN,
                    heatmapParams: {
                        showValues: true,
                        showColors: true,
                        showAbsValues: false,
                        threshold: 0
                    }
                };
                break;
            case 'fit_distribution':
                defaultOptions = {
                    distributions: [{
                        distribution: {
                            type: 'normal'
                        }
                    }]
                };
                break;
            case 'fit_2d_distribution':
                defaultOptions = {
                    distribution: {
                        type: 'kde_2d'
                    },
                    xResolution: 640,
                    yResolution: 480
                };
                break;
            case 'fit_curve':
                // look for option with id named 'type'
                defaultOptions = {
                    curves: [{
                        type: 'polynomial',
                        degree: 2
                    }]
                };
                break;
            case 'pca':
                defaultOptions = {
                    heatmapParams: {
                        showValues: true,
                        showColors: true,
                        showAbsValues: false,
                        threshold: 0
                    }
                };
                break;
            case 'scatter_plot_3d':
                defaultOptions = {
                    symbolSize: 4,
                    maxNumberOfPoints: 100_000,
                };
                break;
            case 'pairwise_mood':
            case 'pairwise_ttest':
                defaultOptions = {
                    adjustmentMethod: PValueAdjustmentMethod.NONE,
                    maxGroups: 10
                };
                break;
            case 'mood_nsamp':
            case 'oneway_anova':
                defaultOptions = {
                    maxGroups: 10
                };
                break;
            case 'chi2_independence_test':
                defaultOptions = {
                    maxValuesX: 5,
                    maxValuesY: 5
                };
                break;
            case 'parallel_coordinates_plot':
                defaultOptions = {
                    maxNumberOfPoints: 10_000,
                };
                break;
            case 'ks_test_2samp':
            case 'mood_test_2samp':
            case 'shapiro':
            case 'sign_test_1samp':
            case 'ttest_2samp':
            case 'ttest_ztest_1samp':
                break;
        }

        card = {
            ...card,
            ...defaultOptions
        };

        return card;
    }

    getAnalysisDefault(analysisType: string): Partial<Card> {
        switch (analysisType) {
            case AnalysisType.UNIVARIATE_HEADER:
            case AnalysisType.BIVARIATE_HEADER:
                return {
                    xColumns: [],
                };
            case AnalysisType.MULTIVARIATE_CARD:
                return {
                    columns: []
                };
        }

        return {};
    }
}

/**
 * Generated from com.dataiku.dip.analysis.model.prediction.DeepHubMetricParams
 */
export interface DeepHubMetricParams {
    confidenceScoreThresholdOptimMetric: DeepHubMetricParams.ConfidenceScoreThresholdOptimizationMetric;
    evaluationMetric: DeepHubMetricParams.EvaluationMetric;
}

export namespace DeepHubMetricParams {
    /**
     * Generated from com.dataiku.dip.analysis.model.prediction.DeepHubMetricParams$EvaluationMetric
     */
    export enum EvaluationMetric {
        AVERAGE_PRECISION_IOU50 = 'AVERAGE_PRECISION_IOU50',
        AVERAGE_PRECISION_IOU75 = 'AVERAGE_PRECISION_IOU75',
        AVERAGE_PRECISION_ALL_IOU = 'AVERAGE_PRECISION_ALL_IOU'
    }

    /**
     * Generated from com.dataiku.dip.analysis.model.prediction.DeepHubMetricParams$ConfidenceScoreThresholdOptimizationMetric
     */
    export enum ConfidenceScoreThresholdOptimizationMetric {
        F1 = 'F1',
        PRECISION = 'PRECISION',
        RECALL = 'RECALL'
    }
}
<div class="card-layout">
    <div class="card-layout__section card-layout__section--horizontal">
        <div class="card-layout__section">
            <div class="card-layout__title">Empirical CDFs</div>
            <div class="card-layout__content">
                <lazy-echart [options]="cdfChartOptions" class="cdf"></lazy-echart>
            </div>
        </div>
        <div class="card-layout__section card-layout__section--vertical">
            <div class="card-layout__section">
                <div class="card-layout__title">Samples</div>
                <div class="card-layout__content">
                    <table class="stats-table">
                        <tbody class="stats-table__body">
                            <tr class="stats-table__row--colored">
                                <th
                                    class="stats-table__head"
                                    colspan="2"
                                ></th>
                                <th
                                    class="stats-table__title"
                                    colspan="2"
                                >
                                    {{ params.xColumn.name }}
                                </th>
                            </tr>
                            <tr class="stats-table__row--colored">
                                <th class="stats-table__title">{{ params.yColumn.name }}</th>
                                <th class="stats-table__title">Count</th>
                                <th class="stats-table__title">Mean</th>
                                <th class="stats-table__title">Std. Dev.</th>
                            </tr>
                            <tr class="stats-table__row--invisible"></tr>
                            <tr
                                class="stats-table__row population"
                                *ngFor="let group of results.groups"
                            >
                                <th class="stats-table__head">
                                    <span [ngStyle]="{color: colorsService.getColorForVariable(group.filter | filterName), 'font-size':'30px'}">&bull;</span>
                                    {{ group.filter | filterName }}
                                </th>
                                <td class="stats-table__cell stats-table__cell--left">{{ group.boxPlot.countComplete }}</td>
                                <td class="stats-table__cell stats-table__cell--left">{{ group.boxPlot.mean | smarterNumber  }}</td>
                                <td class="stats-table__cell stats-table__cell--left">{{ group.boxPlot.stdDev | smarterNumber }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            <div class="card-layout__section">
                <div class="card-layout__title">Hypothesis</div>
                <div class="card-layout__content">

                    <table class="stats-table">
                        <tbody class="stats-table__body">
                            <tr class="stats-table__row">
                                <th class="stats-table__head">Tested hypothesis</th>
                                <td class="stats-table__cell">
                                    <u>{{ params.xColumn.name }}</u>
                                    distribution is the same in the two populations
                                </td>
                            </tr>
                            <tr class="stats-table__row">
                                <th class="stats-table__head">Significance level</th>
                                <td class="stats-table__cell">
                                    {{ 1 - params.confidenceLevel | smarterNumber }}
                                </td>
                            </tr>
                        </tbody>
                    </table>

                </div>
            </div>

            <div class="card-layout__section">
                <div class="card-layout__title">Results</div>
                <div class="card-layout__content">
                    <table class="stats-table">

                        <tbody class="stats-table__body">
                            <tr class="stats-table__row">
                                <th class="stats-table__head">Kolmogorov–Smirnov test statistic</th>
                                <td class="stats-table__cell">{{ results.statistic | smarterNumber }}</td>
                            </tr>
                            <tr class="stats-table__row">
                                <th class="stats-table__head">𝘱-value</th>
                                <td class="stats-table__cell">
                                    <colored-pvalue
                                        [pvalue]="results.pvalue"
                                        [significanceLevel]="1 - params.confidenceLevel"
                                    ></colored-pvalue>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="test-conclusion">
    <ng-container *ngIf="isRejected else inconclusive">
        <p class="test-conclusion__wording test-conclusion__wording--rejected">
            <u>{{ params.xColumn.name }}</u>
            distribution is different in the two populations
        </p>
    </ng-container>

    <ng-template #inconclusive>
        <p class="test-conclusion__wording">
            The test is inconclusive about whether
            <u>{{ params.xColumn.name }}</u>
            distribution is different in the two populations
        </p>
    </ng-template>
</div>

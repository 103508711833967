<div class="matrix mx-center-children" #container>
    <div class="matrix__body" #matrix>
        <table *ngIf="xVariablesWithValues && yVariablesWithValues && xVariablesWithValues.length && yVariablesWithValues.length">
            <thead>
                <tr>
                    <th class="label-name label-headers">
                        {{ yHeaderLabel }}
                        <div class="label-headers__x matrix-table__column-header-background">
                            <span class="matrix-table__column-header-text">
                                {{ xHeaderLabel }}
                            </span>
                        </div>
                    </th>
                    <ng-container *ngFor="let xname of maskedXLabels; index as xindex; trackBy: trackByIndex">
                        <th *ngIf="isLabelFiltered(xname)" (click)="clickCell(xindex, -1)"
                            class="matrix-value matrix-table__column-header matrix-table__cell"
                        >
                            <div [title]="xname" class="xlabel matrix-table__column-header-background" [class.matrix-table__cell--vertical]="xIsSelected(xindex)"
                            >
                                <span class="matrix-table__column-header-text mx-textellipsis">
                                    {{ xname }}
                                </span>
                            </div>
                        </th>
                    </ng-container>
                </tr>
            </thead>
            <tbody>
                <ng-container *ngFor="let yname of maskedYLabels; index as yindex; trackBy: trackByIndex">
                    <tr *ngIf="isLabelFiltered(yname)">
                        <th (click)="clickCell(-1, yindex)"
                            class="matrix-table__cell label-name matrix-table__row-header mx-textellipsis"
                            [class.matrix-table__cell--horizontal]="yIsSelected(yindex)"
                        >
                            <span class="ylabel" title="{{yname}}">{{yname}}</span>
                        </th>
                        <ng-container *ngFor="let xname of maskedXLabels; index as xindex; trackBy: trackByIndex">
                            <td *ngIf="isLabelFiltered(xname)"
                                [style.background-color]="maskedColors[xindex][yindex]"
                                [class.matrix-table__cell--vertical]="xIsSelected(xindex)"
                                [class.matrix-table__cell--horizontal]="yIsSelected(yindex)"
                                class="matrix-value matrix-table__cell"
                                (click)="clickCell(xindex, yindex)"
                                (mousemove)="hoverCell($event, xindex, yindex)"
                                (mouseover)="positionPopover($event)"
                                (mouseout)="hidePopover()"
                            ></td>
                        </ng-container>
                    </tr>
                </ng-container>
            </tbody>
            
        </table>
    
        <div class="matrix-popover" 
            #popover 
            *ngIf="hoveredCell"
            [class.matrix-popover--visible]="isPopoverVisible" 
            [style.transform]="'translate(' + popoverOffsetLeft + 'px' + ', ' + popoverOffsetTop + 'px)'" 
            [style.width]="popoverWidth + 'px'" 
            [style.height]="popoverHeight + 'px'"
        >
            <ng-container [ngTemplateOutlet]="popoverTemplate || defaultTemplate" [ngTemplateOutletContext]="{ xLabel: hoveredCell.xLabel, yLabel: hoveredCell.yLabel, value: hoveredCell.value }"></ng-container>
        </div>
    
        <ng-template #defaultTemplate let-xLabel="xLabel" let-yLabel="yLabel" let-value="value">
            {{ yLabel }} on <li>{{ xLabel}}</li>: <strong>{{ value }}</strong> 
        </ng-template>
    </div>
</div>

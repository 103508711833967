import {Grouping, _Grouping} from './grouping';
import {GroupingResult, _GroupingResult} from './grouping-result';

/**
 * Generated from com.dataiku.dip.eda.compute.grouping.SubsampledGrouping
 */
export interface SubsampledGrouping extends _Grouping {
    maxRows: number;
    seed: number;

    // PolyJson type
    type: 'subsampled';
}

export function isSubsampledGrouping(toTest: Grouping): toTest is SubsampledGrouping { return 'subsampled' === toTest.type; }

export namespace SubsampledGrouping {
    /**
     * Generated from com.dataiku.dip.eda.compute.grouping.SubsampledGrouping$SubsampledGroupingResult
     */
    export interface SubsampledGroupingResult extends _GroupingResult {
        // PolyJson type
        type: 'subsampled';
    }

    export function isSubsampledGroupingResult(toTest: GroupingResult): toTest is SubsampledGroupingResult { return 'subsampled' === toTest.type; }
}
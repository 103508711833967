
import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { SerializedTableChunk } from '@model-main/shaker/server/serialized-table-chunk';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ImagesDataFetcherService } from '@shared/services/images-data-fetcher.service';
import { WindowService } from 'dku-frontend-core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { debounceTime, map, tap } from 'rxjs/operators';
import { SerializedMemTableV2 } from 'src/generated-sources';
import { InfiniteScrollComponent, Chunk } from '../infinite-scroll/infinite-scroll.component';

@UntilDestroy()
@Component({
    selector: 'images-feed',
    templateUrl: './images-feed.component.html',
    styleUrls: ['./images-feed.component.less']
})
export class ImagesFeedComponent implements AfterViewInit {
    @Input() imageHeight: number;
    @Input() itemsPerRow: number;
    @Output() imageClick? = new EventEmitter<{ rowId?: number, feedData?: SerializedMemTableV2 }>();
    @ViewChild(InfiniteScrollComponent) viewport: InfiniteScrollComponent;
    @ViewChild('container') containerEl: ElementRef;

    imageWidth: number;
    totalItems: number | null;
    tableData: SerializedMemTableV2;
    loadingStatus$ = new BehaviorSubject<boolean>(false);

    constructor( 
        private windowService: WindowService,
        private dataFetcher: ImagesDataFetcherService,
        private cd: ChangeDetectorRef
    ) {
    }

    getChunk = (offset: number): Observable<Chunk> => {
        let chunk$: Observable<SerializedTableChunk>;

        if (this.totalItems !== null && offset >= this.totalItems) {
            return of({
                totalItems: this.totalItems,
                chunkItems: []
            });
        }

        if (offset === 0) {
            chunk$ = this.dataFetcher.refreshSample()
                .pipe(
                    tap(result => {
                        this.tableData = result;
                        this.totalItems = result.totalKeptRows; 
                    }),
                    map(result => result.initialChunk)
                );
        } else {
            chunk$ = this.dataFetcher.getSampleChunk(offset);
        }

        return chunk$.pipe(
            map(chunk => ({
                totalItems: chunk.totalKeptRows,
                chunkItems: Array.from(Array(Math.min(chunk.nbRows, chunk.totalKeptRows - offset)))
                .map((row, index) => {
                    const rowContent = chunk.content.slice(index * chunk.nbCols, (index + 1) * chunk.nbCols);
                    return this.dataFetcher.rowToCellData(rowContent, offset + index, 0);
                })
            }))
        );
    };

    setDimensions(): void {
        const viewportWidth = this.containerEl.nativeElement.clientWidth;
        // Not the full width in order not to have a horizontal scrollbar
        this.imageWidth = Math.floor(viewportWidth / this.dataFetcher.NUM_IMAGES_PER_ROW) - 15;
        this.cd.markForCheck();
    }

    ngAfterViewInit(): void {
        this.setDimensions();

        // Not very optimized because we re-do everything, including fetching data
        // from the backend, could be optimized
        this.windowService.resize$.pipe(
            debounceTime(500),
            untilDestroyed(this)
        ).subscribe(_ => {
            this.setDimensions();
        })

        this.dataFetcher.dataChanged$.pipe(
            debounceTime(500),
            untilDestroyed(this)
        ).subscribe(_ => {
            this.totalItems = null;
            this.viewport.reset();
        })
    }



}
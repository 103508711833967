/**
 * Generated from com.dataiku.dip.dataflow.exec.filter.FilterDesc
 */
export interface FilterDesc {
    distinct: boolean;
    enabled: boolean;
    expression?: string | null;
    expressionVariablesContext?: any /* TODO: Missing translation of com.dataiku.dip.variables.VariablesContext */ | null;
    language?: FilterDesc.ExpressionLanguage | null;
    uiData?: FilterDesc.FilterUiData | null;
}

export namespace FilterDesc {
    /**
     * Generated from com.dataiku.dip.dataflow.exec.filter.FilterDesc$FilterUiCondition
     */
    export interface FilterUiCondition {
        col: string;
        date: string;
        date2: string;
        input: string;
        num: number;
        operator: string;
        string: string;
        subCondition: FilterDesc;
        time: string;
        time2: string;
        unit: string;
    }

    /**
     * Generated from com.dataiku.dip.dataflow.exec.filter.FilterDesc$ExpressionLanguage
     */
    export enum ExpressionLanguage {
        GREL = 'GREL',
        SQL = 'SQL'
    }

    /**
     * Generated from com.dataiku.dip.dataflow.exec.filter.FilterDesc$FilterUiData
     */
    export interface FilterUiData {
        conditions: FilterDesc.FilterUiCondition[];
        mode: string;
    }
}
import { Injectable } from '@angular/core';
import { DataikuAPIService } from '@core/dataiku-api/dataiku-api.service';
import { CurrentRouteService } from '@core/nav/current-route.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { CellData } from '@shared/services/images-data-fetcher.service';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { DeepHubColumnFormat, DeephubImagesDataService, ResolvedDeepHubPredictionCoreParams, SerializedMemTableV2, SerializedTableChunk } from 'src/generated-sources';
import { AbstractDeephubDataFetcherService, DeephubCellData } from '../abstract-deephub-data-fetcher.service';
import { DeephubObjectDetectionResultsImagePainterService } from './deephub-object-detection-results-image-painter.service';
import { DeephubResultsObjectDetectionService, ObjectDetectionReport } from './deephub-results-object-detection.service';

export class DeephubObjectDetectionResultsCellData extends DeephubCellData {
    constructor(
        public itemPath: string,
        public target: DeepHubColumnFormat.ObjectDetectionTargetItem[],
        public prediction: DeepHubColumnFormat.ObjectDetectionPredictedItem[],
        public pairings: DeepHubColumnFormat.PairingItem[],
        public enrichedValid: DeepHubColumnFormat.EnrichedObjectDetectionPairedItem[],
        public enrichedFiltered: DeepHubColumnFormat.EnrichedObjectDetectionPairedItem[],
        public rowId: number,
    ) {
        super(itemPath, target, rowId);
    }
}

@UntilDestroy()
@Injectable({
    providedIn: 'root'
})
export class DeephubResultsObjectDetectionDataFetcherService extends AbstractDeephubDataFetcherService {
    NUM_IMAGES_PER_ROW = 2;
    totalItems: number;
    coreParams: ResolvedDeepHubPredictionCoreParams;
    predictionColIndex: number;
    pairingColIndex: number;
    enrichedValidColIndex: number;
    enrichedFilteredColIndex: number;

    report$: Observable<ObjectDetectionReport>;
    filter$ = new BehaviorSubject<{
        detection?: DeephubImagesDataService.Category | null;
        groundTruth?: DeephubImagesDataService.Category | null;
        sorting: DeephubImagesDataService.Sorting;
    } | null>({sorting: {sortBy: DeephubImagesDataService.SortBy.IOU, ascending: false}});
    fetchTrigger$: Observable<[ObjectDetectionReport, DeephubImagesDataService.ObjectDetectionPredictedFilter | null]>;

    constructor(
        private DataikuAPI: DataikuAPIService,
        private objectDetectionService: DeephubResultsObjectDetectionService,
        public imagePainter: DeephubObjectDetectionResultsImagePainterService,
        private currentRoute: CurrentRouteService
    ) {
        super();
        this.projectKey = this.currentRoute.projectKey;
        this.report$ = this.objectDetectionService.getReport().pipe(
            debounceTime(400)
        );

        this.report$.
            pipe(
                withLatestFrom(this.filter$),
                // only refresh data if detection/ground truth filtering is active
                distinctUntilChanged(([, prevFilter], [, currFilter]) => {
                    return !currFilter || !currFilter.detection || !currFilter.groundTruth;
                }),
                untilDestroyed(this)
            ).subscribe(([report, filter]) => {
                this.coreParams = report.coreParams;
                this.managedFolderId = report.coreParams.managedFolderSmartId;
                this.imagePainter.setColorMapping(report.categories);

                this.onDataChanged();
            });

        this.fetchTrigger$ = combineLatest([this.report$, this.filter$])
            .pipe(
                map(([report, filter]) => [report, filter ? {
                    minConfidence: report.confidenceThreshold!,
                    minIOU: report.iou,
                    ...filter
                } : filter]),
            );
    }

    refreshSample(): Observable<SerializedMemTableV2> {
        return this.fetchTrigger$
            .pipe(
                switchMap(([report, filter]) => this.DataikuAPI.analysis.refreshPredictedImagesDataSample(report.fullModelId, filter || {})
                    .pipe(
                        withLatestFrom(this.filter$),
                        map(([result, filter]) => {
                            this.predictionColIndex = result.allColumnNames.indexOf(this.getPredictionColumnName());
                            this.pairingColIndex = result.allColumnNames.indexOf(this.getPairingColumnName());
                            this.enrichedValidColIndex = result.allColumnNames.indexOf(this.getEnrichedValidColumnName());
                            this.enrichedFilteredColIndex = result.allColumnNames.indexOf(this.getEnrichedFilteredColumnName());
                            this.onRefreshSample(result);
                            this.totalItems = result.totalKeptRows;
                            this.imagePainter.setClasses(filter?.groundTruth?.value, filter?.detection?.value);

                            return result;
                        })
                    )
                )
            );
    }

    getSampleChunk(firstRow: number, nbRows?: number): Observable<SerializedTableChunk> {
        return this.fetchTrigger$
            .pipe(
                switchMap(([report, filter]) => this.DataikuAPI.analysis.getPredictedImagesDataChunk(report.fullModelId, firstRow, nbRows, filter || {}))
            );
    }

    getTargetColumnName() {
        return this.coreParams.target_variable;
    }

    getItemPathColumnName() {
        return this.coreParams.pathColumn;
    }

    getPredictionColumnName() {
        return 'prediction';
    }

    getPairingColumnName() {
        return 'pairing';
    }

    getEnrichedValidColumnName() {
        return 'enrichedValid';
    }

    getEnrichedFilteredColumnName() {
        return 'enrichedFiltered';
    }

    rowToCellData(rowContent: string[], i: number, j: number): CellData {
        return new DeephubObjectDetectionResultsCellData(
            rowContent[this.itemPathColIndex],
            JSON.parse(rowContent[this.targetPathColIndex]),
            JSON.parse(rowContent[this.predictionColIndex]),
            JSON.parse(rowContent[this.pairingColIndex]),
            JSON.parse(rowContent[this.enrichedValidColIndex]),
            JSON.parse(rowContent[this.enrichedFilteredColIndex]),
        i);
    }

    setFilteredPair(filter: {
        detection?: DeephubImagesDataService.Category | null;
        groundTruth?: DeephubImagesDataService.Category | null;
        sorting: DeephubImagesDataService.Sorting
    } | null) {
        this.filter$.next(filter);
        this.onDataChanged();
    }
}
